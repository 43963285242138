export const settings = (state) => {
  return state.workspaceSettings
}

export const activeWorkspace = (state, getters, rootState, rootGetters) => {
  if (state.activeWorkspace) {
    return state.activeWorkspace
  }

  const activeWorkspace = rootGetters['profile']?.workspaces?.find(
    (workspace) => workspace?.id === localStorage.getItem('workspace_id'),
  )

  if (typeof activeWorkspace !== 'undefined') {
    return activeWorkspace
  }

  return null
}

export const activeWorkspaceBankAccount = (state) => {
  return state.activeWorkspaceBankAccount
}

export const signatureFooterSettings = (state) => {
  return state.signatureFooterSettings
}

export const signatureFooterSettingsKeys = (state) => {
  return Object.keys(state.signatureFooterSettings)
}

export const workspaceBankAccounts = (state) => {
  return state.workspaceBankAccounts
}

export const membersJobPositions = (state) => {
  return state.membersJobPositions
}
