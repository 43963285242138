import { apiUrl } from '@/app/config/appEnvironment'
import { authHeader } from '@/config/authHeader'
import { autologoutIfUnauthorized } from './helpers'
import router from '@/app/plugins/router'

export const UserService = {
  loadLoggedUser,
  update,
  confirmPin,
}

function confirmPin(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/users/verifymobile`, requestOptions).then(handleResponse)
}

function loadLoggedUser(workspaceId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/loggeduser?workspace_id=${workspaceId || ''}`, requestOptions).then(handleResponse)
}

function update(data, id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/users/${id}`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 404) {
        return router.push({ name: 'notFound' })
      }

      autologoutIfUnauthorized(response)

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
