import * as types from './mutation_types'

export default {
  [types.SET_SIGNATURES](state, payload) {
    let identifier = 1
    state.signatures = []

    payload.forEach((signature) => {
      state.signatures.push(
        Object.assign(signature, {
          identifier: identifier++,
          isConfirmed: false,
        }),
      )
    })
  },
  [types.SET_APPROVE](state, identifier) {
    for (let signature of state.signatures) {
      if (signature.identifier === identifier) {
        signature.isConfirmed = true
      }
    }
  },
  [types.SET_NUMBER_OF_PAGES](state, numberOfPages) {
    state.numberOfPages = numberOfPages
  },
}
