import * as types from './mutation_types'
import axios from 'axios'
import { environment } from '@/config/environment'
import { authHeader } from '@/config/authHeader'
import { workspaceApiKeyRequest, workspaceApiSettingsRequest } from '@apiDoc/services/apiDocService'

export const fetchWorkspaceApiSettings = (context, workspaceId) => {
  return workspaceApiSettingsRequest({
    password: context.getters['userPassword'],
    userId: context.rootGetters['profile']?.id,
    workspaceId: workspaceId,
  }).then((workspaceApiSettings) => {
    return workspaceApiSettings
  })
}

export const storeUserPassword = ({ commit }, { password, googleTokenId, azureTokenId }) => {
  return axios
    .post(
      `${environment.getApiUrl()}/api/v1/checkpassword`,
      {
        password,
        googleTokenId,
        azureTokenId
      },
      {
        headers: authHeader(),
      },
    )
    .then((resp) => {
      if (!resp?.data?.valid) {
        throw new Error('not valid password')
      }

      const timeToLeft = 2 * 60 * 1000

      commit(types.SET_AUTH_TYPE, password ? 'password' : 'token')
      commit(types.SET_USER_PASSWORD, password || googleTokenId || azureTokenId)
      commit(types.SET_REMAINING_TIME, 100)

      setTimeout(() => {
        commit(types.RESET_USER_PASSWORD)
      }, timeToLeft)

      let startTimeMS = new Date().getTime()
      const timerCheck = setInterval(function () {
        const remainingTime = timeToLeft - (new Date().getTime() - startTimeMS)

        commit(types.SET_REMAINING_TIME, (remainingTime / timeToLeft) * 100)

        if (remainingTime < 0) {
          clearInterval(timerCheck)
        }
      }, 2000)
    })
}

export const fetchWorkspaceApiToken = ({ getters }, workspaceId) => {
  const hash = getters['userPassword']
  const authentication = {
    password: null,
    tokenId: null,
  }

  if ('token' === getters['authType']) {
    authentication.tokenId = hash
  } else {
    authentication.password = hash
  }

  return workspaceApiKeyRequest({
    method: 'POST',
    payload: {
      ...authentication,
      workspace_id: workspaceId,
    },
  })
}

export const createWorkspaceApiKey = ({ getters }, payload) => {
  const hash = getters['userPassword']
  const authentication = {
    password: null,
    tokenId: null,
  }

  if ('token' === getters['authType']) {
    authentication.tokenId = hash
  } else {
    authentication.password = hash
  }

  return workspaceApiKeyRequest({
    method: 'PATCH',
    payload: Object.assign(
      {},
      {
        ...authentication,
        ...payload,
      },
    ),
  })
}

export const deleteWorkspaceApiKey = ({ getters }, payload) => {
  const hash = getters['userPassword']
  const authentication = {
    password: null,
    tokenId: null,
  }

  if ('token' === getters['authType']) {
    authentication.tokenId = hash
  } else {
    authentication.password = hash
  }

  return workspaceApiKeyRequest({
    method: 'DELETE',
    payload: Object.assign(
      {},
      {
        ...authentication,
        ...payload,
      },
    ),
  })
}
