import { defineStore } from 'pinia'
import * as contractService from '@/features/contracts/services/contractService'
import { useWorkspaceStore } from '@/stores/workspace'

export const useContractStore = defineStore('contract', {
  state: () => ({
    data: null,
    error: false,
  }),
  actions: {
    fetchContractData(contractId, workspaceId) {
      return contractService.fetchForFirstStep({ contractId, workspaceId }).then((resp) => {
        const workspaceStore = useWorkspaceStore()

        this.data = Object.assign({}, resp, {
          comments_usage: 'comments',
          send_notification_for_ending_contract: !!workspaceStore.workspaceContractSettings.completedExpirationDays,
          waiting_limit: workspaceStore.workspaceContractSettings.draftExpirationDays,
          one_device: workspaceStore.workspaceFeatureFlags.includes('oneDeviceByDefaultEnabled') || null,
        })

        return resp
      })
    },
    updateContractData({ contractId, data }) {
      return contractService.update({ contractId, data }).then((resp) => {
        this.data = Object.assign({}, resp)
      })
    },
    addAttachments(contractId, attachments) {
      return contractService.addAttachments(contractId, attachments).then(async (resp) => {
        await this.fetchContractData(this.mainContractId, resp?.workspace_id)
      })
    },
    reloadAttachments(contractId, workspaceId) {
      return contractService.fetchForFirstStep({ contractId, workspaceId }).then((resp) => {
        this.data.attachments = resp?.attachments || []
      })
    },
    resetContractData() {
      this.data = null
    },
  },
  getters: {
    attachments: (state) => {
      if (null === state.data) {
        return []
      }

      return [...new Map([state.data, ...state.data?.attachments].map((contract) => [contract?.id, contract])).values()]
    },
    attachmentsIds: (state) => {
      return [state.data, ...(state.data?.attachments || [])]?.map((contract) => contract?.id)
    },
    availableProposers: (state) => {
      return state.data?.contract_flow?.availableProposers || []
    },
    contract: (state) => {
      return state.data
    },
    featureFlags: (state) => {
      return state.data?.feature_flags
    },
    hasSequentialSigningOrder: (state) => {
      return state.data?.feature_flags.hasSequentialSigningOrder || false
    },
    title: (state) => {
      return state.data?.title
    },
    mainContractId: (state) => {
      return `${
        [state.data, ...(state.data?.attachments || [])]?.find((contract) => !contract?.is_attachment)?.id ||
        state.data?.id
      }`
    },
    minimalCounterPartiesCount: (state) => {
      return state.data?.template?.analysis?.minCounterpartiesCount || 0
    },
    isAnyContractTypeForm: (state) => {
      return state.attachments?.some((contract) => 'form' === `${contract?.type}`)
    },
    errorOccured: (state) => {
      return state.error
    },
  },
})
