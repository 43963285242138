import { v4 as uuidv4 } from 'uuid'

export const PlacedSignatures = {
  build,
}

function build(reloadView) {
  let signatures = []

  const reload = () => {
    for (let i = 0; i < signatures.length; i++) {
      if (signatures[i].top === null && signatures[i].left === null) {
        signatures.splice(i, 1)
      }
    }

    reloadView(signatures)
  }

  function replaceAll(addSignatures) {
    signatures = []
    addSignatures(upsertWithoutReload)
    reload()
  }

  function upsert(signIdentity, positions) {
    upsertWithoutReload(signIdentity, positions)
    reload()
  }

  function upsertWithoutReload(signIdentity, positions) {
    if (!signIdentity.email && !signIdentity.variable_position) {
      return null
    }

    positions.map((position) => {
      const placedSignature = findSignature(signIdentity, position)

      if (!position.offset_left && !position.offset_top) {
        if (placedSignature) {
          deletePosition(placedSignature)
        }
      } else if (placedSignature) {
        update(placedSignature, position)
      } else {
        create(signIdentity, position)
      }
    })
  }

  function create(signIdentity, position) {
    signatures.push({
      uuid: position?.uuid || uuidv4(),
      anchor: null,
      uniqueSignId: `${signIdentity.id}-${Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .slice(2, 12)}`,
      is_proposer_sign: signIdentity.is_proposer,
      page: position.page,
      top: position.offset_top,
      left: position.offset_left,
      show_header: position?.show_header,
      show_footer: position?.show_footer,
      relativeWidth: position.relative_width,
      firstname: signIdentity.firstname,
      lastname: signIdentity.lastname,
      signIdentity: signIdentity,
      focused: false,
    })
  }

  function update(placedSignature, position) {
    placedSignature.uuid = position.uuid
    placedSignature.anchor = position.anchor
    placedSignature.page = position.page
    placedSignature.top = position.offset_top
    placedSignature.left = position.offset_left
    placedSignature.show_header = position?.show_header
    placedSignature.show_footer = position?.show_footer
    placedSignature.relativeWidth = position.relative_width
  }

  function deletePosition(placedSignature) {
    placedSignature.uuid = null
    placedSignature.anchor = null
    placedSignature.page = null
    placedSignature.top = null
    placedSignature.left = null
    placedSignature.show_header = null
    placedSignature.show_footer = null
    placedSignature.relativeWidth = null
  }

  function findSignature(signIdentity, position) {
    if (!position.uniqueSignId) {
      return null
    }

    return (
      findSignatures(signIdentity)?.find((signature) => `${signature.uniqueSignId}` === `${position.uniqueSignId}`) ||
      null
    )
  }

  function findSignatures(signIdentity) {
    return signatures?.filter((signature) => `${signature.signIdentity.id}` === `${signIdentity.id}`) || []
  }

  function isNotEmpty() {
    return signatures?.length > 0
  }

  return {
    replaceAll,
    upsert,
    focus,
    findSignatures,
    isNotEmpty,
  }
}
