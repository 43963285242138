<template>
  <v-row align="center" justify="center" no-gutters>
    <v-col class="col-12">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDate"
            :label="currentLabel"
            :placeholder="placeholder"
            :readonly="readonly"
            :disabled="changesDisabled"
            :background-color="backgroundColor"
            append-icon="mdi-calendar"
            hide-details
            outlined
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="date"
          :landscape="landscape"
          :locale="locale"
          no-title
          @input="menu = false"
        ></v-date-picker>

        <slot v-if="hasAdditionalButtonsContent" name="additionalButtons"></slot>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import i18n from '@/app/plugins/i18n'
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'

export default {
  name: 'SDatePicker',
  props: {
    value: [String, Number, Date],
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    dateOptions: {
      type: Object,
      default: () => {
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      },
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    changesDisabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    date: {
      get() {
        return new Date(this.value).toISOString().substr(0, 10)
      },
      set(value) {
        this.$emit('input', new Date(value).toISOString().substr(0, 10))
      },
    },
    currentLabel() {
      return this.label ? this.label : i18n.t('contract.date')
    },
    formattedDate() {
      return getFormattedDateByLocale({
        date: this.date,
        options: this.dateOptions,
        locale: this.locale,
      })
    },
    hasAdditionalButtonsContent() {
      return !!this.$slots.additionalButtons
    },
    landscape() {
      return this.$vuetify.breakpoint.mobile && this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height
    },
    locale() {
      return i18n.locale
    },
  },
  mounted() {
    if (null === this.value) {
      this.date = new Date().toISOString().substr(0, 10)
    } else {
      this.date = new Date(`${this.value}`?.includes('00:00:00') ? this.value?.replace(' 00:00:00', '') : this.value)
        .toISOString()
        .substr(0, 10)
    }
  },
}
</script>

<style lang="scss" scoped></style>
