<template lang="html">
  <div class="input-pin my-10 mx-auto" @keyup.enter="submit">
    <v-otp-input ref="input" v-model="pin" type="number" length="4" @finish="submit" />
  </div>
</template>

<script>
export default {
  name: 'InputPIN',
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    pin: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    resetPin() {
      this.pin = ''
    },
    submit() {
      this.$emit('confirmPin')
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.input-pin) {
  max-width: 20rem;

  .v-input__slot {
    height: 5rem;
    font-size: 2.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: transparent !important;

    input {
      max-height: 5rem;
    }
  }
}
</style>
