// TODO - module is not important now
export const UserSettingsModule = {
  namespaced: true,
  state: {
    accountSettingsTab: 'personal-information',
    workspaceSettingsTab: 'sign-identity',
  },
  actions: {
    setAccountSelectedTab: ({ commit, state }, payload) => {
      commit('setAccountSelectedTab', payload)
      return state.accountSettingsTab
    },
    setWorkspaceSelectedTab: ({ commit, state }, payload) => {
      commit('setWorkspaceSelectedTab', payload)
      return state.workspaceSettingsTab
    },
  },
  mutations: {
    setAccountSelectedTab: (state, payload) => {
      state.accountSettingsTab = payload
    },
    setWorkspaceSelectedTab: (state, payload) => {
      state.workspaceSettingsTab = payload
    },
  },
  getters: {
    accountSelectedTab(state) {
      return state.accountSettingsTab
    },
    workspaceSelectedTab(state) {
      return state.workspaceSettingsTab
    },
  },
}
