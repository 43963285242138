<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-premistit</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-premistit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M3.99989251,15.2131563 C3.99989251,15.3824115 3.99989251,15.8035059 3.99989251,16.4764396 C3.99989251,17.4858401 4.84836452,18.0000002 6.29325544,18.0000002 C7.73814636,18.0000002 15.8316204,18.0000002 17.4657307,18.0000002 C19.0998411,18.0000002 19.1836512,15.6889875 19.0988934,15.6889875 C19.0141356,15.6889875 19.0988934,10.1980879 19.0988934,8.88732915 C19.0988934,7.57657039 18.0207533,6.85262483 17.4657307,6.85262483 C17.0957157,6.85262483 14.6992521,6.85262483 10.2763402,6.85262483 C10.340361,5.56808439 9.94833877,4.92581417 9.10027352,4.92581417 C7.82817563,4.92581417 6.03769865,4.92581417 5.00409938,4.92581417 C4.31503319,4.92581417 3.98029757,6.49703233 3.99989251,9.63946867"
        id="Path-59"
        stroke="#B4C1D6"
        stroke-width="2"
      ></path>
      <path d="M0.876487744,12 L13.1466187,12" id="Path-60" stroke="#B4C1D6" stroke-width="2"></path>
      <polyline id="Path-68" stroke="#B4C1D6" stroke-width="2" points="10 9 13 12 10 15"></polyline>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoMoveToFolder',
}
</script>

<style lang="scss" scoped></style>
