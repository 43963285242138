<template lang="html">
  <svg
    width="125px"
    height="141px"
    viewBox="0 0 125 141"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Mobil" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN001---Finish-screen---workspace" transform="translate(-98.000000, -142.000000)">
        <g id="Group-3" transform="translate(98.000000, 142.000000)">
          <g id="Group">
            <circle id="Oval-4" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero" cx="63" cy="63" r="53"></circle>
            <circle id="Oval-4-Copy" stroke="#E8EDF5" fill-rule="nonzero" cx="62.5" cy="62.5" r="62"></circle>
            <g id="ico/ico-workspace" transform="translate(24.000000, 25.000000)">
              <g id="Group-3" transform="translate(6.000000, 11.000000)">
                <path d="M4,25 L4,50.914779" id="Path-42" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                <rect
                  id="Rectangle-4-Copy-2"
                  stroke="#6DECAF"
                  stroke-width="2"
                  fill-rule="nonzero"
                  x="10"
                  y="1"
                  width="25"
                  height="14"
                ></rect>
                <rect
                  id="Rectangle-4-Copy"
                  stroke="#D4DFF0"
                  stroke-width="2"
                  fill-rule="nonzero"
                  x="40"
                  y="25"
                  width="22"
                  height="24"
                ></rect>
                <path
                  d="M40.2143338,35 L62.5222367,35"
                  id="Path-43"
                  stroke="#D4DFF0"
                  stroke-width="2"
                  fill-rule="nonzero"
                ></path>
                <path d="M50,30 L53,30" id="Path-44" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                <path d="M50,40 L53,40" id="Path-44-Copy" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                <path
                  d="M19.3209865,16.0015044 C19.0317915,18.6609676 18.7466992,20.2280178 18.4657097,20.7026549"
                  id="Path-45"
                  stroke="#6DECAF"
                  stroke-width="2"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M26.9680453,16.0015044 C26.6788503,18.6609676 26.3937581,20.2280178 26.1127685,20.7026549"
                  id="Path-45-Copy"
                  stroke="#6DECAF"
                  stroke-width="2"
                  fill-rule="nonzero"
                  transform="translate(26.540407, 18.352080) scale(-1, 1) translate(-26.540407, -18.352080) "
                ></path>
                <rect
                  id="Rectangle-4"
                  stroke="#6DECAF"
                  stroke-width="2"
                  fill-rule="nonzero"
                  x="1"
                  y="21"
                  width="63"
                  height="4"
                ></rect>
              </g>
            </g>
          </g>
          <g id="Group-2" transform="translate(40.000000, 96.000000)" fill-rule="nonzero">
            <circle id="Oval" fill="#6DECAF" cx="22.5" cy="22.5" r="22.5"></circle>
            <polyline
              id="Path-26-Copy-6"
              stroke="#FFFFFF"
              stroke-width="4"
              stroke-linecap="round"
              points="12.7358491 24.0545915 19.3855151 30.7042575 33.1086405 16.9811321"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoWorkspaceCreated',
}
</script>
