import { render, staticRenderFns } from "./LoadMore.vue?vue&type=template&id=4c52db28&scoped=true"
import script from "./LoadMore.vue?vue&type=script&lang=js"
export * from "./LoadMore.vue?vue&type=script&lang=js"
import style0 from "./LoadMore.vue?vue&type=style&index=0&id=4c52db28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c52db28",
  null
  
)

export default component.exports