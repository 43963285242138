<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-main>
      <slot></slot>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'EmptyLayout',
}
</script>

<style lang="scss" scoped></style>
