import { authorizedApiRequest, publicApiRequest } from '@/services/ApiService'
import { encryptData as encryptionService } from '@digitalfactorycz/core/dist/lib/services/encryptionService'
import store from '@/store'

/**
 * Getter of workspace public key for encryption.
 *
 * @param {string|number} workspaceId
 * @returns {Promise<object>}
 */
export const getWorkspacePublicKey = ({ workspaceId }) => {
  const storedWorkspaceId = localStorage.getItem('workspace_id')
  const userProfileWorkspaces = store.getters['profileWorkspaces']

  if ('undefined' === typeof workspaceId) {
    workspaceId = storedWorkspaceId
  }

  if (!workspaceId) {
    if (0 === userProfileWorkspaces?.length) {
      throw new Error('Unknown workspace id.')
    }

    workspaceId = userProfileWorkspaces[0].id
  }

  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v2/workspaces/${workspaceId}/crypto`,
  })
}

/**
 * Getter of workspace encryption public key for public part of application
 *
 * @param authToken
 * @returns {Promise<object>}
 */
export const getPublicWorkspacePublicKey = ({ authToken }) => {
  return publicApiRequest({
    authorization: 'ApiToken',
    authToken,
    method: 'GET',
    endpoint: '/api/client/v1/crypto',
  })
}

/**
 * Setter of custom workspace encrypt public key
 *
 * @param {string|number} workspaceId
 * @returns {Promise<object>}
 */
export const setWorkspacePublicKey = ({ workspaceId }) => {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: `/api/v2/workspaces/${workspaceId}/crypto`,
  })
}

/**
 * Method to get encrypted data
 *
 * @param data
 * @param authToken
 * @param workspaceId
 * @returns {Promise<{date: Date, pubKeyId, encryptedData: {signature: *, keys: string}}>}
 */
export const encryptData = async ({ data, workspaceId, authToken = null }) => {
  let publicKey

  if (authToken !== null) {
    publicKey = await getPublicWorkspacePublicKey({ authToken })
  } else {
    publicKey = await getWorkspacePublicKey({ workspaceId })
  }

  return encryptionService({
    publicKeyResponse: publicKey,
    data,
  })
}
