import Vue from 'vue'
import App from '@/App.vue'
import router from '@/app/plugins/router'
import store from '@/store'
import Vuelidate from 'vuelidate'
import vuetify from '@/app/plugins/vuetify'
import i18n from '@/app/plugins/i18n'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { environment } from '@/config/environment'

// TODO - remove this global components
import Logo from '@/components/partials/Logo'
import LogoSymbol from '@/components/partials/LogoSymbol'
import Input from '@/components/Input'
import InputNumber from '@/components/InputNumber'
import Select from '@/components/Select'
import Checkbox from '@/components/Checkbox'
import Radio from '@/components/Radio'
import Textarea from '@/components/Textarea'
import Button from '@/components/Button'
import Data from '@/components/Data'
import SHelp from '@/common/components/SHelp'
import SBtn from '@/common/components/SBtn'

import 'nprogress/nprogress.css'
import '@/directives/fitTextDirective.js'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import shadow from 'vue-shadow-dom'
import VuejsDialog from 'vuejs-dialog'
import lineClamp from 'vue-line-clamp'

import { ExternalDriveService } from '@/services/ExternalDriveService.js'

import VueSimpleMarkdown from 'vue-simple-markdown'
import VueCookies from 'vue-cookies'
import VueSignaturePad from 'vue-signature-pad'
import VueNotification from '@kugatsu/vuenotification'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import { gsap } from 'gsap'

Vue.config.productionTip = false

Vue.prototype.$env = environment

// TODO: external drive - is frontend redirect necessary? it looks weird when /account page waits on processing code in backend
const queryString = window.location.search
if ('/account' === window.location.pathname && 0 === queryString.indexOf('?code')) {
  ExternalDriveService.onedriveAuthFinish(queryString)
}

Vue.use(PiniaVuePlugin)
Vue.use(shadow)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueSignaturePad)
Vue.use(require('vue-moment'))
Vue.use(VueSimpleMarkdown, 'vue-simple-markdown')
Vue.use(VuejsDialog, {
  okText: 'OK',
  cancelText: 'Cancel',
})
Vue.use(VueNotification, {
  timer: 5,
  position: 'topRight',
  showCloseIcn: true,
  showLeftIcn: false,
  error: {
    background: '#FFEFEF',
    color: '#cd0062',
    textColor: '#cd0062',
  },
  success: {
    background: '#E9FBF4',
    color: '#064e2b',
    textColor: '#064e2b',
  },
  warning: {
    background: '#FFFAD1',
    color: '#8b572a',
    textColor: '#8b572a',
  },
  animateIn: function () {
    // eslint-disable-next-line no-undef
    let tl = gsap.timeline()
    tl.from(this.notificationEl, {
      opacity: 0,
      duration: 0.4,
    }).to(this.notificationEl, {
      height: 'fit-content',
    })
    return tl
  },
  animateOut: function () {
    let tl = gsap.timeline()
    tl.from(this.notificationEl, {
      height: 'fit-content',
    }).to(this.notificationEl, {
      opacity: 0,
      duration: 0.4,
    })
    return tl
  },
})
Vue.use(lineClamp, {
  importCss: true,
})

Vue.component('Data', Data)
Vue.component('Logo', Logo)
Vue.component('LogoSymbol', LogoSymbol)
Vue.component('Input', Input)
Vue.component('InputNumber', InputNumber)
Vue.component('Select', Select)
Vue.component('Checkbox', Checkbox)
Vue.component('Radio', Radio)
Vue.component('Textarea', Textarea)
Vue.component('Button', Button)
Vue.component('s-btn', SBtn)
Vue.component('s-help', SHelp)

Vue.nextTick(() => {
  window.addEventListener('resize', () => {
    store.dispatch('window/updateWindowWidth')
  })
})

let application

if (!application) {
  application = new Vue({
    i18n,
    router,
    store,
    vuetify,
    pinia: createPinia(),
    render: (signi) => signi(App),
  }).$mount('#app')
}
