<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-vzhled.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-vzhled.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-11" transform="translate(2.000000, 1.000000)" stroke="currentColor" stroke-width="2">
        <path
          d="M0.142076203,21.2692308 C1.16846439,19.9931988 1.74758793,18.3778142 1.87944685,16.4230769 C2.07723521,13.490971 3.19524741,12.0665661 4.35419165,11.5220505 C5.12682114,11.1590402 5.97494085,11.0482946 6.89855079,11.1898139 L9.68222788,14 C9.99424749,16.2486275 9.27723976,18.1115603 7.53120467,19.5887984 C5.78516958,21.0660365 3.32212676,21.6261806 0.142076203,21.2692308 Z"
          id="Path-54"
        ></path>
        <polyline
          id="Path-55"
          points="6.87868232 11.0870356 9.22271351 8.89269021 11.9194474 11.5605924 9.73929371 14"
        ></polyline>
        <path
          d="M10.7591587,7.5520914 L18.5769231,0.832366014 C19.2541143,0.167693519 19.9004969,0.0015253957 20.5160708,0.333861643 C21.1316448,0.66619789 21.1316448,1.37449138 20.5160708,2.45874213 L13.2229982,10.1934027"
          id="Path-56"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoAppearance',
}
</script>

<style lang="scss" scoped></style>
