export const getStorageKey = (key) => {
  let cached = localStorage.getItem(key)

  if (!cached) {
    return null
  }

  cached = JSON.parse(cached)

  if (new Date(cached.expires) < new Date()) {
    localStorage.removeItem(key)
    return null
  }

  return cached.value
}

export const setStorageKey = (key, value, lifeMinutes) => {
  const currentTime = new Date().getTime()
  const expires = new Date(currentTime + lifeMinutes * 60000)

  localStorage.setItem(key, JSON.stringify({ value, expires }))
}
