import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import { isRoutePublicPage } from '@/services/helpers'

import loginModuleRoutes from '@/app/modules/login/routes'

import accessRoutes from '@access/routes'
import accountRoutes from '@account/routes'
import apiRoutes from '@apiDoc/routes'
import contactsRoutes from '@contacts/routes'
import contractRoutes from '@contract/routes'
import contractsRoutes from '@/features/contracts/routes'
import subscriptionRoutes from '@subscription/routes'
import templatesRoutes from '@/features/templates/routes'
import workspaceRoutes from '@workspace/routes'

// TODO - Dashboard module
import Dashboard from '@/views/dashboard/Dashboard.vue'

// TODO - Reports module
import Reports from '@/views/dashboard/Reports.vue'

// TODO - Contract module with components and pages
import Documents from '@/views/dashboard/Documents.vue'
import CreateNew from '@/views/create/CreateNew.vue'
import CreateOwn from '@/views/create/CreateOwn.vue'
import CreateUpload from '@/views/create/CreateUpload.vue'
import CreateContent from '@contract/views/CreateContent'
import CreateSign from '@/views/create/CreateSign.vue'
import CreateRejected from '@/views/create/CreateRejected.vue'

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...loginModuleRoutes,
    ...accessRoutes,
    ...accountRoutes,
    ...apiRoutes,
    ...contactsRoutes,
    ...contractRoutes,
    ...contractsRoutes,
    {
      path: '/',
      name: 'home',
      component: Dashboard,
    },
    {
      path: '/dashboard/workspace/:workspace_id',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/dashboard/workspace/:workspace_id/documents',
      name: 'documentsTable',
      component: Documents,
    },
    {
      path: '/dashboard/workspace/:workspace_id/reports',
      name: 'reports',
      component: Reports,
    },
    {
      path: '/:workspace_id/create/new',
      name: 'createNew',
      component: CreateNew,
    },
    {
      path: '/:workspace_id/contract/:contract_id/add/attachment',
      name: 'createNewAttachment',
      component: CreateNew,
    },
    {
      path: '/:workspace_id/create/:contract_id/own',
      name: 'createOwn',
      component: CreateOwn,
    },
    {
      path: '/:workspace_id/create/:contract_id/upload',
      name: 'createUpload',
      component: CreateUpload,
    },
    {
      path: '/:workspace_id/create/:contract_id/content',
      name: 'createContent',
      component: CreateContent,
    },
    {
      path: '/:workspace_id/create/:contract_id/sign',
      name: 'createSign',
      component: CreateSign,
    },
    {
      path: '/:workspace_id/create/rejected',
      name: 'createRejected',
      component: CreateRejected,
    },
    {
      path: '/overview/workspace/:workspace_id/contracts/active',
      name: 'overviewContractsActive',
      redirect: (to) => ({
        name: 'documentsTable',
        params: {
          workspace_id: to.params.workspace_id,
        },
        query: {
          state: 'draft',
        },
      }),
    },
    ...subscriptionRoutes,
    ...templatesRoutes,
    ...workspaceRoutes,
    {
      path: '/error/not-found',
      alias: '/404',
      name: 'notFound',
      component: () => import('@/views/NotFound'),
      meta: {
        authRequired: false,
        layout: () => import('@/layouts/StaticLayout'),
      },
    },
    {
      path: '/success/sign/sms',
      alias: '/sms-sign-success',
      name: 'success-sign-sms',
      component: () => import('@/views/SuccessSignSms'),
      meta: {
        authRequired: false,
        layout: () => import('@/layouts/StaticLayout'),
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()

  const token = localStorage.getItem('token')
  const authRequired = 'undefined' === typeof to.meta.authRequired ? !isRoutePublicPage(to.name) : to.meta.authRequired

  if (authRequired) {
    if (null === token) {
      next({
        name: to.query['loginPage'] ? `${to.query['loginPage']}-login` : 'login',
        query: {
          redirect_to: window.location.href,
        },
      })
    } else {
      store.dispatch('getUserProfile', to.params.workspace_id)
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
