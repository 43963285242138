<template>
  <div class="workspace-custom-inputs" :class="{ 'invitation-form': invitationForm }">
    <div v-if="customInputs.length > 0 && !invitationForm" class="is-row row-custom-input-titles">
      <div class="col-xs-6">
        <div class="input-label-wrap">
          <span class="input__label">{{ $t('workspace.field_name') }}</span>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="input-label-wrap">
          <span class="input__label">{{ $t('workspace.field_value') }}</span>
        </div>
      </div>
    </div>
    <div v-for="input in customInputs" :key="input.id" class="workspace-custom-inputs__item">
      <div class="is-row">
        <div
          v-if="!invitationForm"
          class="col-xs-6 input"
          :class="{ disabled: disabledNames }"
          :style="{ marginTop: 0 }"
        >
          <label>
            <div v-if="!invitationForm" class="input-label-wrap">
              <span class="input__label">{{ $t('workspace.field_name') }}</span>
            </div>
            <input
              :value="input.name"
              name="name"
              :disabled="disabledNames"
              @input="(event) => onChange(event, input.id)"
              class="input__data"
            />
          </label>
        </div>

        <div
          class="input"
          :class="{
            'col-xs-6': !invitationForm,
            'col-xs-12': invitationForm,
            'input-with-delete': !disabledNames,
            invalid: errorIds.indexOf(input.id) >= 0,
          }"
          :style="{ marginTop: 0 }"
        >
          <div v-if="invitationForm" class="input-label-wrap">
            <strong class="input__label">{{ input.name }}</strong>
          </div>
          <label>
            <div v-if="!invitationForm" class="input-label-wrap">
              <span class="input__label">{{ $t('workspace.field_value') }}</span>
            </div>
            <input
              :value="input.value"
              name="value"
              @input="(event) => onChange(event, input.id)"
              class="input__data"
            />
          </label>

          <a href="#" @click="(event) => removeItem(event, input.id)" v-if="!disabledNames">x</a>
        </div>
      </div>
    </div>

    <div v-if="!disabledNames" class="workspace-custom-inputs__add-item">
      <button type="button" class="btn btn-primary" @click="addInput">+ {{ $t('workspace.add_detail') }}</button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'WorkspaceCustomInput',
  props: {
    inputs: {
      type: Array,
      required: true,
    },
    disabledNames: {
      type: Boolean,
      required: false,
      default: false,
    },
    invitationForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      customInputs: this.inputs,
      counter: 0,
      errorIds: [],
    }
  },
  methods: {
    addInput() {
      this.customInputs.push({
        name: '',
        value: '',
        id: 'new_' + this.counter,
      })
      this.counter++
    },
    removeItem(event, id) {
      event.preventDefault()
      let customInputs = []
      _.each(this.customInputs, (customInput) => {
        if (customInput.id !== id) {
          customInputs.push(customInput)
        }
      })
      this.customInputs = customInputs
      this.$emit('change', this.customInputs)
    },
    onChange(event, id) {
      const name = event.target.getAttribute('name')
      this.customInput = _.find(this.customInputs, { id: id })
      this.customInput[name] = event.target.value
      this.$emit('change', this.customInputs)
    },
    setInputs() {
      this.customInputs = this.inputs
    },
    setErrors() {
      this.errorIds = this.errors
    },
  },
  watch: {
    inputs: 'setInputs',
    errors: 'setErrors',
  },
}
</script>

<style lang="scss">
.workspace-custom-inputs {
  max-width: 600px;
  margin: 20px auto 0;

  &.invitation-form {
    margin-top: 20px;

    .input {
      margin-left: 0;
    }
  }
  .is-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @media (max-width: 767px) {
      display: block;
    }

    &.row-custom-input-titles {
      @media (max-width: 767px) {
        display: none;
      }
    }

    label {
      .input-label-wrap {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }
    .col-xs-6 {
      width: 50%;

      @media (max-width: 767px) {
        width: 100%;
      }

      &:first-of-type {
        label {
          display: block;
          padding-right: 5px;
          @media (max-width: 767px) {
            padding-right: 0;
          }
        }
      }

      &:last-of-type {
        label {
          display: block;
          padding-left: 5px;
          @media (max-width: 767px) {
            padding-left: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }

  &__item {
    .is-row {
      margin-bottom: 10px;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    strong {
      font-size: 14px;
    }
  }

  &__add-item {
    text-align: center;
  }
}

.input-with-delete {
  display: flex;
  align-items: center;

  > label {
    flex: 1;
    padding-right: 10px;
  }

  > a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    border-radius: 50%;
    background: #b1bbcb;
    line-height: 15px;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 12px;
    transition: all ease-in-out 0.1s;
    @media (max-width: 767px) {
      margin-top: 25px;
    }
    &:hover {
      background: #cd0000;
    }
  }
}
</style>
