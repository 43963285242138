export function getElementBounds(trim = 0) {
  return [...document.querySelectorAll('.showImages > .page')].map((x, index) => ({
    element: x,
    index,
    coords: [x.offsetTop + trim, x.offsetTop + trim + x.clientHeight],
  }))
}

export function getPage(elementBounds, scrollOfPanel) {
  return elementBounds.find((page, index, array) => {
    if (index === 0 && scrollOfPanel < page.coords[0]) {
      return true
    }

    if (index === array.length - 1 && scrollOfPanel > page.coords[1]) {
      return true
    }

    return scrollOfPanel >= page.coords[0] && scrollOfPanel <= page.coords[1]
  })
}
