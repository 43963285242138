export default () => ({
  display: {
    previousWidth: 0,
    previousHeight: 0,
  },
  signatureColor: '#000000',
  signatureDate: new Date(),
  signatureImage: null,
  signaturePlace: null,
  signatureProgress: [],
})
