import router from '@/app/plugins/router'
import { defineStore } from 'pinia'
import { useContractStore } from '@/features/contracts/stores/contract'
import { fetchAvailableEnvelopSignTypes } from '@/features/contracts/services/contractService'

export const signTypes = ['sign', 'sign_type_a', 'sign_certificate', 'sign_bank_id_sign', 'sign_remote_sign', 'handy_signatur_sign']

export function hasSignIdentitySignAction(signIdentity) {
  return [...signTypes, 'stamp']?.includes(signIdentity?.contract_role) || false
}

export const defaultTypes = {
  sign: {
    translationKey: 'contract.signs.role_sign',
    value: 'sign',
    order: 1,
  },
  sign_type_a: {
    translationKey: 'contract.signs.role_sign',
    value: 'sign_type_a',
    order: 2,
  },
  approve: {
    translationKey: 'contract.signs.role_approve',
    value: 'approve',
    order: 3,
  },
  notice: {
    translationKey: 'contract.signs.role_notice',
    value: 'notice',
    order: 7,
  },
  stamp: {
    translationKey: 'contract.signs.role_stamp',
    value: 'stamp',
    order: 6,
  },
  sign_certificate: {
    translationKey: 'qualified_signature',
    value: 'sign_certificate',
    order: 4,
  },
  sign_bank_id_sign: {
    translationKey: 'bank_id_sign',
    value: 'sign_bank_id_sign',
    order: 5,
  },
  sign_remote_sign: {
    translationKey: 'remote_sign',
    value: 'sign_remote_sign',
    order: 6,
  },
  handy_signatur_sign: {
    translationKey: 'handy_signatur_sign',
    value: 'handy_signatur_sign',
    order: 7,
  }
}

export const useContractSignTypesStore = defineStore('contractSignTypes', {
  state: () => ({
    types: [],
  }),
  actions: {
    async fetchAvailableSignTypes() {
      const contractStore = useContractStore()

      return fetchAvailableEnvelopSignTypes({
        currentRouteName: router.currentRoute.name,
        mainContractId: contractStore.mainContractId,
        next: (resp) => {
          this.types = [...resp?.map((signValue) => Object.assign({}, defaultTypes[`${signValue}`]))]?.sort(
            (a, b) => a.order - b.order,
          )
        },
        catchError: (err) => {
          console.log('fetchContractSignIdentities not ready yet', err)
        },
      })
    },
    resetSignTypes() {
      this.types = []
    },
  },
  getters: {
    availableTypes: (state) => {
      return state.types
    },
  },
})
