<template>
  <SigniModal v-model="show" max-width="400px">
    <Loader v-if="isLoadingCredits" class="register-loader" />

    <div v-else>
      <div class="duplicate-contract__credit-cost">
        <IcoInfo />
        {{ $t('contract.buy_long.text') }}
        {{ $tc('contract.buy_long.x_credits', creditCosts, { count: creditCosts }) }}.
      </div>

      <v-btn color="primary" x-large block @click="duplicateContract()">
        {{ $t('contract.duplicate') }}
      </v-btn>
    </div>
  </SigniModal>
</template>

<script>
import { ContractService } from '@/services/ContractService.js'
import IcoInfo from '@/components/svg/IcoInfo'
import Loader from '@/components/Loader.vue'
import SigniModal from '@/components/SigniModal'

export default {
  name: 'DuplicateContract',
  components: {
    IcoInfo,
    SigniModal,
    Loader,
  },
  data() {
    return {
      isLoadingCredits: true,
      creditCosts: null,
    }
  },
  props: {
    value: Boolean,
    contract: {
      type: Object,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.loadUserCredits()
  },
  methods: {
    loadUserCredits() {
      this.isLoadingCredits = true

      ContractService.getDuplicatedPrice(this.contract).then((response) => {
        this.creditCosts = response.price
        this.isLoadingCredits = false
      })
    },
    duplicateContract() {
      ContractService.duplicate(this.contract, () => {
        this.$notification.error(this.$t('upload.error_general'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.duplicate-contract__credit-cost {
  display: flex;
  align-items: flex-start;
  font-style: italic;
  color: #616161;
  margin: 1rem 0;

  svg {
    width: 1.8rem;
    padding-right: 0.4rem;
    margin-top: 4px;
  }

  p {
    text-align: left;
  }
}
</style>
