<template>
  <v-dialog :value="showDialog" max-width="30rem" width="100%">
    <v-card>
      <v-toolbar class="pt-1" dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="onCloseDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title class="px-2 px-md-5">
        <h2 class="headline font-weight-medium mb-0 text-center">
          {{ $t('contract.signs.approve_help') }}
        </h2>
      </v-card-title>

      <v-card-text class="px-4 pb-5">
        <v-row align="center" justify="center" no-gutters>
          <v-col
            v-for="signature in filteredEditedUnsignedSignatures"
            :key="signature.signIdentity.id"
            class="pa-4"
            :class="`col-${$vuetify.breakpoint.mobile ? 12 : 10}`"
          >
            <v-row align="center" class="sign-identity__box">
              <v-col cols="auto">
                <SAvatar
                  :size="40"
                  :firstname="signature.firstname"
                  :lastname="signature.lastname"
                  :profile-image="signature.signIdentity.avatar_url"
                />
              </v-col>

              <v-col class="px-1">
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <span
                      class="sign-identity__name font-weight-bold"
                      :class="{
                        'subtitle-1': $vuetify.breakpoint.mobile,
                        title: !$vuetify.breakpoint.mobile,
                      }"
                    >
                      {{ signature.firstname }} {{ signature.lastname }}
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <span class="subtitle-2 sign-identity__description font-weight-regular">
                      {{ signature.signIdentity.organization_name || $t('general.signature') }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="auto">
                <v-btn icon @click="onRemoveSignIdentity(signature)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="canAddSignIdentity" class="col-10">
            <ContractSignIdentitiesManagementAdd
              :available-signatories="availableSignatories"
              :unsigned-signatures="unsignedSignatures"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-4 pb-8">
        <v-btn
          color="primary"
          class="mx-auto"
          :loading="savingIdentities"
          :block="$vuetify.breakpoint.mobile"
          max-width="12rem"
          width="40%"
          @click="onSaveSignIdentities"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContractHelper } from '@/helpers/ContractHelper'
import SAvatar from '@/common/components/SAvatar'
import ContractSignIdentitiesManagementAdd from '@contract/components/ContractSignIdentitiesManagementAdd'

export default {
  name: 'ContractSignIdentitiesManagement',
  components: {
    ContractSignIdentitiesManagementAdd,
    SAvatar,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    unsignedSignatures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      savingIdentities: false,
    }
  },
  computed: {
    availableSignatories() {
      return this.unsignedSignatures
        .filter((s) => !ContractHelper.isSigningContractRole(s.signIdentity))
        .map((item) => ({
          ...item.signIdentity,
          name: `${item.signIdentity.firstname} ${item.signIdentity.lastname}`,
        }))
    },
    canAddSignIdentity() {
      return this.availableSignatories?.length > 0
    },
    filteredEditedUnsignedSignatures() {
      return this.unsignedSignatures.filter(
        (item) => item && item.signIdentity && ContractHelper.isSigningContractRole(item.signIdentity),
      )
    },
  },
  methods: {
    onSaveSignIdentities() {
      this.$emit('save-sign-identities')
    },
    onRemoveSignIdentity(signature) {
      this.$emit('remove-sign-identity', signature)
    },
    onCloseDialog() {
      this.$emit('close-dialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.sign-identity__box {
  border: 1px solid #dfe7f3;
  border-radius: 6px;
}

.sign-identity__name {
  color: #5e239e;
  letter-spacing: -0.03px;
}

.sign-identity__description {
  color: #8e97a4;
  letter-spacing: -0.03px;
}
</style>
