<template lang="html">
  <Data :loader="isLoadingTemplates" :panelLoad="panelLoad" :isDashboard="true" showMenu>
    <div v-if="areTemplatesDisabled" class="templates-tutorial-wrapper">
      <TutorialTab
        :id="'addDocument'"
        :heading="$t('templates.text_disabled.title')"
        :content="disabledTextContent"
        :type="'banner'"
        :collapsed="false"
        :icon="'/images/macek-legal.png'"
        :borderStyle="'shadow'"
        :background="'#fff'"
      />
    </div>
    <template v-else>
      <TemplatesTable :title="tableTitle" :templates="visibleTemplates" :isLinkHidden="panelLoad" />
    </template>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex'
import { TemplateService } from '@/services/TemplateService.js'
import TutorialTab from '@/views/tutorial/components/TutorialTab'
import TemplatesTable from '@dashboard/components/TemplatesTable'

export default {
  name: 'Templates',
  components: {
    TutorialTab,
    TemplatesTable,
  },
  data() {
    return {
      tableTitle: '',
      visibleTemplates: [],
      workspaceTemplates: [],
      publicTemplates: [],
      lastUsedTemplates: [],
      isLoadingTemplates: true,
      disabledTextContent: `
        <p>${this.$t('templates.text_disabled.p1')}</p>
        <p>${this.$t('templates.text_disabled.p2html')}</p>
        <p>${this.$t('templates.text_disabled.p3html')}</p>
      `,
      filters: [
        {
          query: 'workspace',
          title: this.$t('dashboard.workspace_patterns'),
          getTemplates: () => this.workspaceTemplates,
          isWorkspaceFilter: true,
        },
        {
          query: 'public',
          title: this.$t('dashboard.general_patterns'),
          getTemplates: () => this.publicTemplates,
        },
        {
          query: 'last_used',
          title: this.$t('dashboard.last_used'),
          getTemplates: () => this.lastUsedTemplates,
        },
        {
          query: 'all',
          title: this.$t('docs.all'),
          getTemplates: () => this.workspaceTemplates.concat(this.publicTemplates),
          isWorkspaceFilter: true,
        },
      ],
    }
  },
  props: {
    panelLoad: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
      profile: 'profile',
    }),
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id)
    },
    areTemplatesDisabled() {
      return this.activeWorkspace && !this.activeWorkspace.settings.contracts.can_create_form_contract
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.areTemplatesDisabled) {
        this.isLoadingTemplates = false
        this.reloadMenuLinks([])
        return
      }
      TemplateService.getWorkspaceTemplates(this.$route.params.workspace_id).then((response) => {
        this.workspaceTemplates = response.templates.filter((t) => t.workspace_id)
        this.publicTemplates = response.templates.filter((t) => !t.workspace_id)
        this.lastUsedTemplates = response.last_used || []
        if (this.panelLoad) {
          this.tableTitle = this.$t('templates')
          this.visibleTemplates = response.templates
          this.isLoadingTemplates = false
          return
        }
        if (!this.getActiveFilter()) {
          const defaultFilter = this.getAvailableFilters()[0]
          this.changeQueryString(defaultFilter)
        }
        this.selectVisibleTemplates()
        this.isLoadingTemplates = false
      })
    },
    selectVisibleTemplates() {
      const availableFilters = this.getAvailableFilters()
      const selectedFilter = availableFilters.find((f) => this.isActiveFilter(f)) || availableFilters[0]
      this.tableTitle = selectedFilter.title
      this.visibleTemplates = selectedFilter.getTemplates()
      this.reloadMenuLinks(availableFilters)
    },
    getAvailableFilters() {
      if (this.workspaceTemplates.length) {
        return this.filters
      }
      return this.filters.filter((f) => !f.isWorkspaceFilter)
    },
    reloadMenuLinks(activeFilters) {
      if (!activeFilters || !activeFilters.length) {
        return this.$store.commit('dashboardSetTemplateLinks', [])
      }
      this.$store.commit(
        'dashboardSetTemplateLinks',
        activeFilters.map((f) => ({
          title: f.title,
          action: () => this.changeQueryString(f),
          isActive: this.isActiveFilter(f),
        })),
      )
    },
    changeQueryString(filter) {
      this.$router.replace({
        name: 'template',
        query: {
          templates: filter.query,
          contacts: this.$route.query.contacts || undefined,
        },
      })
    },
    isActiveFilter(filter) {
      const activeFilter = this.getActiveFilter()
      return (!activeFilter && !filter.query) || String(activeFilter) == String(filter.query)
    },
    getActiveFilter() {
      return (this.$route.query || {}).templates
    },
  },
  watch: {
    $route: 'selectVisibleTemplates',
  },
}
</script>

<style lang="scss">
.templates-tutorial-wrapper {
  max-width: 770px;
  padding: 0;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 70px;
}
</style>
