const routes = [
  {
    path: '/dashboard/workspace/:workspace_id/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "dashboard__contacts-list" */ './views/Contacts'),
  },
  {
    path: '/dashboard/workspace/:workspace_id/contacts/:contact_id',
    name: 'contactDetail',
    component: () => import(/* webpackChunkName: "dashboard__contacts-detail" */ './views/ContactDetail'),
  },
]

export default routes
