import { fullyLocalizedLocales } from '@/app/config/locales'
import i18n from '@/app/plugins/i18n'

export const availableNewsLocales = () => {
  return fullyLocalizedLocales
}

export const title = () => {
  return i18n.t('news.header.title')
}

export const link = () => {
  return i18n.t('news.header.link')
}

export const messages = (state) => {
  const messages = []
  const currentLocale = i18n.locale

  state.messages.forEach((message) => {
    messages.push({
      id: message.id,
      title:
        'undefined' === typeof message.title[currentLocale] || null === message.title[currentLocale]
          ? message.title[i18n.fallbackLocale]
          : message.title[currentLocale],
      subtitle:
        'undefined' === typeof message.subtitle[currentLocale] || null === message.subtitle[currentLocale]
          ? message.subtitle[i18n.fallbackLocale]
          : message.subtitle[currentLocale],
      link:
        'undefined' === typeof message.link[currentLocale] || null === message.link[currentLocale]
          ? message.link[i18n.fallbackLocale]
          : message.link[currentLocale],
    })
  })

  return messages
}
