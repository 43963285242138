<template lang="html">
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="dashboard-documents__wrapper dashboard-reports">
      <h1 class="dashboard-documents__heading">{{ $t('reports.credits') }}</h1>
      <div class="dashboard-documents__table-wrapper">
        <div class="dashboard-documents__filters">
          <div class="dashboard-documents__filters-desktop reports-filters">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.fulltext')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>

            <div class="dashboard-datepicker" :class="{ 'dashboard-datepicker-open': isDatepickerOpened }">
              <div
                @click.prevent.stop="clearCreatedAt"
                v-if="datepickerCreatedModel.startDate"
                class="dashboard-datepicker-clear-icon"
              ></div>
              <div v-else class="dashboard-datepicker-icon"></div>
              <date-range-picker
                ref="picker"
                :opens="'left'"
                v-model="datepickerCreatedModel"
                :locale-data="datepickerLocale"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :auto-apply="true"
                :ranges="false"
                @toggle="toggleCreatedDatepicker"
                @select="updateCreatedAt"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  <template v-if="picker && picker.startDate">
                    <span v-line-clamp="1"
                      >{{ $t('general.created_at') }}: {{ picker.startDate | formatDate }} -
                      {{ picker.endDate | formatDate }}</span
                    >
                  </template>
                  <template v-else>
                    <span>{{ $t('general.created_at') }}:</span>
                  </template>
                </template>
              </date-range-picker>
            </div>

            <v-btn
              color="primary"
              :loading="isBuildingCsv"
              :disabled="isBuildingCsv"
              class="ml-auto"
              x-large
              @click="downloadCsv"
            >
              {{ $t('reports.download_csv') }}
            </v-btn>
          </div>
        </div>

        <v-data-table
          :items="rows"
          :headers="headers"
          :loading="isLoadingDocuments"
          :server-items-length="totalRowsCount"
          :options.sync="options"
          :no-data-text="$t('table.noResults')"
          :no-results-text="$t('table.noResults')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }"
        >
          <template v-slot:item.user.fullname="{ item }">
            <v-card flat style="background: transparent">
              <v-card-subtitle
                :title="item.user.email"
                class="pa-0 mt-1 text-subtitle-1 font-weight-bold"
                :style="$vuetify.breakpoint.mobile ? 'text-align: left' : ''"
              >
                {{ item.user.fullname }}
              </v-card-subtitle>

              <div class="d-flex mb-1">
                {{ item.contract.is_deleted ? $t('general.deleted_at') : $t('general.created_at') }}:

                <v-card-actions class="py-0">
                  <router-link
                    v-if="!item.contract.is_deleted"
                    class="report-router-link"
                    :to="{
                      name: 'documentsDetail',
                      params: {
                        workspace_id: item.contract.workspace_id,
                        contract_id: item.contract.id,
                      },
                    }"
                    target="_blank"
                  >
                    {{ item.contract.title }}
                  </router-link>

                  <span v-else>
                    {{ item.contract.title }}
                  </span>
                </v-card-actions>
              </div>
            </v-card>
          </template>

          <template v-slot:item.created_at="{ item }">
            <span>{{ getFormattedDate(item.created_at) }}</span>
          </template>

          <template v-slot:item.credits.amount="{ item }">
            <span
              :class="{
                'document-table-item__credits-amount-positive': item.credits.amount >= 0,
                'document-table-item__credits-amount-negative': item.credits.amount < 0,
              }"
            >
              {{ item.credits.amount > 0 ? `+${item.credits.amount}` : item.credits.amount }}
            </span>
          </template>
        </v-data-table>
      </div>
    </div>
  </Data>
</template>

<script>
import { DataService } from '@/services/DataService.js'
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import moment from 'moment'
import FileSaver from 'file-saver'
import { parseDate } from '@/services/helpers/parseDate.js'
import DateRangePicker from 'vue2-daterange-picker'
import LoadMore from '@/views/dashboard/components/LoadMore'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'Reports',
  components: {
    DateRangePicker,
    LoadMore,
  },
  filters: {
    formatDate: (jsDate) => (jsDate ? parseDate(moment(jsDate).format('YYYY-MM-DD')) : ''),
    formatDatetime: (jsDate) => moment(jsDate).format('D. M. YYYY HH:mm:ss'),
  },
  data() {
    return {
      isLoadingFirstRequest: true,
      isLoadingDocuments: true,
      isBuildingCsv: false,
      debounceTimeout: false,
      rows: [],
      isDatepickerOpened: false,
      datepickerCreatedModel: {
        startDate: null,
        endDate: null,
      },
      datepickerLocale: {
        firstDay: 1,
        format: 'DD. MM. YYYY', // TODO: i18n - move date format to locale + use it in parseDate
        daysOfWeek: [
          this.$t('calendar.shortDays.sun'),
          this.$t('calendar.shortDays.mon'),
          this.$t('calendar.shortDays.tue'),
          this.$t('calendar.shortDays.wed'),
          this.$t('calendar.shortDays.thu'),
          this.$t('calendar.shortDays.fri'),
          this.$t('calendar.shortDays.sat'),
        ],
        monthNames: [
          this.$t('calendar.months.jan'),
          this.$t('calendar.months.feb'),
          this.$t('calendar.months.mar'),
          this.$t('calendar.months.apr'),
          this.$t('calendar.months.may'),
          this.$t('calendar.months.jun'),
          this.$t('calendar.months.jul'),
          this.$t('calendar.months.aug'),
          this.$t('calendar.months.sep'),
          this.$t('calendar.months.oct'),
          this.$t('calendar.months.nov'),
          this.$t('calendar.months.dec'),
        ],
      },
      filters: {
        workspace_id: this.$route.params.workspace_id,
        searchQuery: '',
        createdAt: {
          from: null,
          to: null,
        },
      },
      page: 0,
      limit: 50,
      totalRowsCount: 0,
      hasNextPage: false,
      headers: [
        {
          text: this.$t('workspace.team.title'),
          value: 'user.fullname',
          sortable: false,
          width: '60%',
        },
        {
          text: this.$t('general.created_at'),
          value: 'created_at',
          sortable: false,
          align: 'start',
        },
        {
          text: this.$t('account.credits'),
          value: 'credits.amount',
          sortable: false,
          align: 'end',
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  async created() {
    this.fetchData()
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      this.isLoadingDocuments = true

      DataService.getCreditsReport(this.buildFiltersQuery('json'))
        .then((response) => {
          this.rows = response.data

          response.meta.count && (this.totalRowsCount = response.meta.count)
        })
        .finally(() => {
          this.isLoadingDocuments = false
          this.isLoadingFirstRequest = false
        })
    },
    buildFiltersQuery(format) {
      const normalizeDate = (jsDate) => (jsDate ? moment(jsDate).format('YYYY-MM-DD') : null)

      if (this.options.itemsPerPage) {
        this.limit = -1 === this.options.itemsPerPage ? this.totalRowsCount : this.options.itemsPerPage
      }

      if (this.options.page) {
        this.page = this.options.page - 1
      }

      return {
        workspace_id: this.filters.workspace_id,
        search_query: this.filters.searchQuery,
        created_at: {
          from: normalizeDate(this.filters.createdAt.from),
          to: normalizeDate(this.filters.createdAt.to),
        },
        pagination: {
          limit: this.limit,
          offset: this.page * this.limit,
        },
        format,
      }
    },
    getFormattedDate(date) {
      return getFormattedDateByLocale({
        date,
        options: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        },
      })
    },
    onSearchQueryChange(value) {
      this.updateFilters('searchQuery', value)
    },
    toggleCreatedDatepicker() {
      this.isDatepickerOpened = !this.isDatepickerOpened
    },
    clearCreatedAt() {
      this.datepickerCreatedModel = {
        startDate: null,
        endDate: null,
      }
      this.updateCreatedAt(this.datepickerCreatedModel)
    },
    updateCreatedAt(newDatepickerModel) {
      this.updateFilters('createdAt', {
        from: newDatepickerModel.startDate,
        to: newDatepickerModel.endDate,
      })
    },
    updateFilters(type, newValue) {
      if (JSON.stringify(this.filters[type]) === JSON.stringify(newValue)) {
        return
      }

      this.filters[type] = newValue

      const self = this
      const debounceDuration = 500

      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        self.page = 0
        this.fetchData()
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = false
      }, debounceDuration)
    },
    downloadCsv() {
      this.isBuildingCsv = true
      const filters = this.buildFiltersQuery('csv')

      DataService.getCreditsReport(filters)
        .then((csv) => {
          FileSaver.saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), 'report_credits.csv')
        })
        .finally(() => {
          this.isBuildingCsv = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.document-table-item__credits-amount-positive {
  color: green !important;
}
.document-table-item__credits-amount-negative {
  color: red !important;
}
// hotfix css from Documents - override grid-template-columns, unify widths...
.dashboard-reports.dashboard-documents__wrapper {
  text-align: left;
  .dashboard-documents__filters-desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > div {
      display: block;
    }
    & > div:first-child {
      width: 60%;
    }
    & > div:nth-child(2) {
      width: 25%;
    }
    & > div:nth-child(3) {
      width: 15%;
      text-align: right;
    }
    @include md {
      display: block;
      & > div {
        display: block;
        text-align: left;
        width: 100% !important;
      }
    }
  }

  .report-router-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 19rem;
  }

  :deep(.v-data-table) {
    .v-data-table__mobile-table-row {
      margin: 1rem 0;
    }

    .v-data-table__mobile-row {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    }

    .v-data-table__mobile-row:not(:first-child) {
      margin-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .reports-filters {
    justify-content: unset !important;
  }

  .dashboard-documents__filters-desktop {
    .dashboard-datepicker {
      cursor: pointer;
      position: relative;
      width: 310px;
      outline: none;
      border: 1px solid #b1bbcb;
      border-radius: 6px;
      height: 56px;
      font-size: 16px;
      letter-spacing: -0.03px;
      line-height: 54px;
      font-weight: 300;
      margin: 0 1ex;

      @include md {
        margin: 1ex 0;
      }

      .dashboard-datepicker-icon {
        pointer-events: none;
      }
      &-icon {
        background: url(/images/calendar.svg);
        width: 20px;
        position: absolute;
        right: 20px;
        background-size: contain;
        top: -2px;
        background-repeat: no-repeat;
        height: 100%;
        background-position: center center;
        z-index: 99;
      }
      &-clear-icon {
        background: url(/images/cross.svg);
        width: 15px;
        position: absolute;
        right: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        background-position: center center;
        z-index: 99;
      }
      .row {
        margin: 0;
      }
      .in-range {
        background-color: #712cbd26 !important;
      }
      .start-date,
      .end-date {
        background-color: #712cbd !important;
      }
      .vue-daterange-picker {
        height: 56px;
        width: 100%;

        ::v-deep .reportrange-text {
          display: flex;
          align-items: center;
          background: none;
          border: none;
          height: 56px;
          font-size: 16px;
          color: #808080;
        }

        & > div {
          border: 0;
          width: 100%;
          background: none;
          padding: 0 50px 0 20px;
          color: gray;
          font-weight: 400;
          font-size: 16px;
        }
        .daterangepicker {
          top: 60px;
          right: -1px;
          box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
        }
        .calendars-container {
          width: 100%;
          background: white;

          @include md {
            & > div:first-child {
              width: 100%;
              max-width: unset;
              padding: 10px;
            }
            & > div:nth-child(2) {
              display: none;
            }
          }
        }
      }
      .form-control {
        background: white !important;
        border-radius: 6px;
      }
    }
    .dashboard-datepicker-open {
      border-color: #5e239e;
      border-radius: 6px 6px 0 0;

      .daterangepicker {
        top: 0 !important;
        margin: 0;
        border: 1px solid #5e239e !important;
        border-radius: 6px 0 6px 6px;
        overflow: hidden;
        &:before,
        &:after {
          display: none;
        }
        @include md {
          width: calc(100% + 2px) !important;
          border-radius: 0 0 6px 6px;
        }
      }
    }

    .button--primary {
      margin-bottom: 0;
    }
  }
}
</style>
