<template>
  <div class="signature-footer-settings">
    <h2 class="subtitle-1">
      {{ $t('signature.footer.settings_title') }}
    </h2>

    <v-checkbox
      v-for="settingKey in signatureFooterSettingsKeys"
      :key="`${settingKey}-${signatureFooterSettings[`${settingKey}`]}`"
      v-model="signatureFooterSettings[`${settingKey}`]"
      :label="$t(`footerSettings.${settingKey}`)"
      class="pl-2"
      hide-details
      outlined
      @change="onSaveChanges"
    ></v-checkbox>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SignatureFooterSettings',
  computed: {
    ...mapGetters({
      signatureFooterSettings: 'workspace/signatureFooterSettings',
      signatureFooterSettingsKeys: 'workspace/signatureFooterSettingsKeys',
    }),
  },
  created() {
    this.fetchWorkspaceSignatureFooterSettings(this.$route.params.workspace_id).catch(() => {
      this.$notification.error(this.$t('contract.external_company.error'))
    })
  },
  methods: {
    ...mapActions({
      fetchWorkspaceSignatureFooterSettings: 'workspace/fetchWorkspaceSignatureFooterSettings',
      saveWorkspaceSignatureFooterSettings: 'workspace/saveWorkspaceSignatureFooterSettings',
    }),
    onSaveChanges() {
      this.saveWorkspaceSignatureFooterSettings({
        workspaceId: this.$route.params.workspace_id,
        data: this.signatureFooterSettings,
      }).catch(() => {
        this.$notification.error(this.$t('account.edit.failed'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.signature-footer-settings {
  padding: 1rem 0 1rem;
}
</style>
