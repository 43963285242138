<template>
  <div
    class="contract-document-tab pa-5"
    :class="{
      'contract-document-tab__active': canShowHighlighting,
      'pl-4': isMobileDevice,
      'pl-2': !isMobileDevice,
    }"
  >
    <v-row align="center" justify="space-between" no-gutters>
      <v-col :cols="isMobileDevice ? 2 : 3" class="mr-2">
        <v-btn v-if="isMobileDevice" icon class="pt-0 mt-0" @click="changeContract(contract.id)">
          <v-icon v-if="isDocumentOpen" class="pt-0 mt-0" color="primary" large> mdi-chevron-up </v-icon>
          <v-icon v-else color="primary" large> mdi-chevron-down </v-icon>
        </v-btn>

        <v-btn v-if="canChangeVisibility && hasAttachments" icon class="pt-0 mt-0" style="pointer-events: none">
          <v-icon> mdi-drag </v-icon>
        </v-btn>

        <v-btn icon class="pt-0 mt-0" style="pointer-events: none">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="20" rounded v-bind="attrs" v-on="on">
                <v-icon v-if="'form' === contract.type" color="primary"> mdi-form-select </v-icon>
                <v-icon v-else :color="`${isAllContractSignaturesPlaced ? 'success' : '#F8E71D'}`">
                  {{ isAllContractSignaturesPlaced ? 'mdi-check' : 'mdi-alert' }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{
              isAllContractSignaturesPlaced ? $t('contract.positions.done') : $t('contract.info.undone')
            }}</span>
          </v-tooltip>
        </v-btn>
      </v-col>

      <v-col :cols="isMobileDevice ? 5 : 6" class="text-left pr-0 mr-auto" :class="{ 'pl-3': isMobileDevice }">
        <v-row>
          <v-hover v-slot="{ hover }">
            <a
              href="#"
              class="text-decoration-none text-left pl-0 pr-auto primary--text"
              :class="{ 'text-decoration-underline': hover }"
              style="word-wrap: break-word; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
              @click.prevent="changeContract(contract.id)"
            >
              <span>
                {{ contract.title }}
              </span>
            </a>
          </v-hover>
        </v-row>

        <v-row v-if="canChangeVisibility && !contract.is_attachment && hasAttachments">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="primary" outlined x-small label v-bind="attrs" v-on="on">
                {{ $t('upload.main_document') }}
              </v-chip>
            </template>

            <span>{{ $t('main_document.info') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>

      <v-col v-show="canChangeVisibility && contract.is_attachment" cols="1" class="mr-1 mr-lg-3 text-center">
        <v-badge left bottom offset-x="18" offset-y="18" :content="visibleUsersCount">
          <template>
            <v-btn icon @click="manageContractVisibility = true">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
        </v-badge>
      </v-col>

      <v-col cols="1" class="text-left pr-0">
        <v-menu transition="slide-y-transition" bottom offset-y style="z-index: 1001 !important">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0" max-width="17rem" width="17rem">
            <v-list-item v-if="canChangeContract" link @click="replaceContractDocument">
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.replace_document') }}
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="canChangeContract && canDeleteContract" class="mx-2"></v-divider>

            <v-list-item v-if="canDeleteContract" link @click.prevent="showDeleteContractDocumentDialog = true">
              <v-list-item-icon class="mr-3">
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.remove_document') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider v-if="isDocumentOpen && isMobileDevice" class="mt-4 ml-2 mr-1 mb-2"></v-divider>

    <v-row v-if="isDocumentOpen && isMobileDevice">
      <v-col cols="12" :class="{ 'pa-0 mt-1': contract.type !== 'form' }">
        <slot></slot>
      </v-col>
    </v-row>

    <ContractVisibilityManagement
      v-model="manageContractVisibility"
      :contract="contract"
      @close-dialog="manageContractVisibility = false"
    />

    <SConfirmDialog
      v-model="showDeleteContractDocumentDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteContractDocument"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { ContractService } from '@/services/ContractService'
import { useContractSignaturesPlaceholdersStore } from '@/features/contract/stores/contractSignaturesPlaceholdersStore'
import SConfirmDialog from '@/common/components/SConfirmDialog'
import { mapGetters } from 'vuex'
import { internalApiRequest } from '@/shared/services/apiService'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'

export default {
  name: 'CreateContractDocumentTab',
  components: {
    ContractVisibilityManagement: () => import('@/features/contract/components/ContractVisibilityManagement'),
    SConfirmDialog,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    contracts: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showDeleteContractDocumentDialog: false,
      open: false,
      manageContractVisibility: false,
      visibleUsersCount: '',
    }
  },
  computed: {
    ...mapState(useContractSignaturesPlaceholdersStore, {
      hasContractPlacedAllSignIdentities: 'hasContractPlacedAllSignIdentities',
    }),
    ...mapGetters({
      documentInfo: 'pdfDocument/documentInfo',
      profile: 'profile',
      workspaceById: 'workspaceById',
    }),
    canShowHighlighting() {
      return this.isDocumentOpen && !this.isMobileDevice
    },
    canChangeContract() {
      return 'doc' === this.contract.type
    },
    canChangeVisibility() {
      return 'draft' === this.contract.state && 'doc' === this.contract.type && this.isContractOwner
    },
    canDeleteContract() {
      return this.contracts?.length > 1
    },
    currentWorkspace() {
      return this.workspaceById(this.contract.workspace_id)
    },
    hasAttachments() {
      return this.contracts?.length > 0
    },
    isContractOwner() {
      return this.profile?.id === this.contract?.creator?.user_id || false
    },
    isDocumentOpen() {
      return parseInt(this.$route.params.contract_id) === parseInt(this.contract.id) && this.open
    },
    isAllContractSignaturesPlaced() {
      return 'form' === this.contract?.type ? false : this.hasContractPlacedAllSignIdentities(this.contract.id)
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    this.open = true
    this.visibilityUserCount()
  },
  methods: {
    changeContract(contractId = null) {
      if (parseInt(this.contract.id) === parseInt(this.$route.params.contract_id) || null === contractId) {
        this.open = this.isMobileDevice ? !this.open : this.open

        return
      }

      this.open = true
      this.$router.push({
        name: 'createContent',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: contractId,
        },
      })
    },
    replaceContractDocument() {
      const self = this
      setTimeout(() => {
        self.$router.push({
          name: 'createUpload',
          query: {
            replace: 'true',
          },
        })
      }, 450)
    },
    deleteContractDocument() {
      const self = this
      const contractId = this.contract.id

      ContractService.delete(contractId).then(
        () => {
          const nextContract = self.contracts.find(
            (contract) =>
              parseInt(contract.id) !== parseInt(self.$route.params.contract_id) && 'draft' === contract.state,
          )
          const nextNonDraftContract = self.contracts.find(
            (contract) => parseInt(contract.id) !== parseInt(self.$route.params.contract_id),
          )

          if ('undefined' === typeof nextContract?.id) {
            self.$router.push({
              name: 'documentsDetail',
              params: {
                contract_id: self.contract?.main_contract_id || nextNonDraftContract.id,
                workspace_id: self.$route.params.workspace_id,
              },
            })
          }

          this.$notification.success(this.$t('docs.delete.ok'))

          if (parseInt(nextContract.id) !== parseInt(contractId)) {
            return self.$router.push({
              name: 'createContent',
              params: {
                workspace_id: self.$route.params.workspace_id,
                contract_id: nextContract.id,
              },
            })
          }

          location.reload()
        },
        () => {
          this.$notification.error(this.$t('docs.delete_draft.failed'))
        },
      )
    },
    visibilityUserCount() {
      internalApiRequest({
        method: 'GET',
        path: `api/v2/contract/${this.contract.id}/signIdentity/list?showDeleted=true`,
      })
        .then((response) => {
          const visibleUsers = response.filter(
            (signIdentity) => !signIdentity?.is_proposer && !signIdentity?.deleted_at,
          )

          if (!visibleUsers.length) {
            return (this.visibleUsersCount = '0')
          }

          const counterpartySignIdentities = response.filter((signIdentity) => !signIdentity.is_proposer)

          return (this.visibleUsersCount =
            visibleUsers.length !== counterpartySignIdentities.length ? visibleUsers.length : '✓')
        })
        .catch((error) => {
          this.visibleUsersCount = 'x'
          getErrorResponseMessage(error.data)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-document-tab {
  border-bottom: 1px solid #d8d8d8;
  background-color: #ffffff;
  position: relative;

  &__active {
    border-left: 3px solid #7240ab;
  }

  &__active::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    z-index: 1;
    border-bottom: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    background-color: #ffffff;
    transform: rotate(-45deg) translateY(-50%);
    height: 25px;
    width: 25px;
  }
}
</style>
