<template lang="html">
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/arr-20-back.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/arr-20-back.gray"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
    >
      <g
        id="arr/arr-back"
        transform="translate(2.000000, 3.300000)"
        fill-rule="nonzero"
        stroke="currentColor"
        stroke-width="2"
      >
        <polyline
          id="Path-39"
          stroke-linejoin="round"
          transform="translate(3.537718, 6.466064) rotate(-270.000000) translate(-3.537718, -6.466064) "
          points="-2.45962733 3.4673913 3.53771765 9.46473629 9.53506263 3.4673913"
        ></polyline>
        <path d="M1.22981366,6.67857143 L15.782981,6.67857143" id="Path-27"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoArrowBack',
}
</script>
