<template lang="html">
  <transition name="modal-window">
    <div v-if="show" class="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container green-corner">
            <div class="modal-inner">
              <v-btn v-if="enableClose" icon class="modal-default-button" @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <slot></slot>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-blur"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    enableClose: {
      type: [Boolean, String],
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loader: false,
      type: 'nature',
      errorMessage: [],
    }
  },
}
</script>

<style lang="scss">
.modal,
.modal-phone-off {
  position: fixed;
  z-index: 9992;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  overflow: hidden;

  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: block;
  position: relative;
  scroll-behavior: smooth;

  @include md {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.modal-blur {
  position: absolute;
  z-index: 9990;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
}

.modal-wrapper {
  padding: 15px;
  overflow: hidden;

  @include md {
    display: block;
  }
}

.modal-container {
  position: relative;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding: 60px 30px;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: auto !important;
  text-align: center;
  height: 100%;

  @include md {
    padding: 20px;
    display: block;
    overflow: hidden;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  text-align: center;
  margin: 20px 0;
}

.modal-default-button {
  border: none;
  background: white;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-default-left-button {
  border: none;
  background: white;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.modal-inner {
  // overflow: scroll;
}

.modal-window-enter,
.modal-window-leave-to {
  opacity: 0;
}

.modal-window-enter-active,
.modal-window-leave-active {
  transition: opacity 0.5s ease-out;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-radio {
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal-buttons {
  margin-bottom: 15px;
  margin-top: 40px;
}

.modal-wrapper {
  padding: 15px 15px 40px 15px;
  .input {
    width: 321px;
  }
}
.modal-wrapper {
  .radio-wrap {
    max-width: 321px;
  }
}

.new-user-title {
  margin-bottom: 35px;
}

.modal-inner {
  .icon-close:before {
    color: #979797;
    font-size: 16px;
  }
}
</style>
