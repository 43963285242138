import { apiUrl } from '@/app/config/appEnvironment'
import { authHeader } from '@/config/authHeader'
import { autologoutIfUnauthorized } from './helpers'
import router from '@/app/plugins/router'

export const DashboardService = {
  fetchData,
  acceptInvivation,
  declineInvitation,
}

function fetchData(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/dashboard/${id}`, requestOptions).then(handleResponse)
}

function acceptInvivation(id, inputs) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ inputs: inputs }),
  }

  return fetch(`${apiUrl}/api/v1/invitations/${id}/accept`, requestOptions).then(handleResponse)
}

function declineInvitation(id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/invitations/${id}/decline`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    // const data = text && JSON.parse(text);
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }

      autologoutIfUnauthorized(response)

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
