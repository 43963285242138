<template>
  <svg
    width="35px"
    height="35px"
    viewBox="0 0 35 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-20-smlouvy-brown</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-20-smlouvy-brown" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M22.0000134,6 L16.526152,6 C14.8472784,5.99936281 14.0052277,5.99849144 14,5.99738588 L14,25.8575267 C14,25.9128124 14.0014603,25.9603289 14.0034535,26 L26.902781,26 C26.939637,26 26.9721568,25.9986978 27,25.9967876 L27,12 L25.3758994,12 L23.6916484,12 C22.8667069,12 22.3174592,11.4864199 22.118152,10.7229615 C22.0146042,10.3263153 21.9994745,10.0100089 22.0000136,9.14766996 C22.0000441,9.09874668 22.0000441,9.09874668 22.0000541,9.04974853 L22.0000134,6 Z M24.0000541,7.74606444 L24.0000541,9.04974853 C24.0000438,9.09977924 24.0000438,9.09977924 24.0000132,9.14892023 C23.9997473,9.57414914 24.0046705,9.82947595 24.0194356,10 L26.2754466,10 L24.0000541,7.74606444 Z M12,10.0463604 C10.1498226,10.807797 9.05863513,11.2481937 8.68916155,11.3805193 L12,20.4769732 L12,10.0463604 Z M14.7381578,28 L15.4817298,30.0429472 C15.5006386,30.0948987 15.5182624,30.1390501 15.5337037,30.1756471 L21.5112449,28 L14.7381578,28 Z M28.3199761,27.5905312 L28.3391468,27.6432025 L28.0923674,27.7330228 C27.9271645,27.8204842 27.7444803,27.8864362 27.5486807,27.9309086 L16.0615152,32.1118949 C14.8735215,32.5442893 14.0356949,31.9176078 13.6023445,30.7269874 L12.3383883,27.2542962 C12.1970894,27.0297108 12.1012881,26.7611989 12.0486588,26.458271 L6.79432581,12.0221097 C6.40259905,10.9458493 6.90345565,9.90488947 8.03398722,9.49340963 C8.38938656,9.36405485 9.7228394,8.82281147 12,7.88316932 L12,5.95221164 C12,4.80687922 12.8266805,4 14.029767,4 L23.06025,4 L29,9.88373814 L29,26.1386596 C29,26.7979732 28.7396179,27.281701 28.3199761,27.5905312 Z"
        id="Combined-Shape"
        fill="#8B572A"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoInvoices',
}
</script>
