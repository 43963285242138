import * as types from './mutation_types'
import { UserService } from '@/services/UserService'
import { authorizedApiRequest } from '@/services/ApiService'

export const setLoading = (context) => {
  context.commit(types.SET_LOADED, false)
}

export const setReady = (context) => {
  context.commit(types.SET_LOADED, true)
}

export const setError = (context, payload) => {
  context.commit(types.SET_ERROR, payload)
}

/**
 * Account Profile
 */
export const fetchProfile = ({ commit }, workspaceId) => {
  return UserService.loadLoggedUser(workspaceId).then((userProfile) => {
    commit(types.SET_PROFILE, userProfile)

    return userProfile
  })
}

export const updateProfile = ({ dispatch }, payload) => {
  return UserService.update(payload).then(() => {
    return dispatch('account/fetchProfile').then((userProfile) => {
      return userProfile
    })
  })
}

/**
 * Account Signature
 */
export const fetchSignature = ({ commit }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: 'api/v2/loggedUser/signature',
  })
    .then((resp) => {
      commit(types.SET_SIGNATURE, resp)
    })
    .catch((err) => {
      commit(types.SET_ERROR, err)
    })
}

export const setSignature = ({ commit }) => {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: 'api/v2/loggedUser/signature',
  })
    .then((resp) => {
      commit(types.SET_SIGNATURE, resp)
    })
    .catch((err) => {
      commit(types.SET_ERROR, err)
    })
}

export const deleteSignature = ({ commit }) => {
  return authorizedApiRequest({
    method: 'DELETE',
    endpoint: 'api/v2/loggedUser/signature',
  })
    .then((resp) => {
      commit(types.SET_SIGNATURE, resp)
    })
    .catch((err) => {
      commit(types.SET_ERROR, err)
    })
}
