<template lang="html">
  <Data
    class="data-sign"
    :loader="isLoading"
    :showMenu="false"
    :shadow="false"
    :contractSignature="true"
    :showHeader="showHeader"
    :withoutAfterGraphic="true"
  >
    <Signature
      :signIdentity="signIdentity"
      :contract="contract"
      @contractSignedOnRemoteDevice="contractSignedOnRemoteDevice"
      @save="signatureSave"
    />
  </Data>
</template>

<script>
import { mapGetters } from 'vuex'
import { ContractService } from '@/services/ContractService.js'
import { ContractHelper } from '@/helpers/ContractHelper'
import Signature from '@signature/views/Signature'

export default {
  name: 'createSign',
  components: {
    Signature,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      signIdentity: 'signIdentity',
    }),
    isLoading() {
      return !this.loaded
    },
    showHeader() {
      return !this.$vuetify.breakpoint.mobile && this.$vuetify.breakpoint.width <= this.$vuetify.breakpoint.height
    },
  },
  created() {
    this.fetchData()
    window.dataLayer.push({ event: 'contract_proposal_sign_requested' })
  },
  methods: {
    fetchData() {
      this.loaded = false

      ContractService.detail(this.$route.params.contract_id, this.$route.params.workspace_id).then(
        (contract) => {
          this.$store.commit('setContract', contract)
          this.loaded = true

          if (!this.contract.one_device && ContractHelper.isSignedByMe(this.contract)) {
            this.$router.push({
              name: 'documentsDetail',
              params: {
                workspace_id: this.contract.workspace_id,
                contract_id: this.contract.id,
              },
            })
          }
        },
        () => {
          this.$notification.error(this.$t('contract.not_loaded'))
          this.loaded = true
        },
      )
    },
    contractSignedOnRemoteDevice(contract) {
      this.$store.commit('setContract', contract)
      this.checkUnsignedDocuments()
    },
    signatureSave(event) {
      ContractService.sendSignatureAuthorized(
        event.signature,
        event.data,
        event.contract_id,
        event.sign_identity_id,
        event.progress,
      ).then((response) => {
        // TODO: i18n - displaying backend error message
        if (parseInt(response.code) === 400 && String(response.title) === 'Smlouva je již uzavřená') {
          this.$notification.error(response.title)
          this.loaded = true

          this.$router.push({
            name: 'documentsDetail',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
          })

          return
        } else if (response.code === 406) {
          this.$notification.error(this.$t('contract.errors.signature_empty'))
          this.loaded = true

          return
        } else if (response.code >= 400) {
          this.$notification.error(response.title)
          this.loaded = true

          return
        }

        if (response.state === 'completed') {
          window.dataLayer.push({ event: 'contract_proposal_sign_completed' })
        }

        if (event.fromSMS) {
          this.$notification.success(this.$t('contract.completed.sms_sign'))
          this.$router.push({ name: 'success-sign-sms' })
        } else {
          this.checkUnsignedDocuments(response)
        }
      })
    },
    checkUnsignedDocuments(responseContract = null) {
      const contract = responseContract === null ? this.contract : responseContract

      const unApprovedContract = ContractHelper.getNextUnsigned(contract)

      if (unApprovedContract) {
        this.$router.push({
          name: this.signIdentity.is_proposer ? 'documentsDetail' : 'documentsDetailCounterparty',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: unApprovedContract.id,
          },
          query: {
            showFull: true,
            viewContract: true,
          },
        })
      } else {
        // TODO: workflow - copy-pasted in DataContractCounterparty, it should be handled by getNextUnsigned if one-device contract has no attachments
        if (contract.one_device && contract.state !== 'completed') {
          this.$router.push({
            name: 'documentsDetailCounterparty',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: contract.main_contract_id || contract.id,
            },
            query: {
              viewContract: true,
              complete: true,
            },
          })
        } else {
          this.$store.commit('setContract', contract)
          this.$router.push({
            name: 'contractsCollectionCreated',
            params: {
              contractId: contract.id,
              workspaceId: this.$route.params.workspace_id,
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
