<template>
  <v-skeleton-loader v-bind="attrs"></v-skeleton-loader>
</template>

<script>
export default {
  name: 'DocumentPageLoader',
  data() {
    return {
      attrs: {
        class: 'px-5 px-md-10 py-5 pb-md-10 mb-5',
        elevation: 0,
        type: `
          article,
          paragraph,
          table-heading,
          list-item-two-line,
          paragraph,
          image,
          table-tfoot,
          paragraph,
          list-item-two-line,
          list-item-three-line,
          list-item-avatar-three-line,
          paragraph,
          sentences,
          text
        `,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
