import _ from 'lodash'
import VueI18n from 'vue-i18n'
import i18n from '@/app/plugins/i18n'
import { FALLBACK_LOCALE } from '@/app/config/locales'
import { loadTranslationsMessages } from '@digitalfactorycz/core/dist/lib/config/applicationTranslations'

const errors = {
  Authentication: {
    Expired: 'auths.timeout.title',
  },
  FOS: {
    RestBundle: {
      Exception: {
        InvalidParameterException: 'error.unsupported_chars',
      },
    },
  },
  Signi: {
    Exceptions: {
      Auth: {
        AuthNotSubmittedException: 'auths.not_all_submitted',
      },
      BankIdSignException: 'contract.bank_id_sign.not_allowed',
      Contract: {
        ContractCreditException: 'general.error',
        ContractLackCreditException: 'upload.error_credits',
      },
      Document: {
        DocumentFailedException: 'document.check_failed',
      },
      DocsPerson: {
        DocsPersonCannotChangeMobileException: 'contract.parties.party.missing_phone_number',
      },
      NotFoundException: 'general.data_loading_error',
      Sms: {
        SmsPin: {
          SmsPinExpiredException: 'auth.sms.expired_pin',
          SmsPinMismatchException: 'auth.sms.wrong_pin',
          SmsPinOverflowAttemptsException: 'auth.sms.overflow_attempt',
        },
      },
    },
  },
}

export const errorsI18n = new VueI18n({
  locale: i18n.locale,
  fallbackLocale: FALLBACK_LOCALE,
  messages: loadTranslationsMessages({
    path: 'exports/errors',
  }),
  pluralizationRules: {
    cs: (count) => (1 === count ? 0 : count < 5 ? 1 : 2),
  },
  escapeParameterHtml: true,
})

export const getErrorMessage = ({ errorCode, errorValues = null, locale = null }) => {
  return i18n.t(
    _.get(errors, errorCode) || 'general.error',
    null === locale ? i18n.locale : locale,
    null === errorValues ? {} : errorValues,
  )
}

export const getErrorResponseMessage = (data, locale = null) => {
  if (!data?.translationKey) {
    if (data?.errorCode) {
      return getErrorMessage({
        errorCode: data.errorCode,
      })
    }

    return data?.message || i18n.t('general.error')
  }

  return errorsI18n.t(
    data?.translationKey || 'general.error',
    null === locale ? i18n.locale : locale,
    data?.values ? data?.values : {},
  )
}
