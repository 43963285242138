<template lang="html">
  <svg
    width="43px"
    height="44px"
    viewBox="0 0 43 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="SYN001---DSKTP---Návrh---text-editace---0"
        transform="translate(-717.000000, -341.000000)"
        fill-rule="nonzero"
      >
        <g
          id="Group-5"
          transform="translate(738.500000, 363.000000) scale(-1, 1) translate(-738.500000, -363.000000) translate(717.000000, 341.000000)"
        >
          <path
            d="M36.419794,43.9628739 C42.1814622,43.9628739 42.0513589,40.2075984 42.0513589,32.7598619 C42.0513589,27.7947042 41.491304,16.8747502 40.3711942,0 L1,41.6410184 C20.7720838,43.188922 32.5786818,43.9628739 36.419794,43.9628739 Z"
            id="Path-16"
            fill="#000000"
            opacity="0.08"
          ></path>
          <path
            d="M0,41 C20.9161164,41 32.3298182,41 34.2411054,41 C39.8213061,41 40,36.3837602 40,28.5826408 C40,23.3818946 40,13.8543477 40,0 L0,41 Z"
            id="Path-16-Copy"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoEnvelopeWrap',
}
</script>
