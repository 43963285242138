<template>
  <div
    v-if="areTemplatesLoaded"
    class="dashboard-documents__wrapper dashboard-templates"
    :class="{
      'dashboard-templates-favourites': !isSearchable,
    }"
  >
    <component :is="headingTag" class="dashboard-documents__heading">
      {{ title }}
    </component>

    <div class="dashboard-documents__filters" v-if="isSearchable">
      <div class="dashboard-documents__filters-desktop">
        <v-text-field
          type="text"
          v-model="searchQuery"
          :placeholder="$t('general.try_secrecy')"
          prepend-inner-icon="mdi-magnify"
          class="filters__search--input"
          hide-details
          clearable
          outlined
        ></v-text-field>
      </div>
    </div>

    <v-data-table
      :search="searchQuery"
      :items="rows"
      :hide-default-header="noSearch"
      :hide-default-footer="noSearch"
      :headers="headers"
      :no-data-text="$t('table.noResults')"
      :no-results-text="$t('table.noResults')"
      :loading="loading"
    >
      <template v-slot:item.title="{ item }">
        <v-icon color="#B4C1D6" left>mdi-file-outline</v-icon>
        <span>{{ item.title }}</span>
      </template>

      <template v-slot:item.credit_cost="{ item }">
        <span :style="parseInt(item.credit_cost) ? '' : 'color: green'">
          {{
            parseInt(item.credit_cost)
              ? `${$t('contract.buy_short.text')}
         ${$tc('contract.buy_short.x_credits', item.credit_cost, { count: item.credit_cost })}`
              : $t('contract.template_free')
          }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" @click="createContract(item)" :disabled="loading">
          {{ $t('general.create') }}
        </v-btn>
      </template>
    </v-data-table>

    <div v-if="isLinkDisplayed" class="mt-5 d-flex align-center">
      <v-btn
        @click="showAllTemplates()"
        style="font-size: 1rem; text-decoration: underline"
        class="mx-auto"
        color="primary"
        plain
        text
        large
      >
        {{ $t('dashboard.show_contract_patterns') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ContractService } from '@/services/ContractService'
import IcoFile from '@/components/svg/IcoFile.vue'
import IcoFilePrev from '@/components/svg/IcoFilePrev.vue'
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue'

export default {
  name: 'TemplatesTable',
  components: {
    IcoFile,
    IcoFilePrev,
    IcoContractRejected,
  },
  props: ['title', 'templates', 'noSearch', 'isLinkHidden'],
  data() {
    return {
      searchQuery: '',
      rows: null,
      loading: false,
      headers: [
        {
          text: this.$t('general.title'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '60%',
        },
        {
          text: this.$t('general.price'),
          align: 'start',
          sortable: true,
          value: 'credit_cost',
          width: '20%',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
          width: '20%',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      workspaceById: 'workspaceById',
    }),
    areTemplatesLoaded() {
      return this.rows !== null
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id)
    },
    headingTag() {
      return this.isSearchable ? 'h1' : 'h2'
    },
    isSearchable() {
      return !this.noSearch
    },
    isLinkDisplayed() {
      return this.$route.name !== 'template' && !this.isLinkHidden
    },
  },
  watch: {
    templates: 'loadVisibleTemplates',
  },
  created() {
    this.loadVisibleTemplates()
  },
  methods: {
    loadVisibleTemplates() {
      if (!this.templates) {
        return
      }
      if (this.searchQuery) {
        this.rows = this.templates.filter((t) => t.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else {
        this.rows = this.templates
      }
    },
    showAllTemplates() {
      if (this.$listeners && this.$listeners.showAllTemplates) {
        this.$emit('showAllTemplates')
      } else {
        this.$router.push({
          name: 'template',
          params: {
            workspace_id: this.$route.params.workspace_id,
          },
        })
      }
    },
    async createContract(item) {
      this.loading = true

      if (this.$route.params.contract_id) {
        try {
          const { id } = await ContractService.addAttachment(
            {
              title: item.title,
              type: 'form',
              number: '',
              tvid: item.id,
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
            this.$route.params.contract_id,
          )

          this.loading = false

          return this.$router.push({
            name: 'createContent',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: id,
            },
          })
        } catch (e) {
          this.loading = false
          this.loaded = true

          if ('Uživatel nemá dostatek kreditů' === e) {
            this.$notification.error(this.$t('upload.error_credits'))

            return this.$router.push({ name: 'subscription' })
          }

          this.$notification.error(this.$t('upload.error_general'))
        }
      } else {
        try {
          this.loading = true

          const { id } = await ContractService.create(
            {
              title: item.title,
              tvid: item.id,
              type: 'form',
            },
            this.activeWorkspace,
          )

          return this.$router.push({
            name: 'contractsCollectionParties',
            params: {
              contractId: id,
              workspaceId: this.$route.params?.workspace_id,
              contacts: this.$route.query?.contacts,
            },
          })
        } catch (e) {
          this.loaded = true
          this.loading = false
          this.$notification.error(this.$t('upload.error_general'))
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// hotfix css from Documents - override grid-template-columns, unify widths...
.dashboard-templates.dashboard-documents__wrapper {
  text-align: left;
  .dashboard-documents__filters-desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include md {
      display: block;
      & > div {
        display: block;
        text-align: left;
        width: 100% !important;
      }
    }
  }
  &.dashboard-templates-favourites {
    margin: 15px auto;
    .dashboard-documents__heading {
      text-align: center;
      margin: 30px 0;
    }
  }
}
</style>
