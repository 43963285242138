import { authorizedApiRequest } from '@/services/ApiService'
import i18n from '@/app/plugins/i18n'

export const namespaced = true

export const state = {
  list: [],
}

export const mutations = {
  setCurrencies(state, currencies) {
    state.list = currencies
  },
}

export const actions = {
  fetchCurrencies: (context, language = null) => {
    return authorizedApiRequest({
      method: 'GET',
      endpoint: `/api/public/v1/enum/currencies/rate${null === language ? `/${i18n.locale}` : `/${language}`}`,
    })
      .then((resp) => {
        context.commit('setCurrencies', resp)
      })
      .catch(() => {
        context.commit('setCurrencies', [])
      })
  },
}

export const getters = {
  currentCurrency: (state) => (currencyCode) => {
    return state.list.find((item) => {
      return item.code === currencyCode
    })
  },
  list: (state) => {
    return state.list
  },
}
