export const authHeader = () => {
  return generateAuthHeader({
    authorization: 'Bearer',
  })
}

export const apiTokenBasedHeader = (authToken) => {
  return generateAuthHeader({
    authorization: 'ApiTokenAuth',
    authToken,
  })
}

export const apiTokenBasedFormHeader = (authToken) => {
  return {
    Authorization: `ApiTokenAuth ${authToken}`,
  }
}

export const generateAuthHeader = ({ authorization, authToken = null, isForm = false }) => {
  const storedAuthToken = localStorage.getItem('token')

  if (true === isForm) {
    return {
      Authorization: `${authorization || 'ApiTokenAuth'} ${authToken || storedAuthToken}`,
      'Content-Type': '',
    }
  }

  if (null === authToken && null === storedAuthToken) {
    return null
  }

  return {
    Accept: 'application/json',
    Authorization: `${authorization || 'Bearer'} ${null === authToken ? storedAuthToken : authToken}`,
    'Content-Type': 'application/json',
  }
}
