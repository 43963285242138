import { internalApiRequest } from '@/shared/services/apiService'

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchSharePointSettings = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/sharePoint`,
  })
}

/**
 *
 * @param {Number|String} workspaceId
 * @param {String} path
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchSharePointDirectories = ({ workspaceId, path }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/onedrive/folder?workspaceid=${workspaceId}&path=${path}`,
  })
}

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const deactivateSharePointAccess = (workspaceId) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v1/onedrive/auth?workspaceid=${workspaceId}`,
  })
}
