import { ref, onMounted, onUnmounted, computed } from 'vue'

export function useScreen() {
  const orientationType = ref(window.screen.orientation.type)
  const isLandscapeOrientation = computed(() => orientationType.value?.includes('landscape'))

  function updateOrientation() {
    orientationType.value = window.screen.orientation.type
  }

  onMounted(() => {
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', updateOrientation)
    } else {
      console.warn('Screen orientation API is not supported in this browser.')
    }
  })

  onUnmounted(() => {
    if (window.screen.orientation) {
      window.screen.orientation.removeEventListener('change', updateOrientation)
    }
  })

  return { orientationType, isLandscapeOrientation }
}
