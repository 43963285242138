<template lang="html">
  <svg
    width="21px"
    height="15px"
    viewBox="0 0 21 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="SYN001---DSKTP---Dashboard---no-workspace"
        transform="translate(-867.000000, -166.000000)"
        fill-rule="nonzero"
        stroke="#6DECAF"
        stroke-width="2"
      >
        <g id="upozorneni" transform="translate(370.000000, 111.000000)">
          <g id="btn-full" transform="translate(479.000000, 39.000000)">
            <polyline id="Path-2" points="19 22.6333556 25.3633042 28.9966598 37.359964 17"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoCheck',
}
</script>
