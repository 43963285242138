import { Environment } from '@digitalfactorycz/core/dist/lib/config/Environment'

export const GOOGLE_CLIENT_IDS = {
  prod: '837969300817-lu6ohd94c4oj22snuq7m5666lbi9g9fh.apps.googleusercontent.com',
  dev: '208954475859-1jh11dpod3cruh08uq4ng9o5386evrei.apps.googleusercontent.com',
}

export const GOOGLE_DEV_KEYS = {
  production: 'AIzaSyDWFBM9dQJgG4-q_MgHkmFRcL4O216wXrQ',
  development: 'AIzaSyAQbiX3pOi40jyxETeTGzTaEpIycJ4S9_U',
}

export const appEnv = new Environment({
  appUrl: process.env?.VUE_APP_FRONTEND_URL || window.location.origin,
  apiUrl: process.env?.VUE_APP_BACKEND_URL || undefined,
  prefix: process.env?.VUE_APP_TRANSLATIONS_PREFIX || undefined,
})

export const appUrl = appEnv.getAppUrl()

export const apiUrl = appEnv.getApiUrl()

export const translationsPrefix = appEnv.getAppTranslationsPrefix()

export function useGoogleEnv() {
  const getGoogleDriveClientId = (envMode = process.env.NODE_ENV) => {
    return GOOGLE_CLIENT_IDS[envMode]
  }

  const getGoogleDriveDevKey = (envMode = process.env.NODE_ENV) => {
    return GOOGLE_DEV_KEYS[envMode]
  }

  return {
    getGoogleDriveClientId,
    getGoogleDriveDevKey,
  }
}
