const routes = [
  {
    path: '/:workspace_id/templates',
    name: 'template',
    component: () => import('@/views/dashboard/Templates.vue'),
  },
  {
    path: '/:workspace_id/templates/export',
    name: 'templates-export',
    component: () => import('@/features/templates/views/TemplatesExport.vue'),
  },
]

routes.forEach((route) => {
  route.meta = {
    ...route.meta,
    layout: () => import('@/layouts/EmptyLayout'),
    authRequired: true,
  }
})

export default routes
