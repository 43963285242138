import * as types from './mutation_types'

export const setDisplaySizes = ({ commit }, sizes) => {
  commit(types.SET_DISPLAY_SIZES, sizes)
}

export const setSignatureColor = ({ commit }, color) => {
  commit(types.SET_SIGNATURE_COLOR, color)
}

export const setSignatureDate = ({ commit }, signatureDate) => {
  commit(types.SET_SIGNATURE_DATE, signatureDate)
}

export const setSignatureImage = ({ commit }, preloadedSignatureImage) => {
  commit(types.SET_SIGNATURE_IMAGE, preloadedSignatureImage)
}

export const setSignaturePlace = ({ commit }, signaturePlace) => {
  commit(types.SET_SIGNATURE_PLACE, signaturePlace)
}

export const setSignatureProgress = ({ commit }, signatureProgress) => {
  commit(types.SET_SIGNATURE_PROGRESS, signatureProgress)
}

export const resetSignature = ({ commit }) => {
  commit(types.RESET_SIGNATURE)
}

export const resetSignatureProgress = ({ commit }) => {
  commit(types.RESET_SIGNATURE_PROGRESS)
}
