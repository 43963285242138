<template>
  <div v-bind:class="customClass">
    <ul v-if="showTabsButtons" role="tablist" class="tabs-component-tabs" style="padding-left: 0">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
        class="tabs-component-tab"
        role="presentation"
        v-show="tab.isVisible"
      >
        <a
          v-html="tab.header"
          :aria-controls="tab.hash"
          :aria-selected="tab.isActive"
          @click="selectTab(tab.hash, $event)"
          :href="tab.hash"
          class="tabs-component-tab-a"
          role="tab"
        ></a>
      </li>
    </ul>

    <div :class="{ 'tabs-component-panels': '#team' != activeTabHash }">
      <slot />
    </div>
  </div>
</template>

<script>
import { setStorageKey, getStorageKey } from '@/common/reusable/expiringStorage'

export default {
  name: 'Tabs',
  props: {
    customClass: {
      default: '',
    },
    cacheLifetime: {
      default: 0,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        useUrlFragment: true,
        defaultTabHash: null,
      }),
    },
    setTab: {
      default: null,
    },
    setSelectedTab: {
      default: null,
    },
    showTabsButtons: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    tabs: [],
    activeTabHash: '',
    activeTabIndex: 0,
    lastActiveTabHash: '',
  }),
  computed: {
    storageKey() {
      return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`
    },
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    window.addEventListener('hashchange', () => this.selectTab(window.location.hash))
    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash)
      return
    }
    const previousSelectedTabHash = getStorageKey(this.storageKey)
    if (this.findTab(previousSelectedTabHash)) {
      this.selectTab(previousSelectedTabHash)
      return
    }
    if (this.options.defaultTabHash !== null && this.findTab('#' + this.options.defaultTabHash)) {
      this.selectTab('#' + this.options.defaultTabHash)
      return
    }
    if (this.tabs.length) {
      this.selectTab(this.tabs[0].hash)
    }
  },
  watch: {
    setTab: function () {
      this.selectTab(this.setSelectedTab)
    },
  },
  methods: {
    findTab(hash) {
      return this.tabs.find((tab) => tab.hash === hash)
    },
    selectTab(selectedTabHash, event) {
      // See if we should store the hash in the url fragment.
      if (event && !this.options.useUrlFragment) {
        event.preventDefault()
      }
      const selectedTab = this.findTab(selectedTabHash)
      if (!selectedTab) {
        return
      }
      if (selectedTab.isDisabled && event) {
        event.preventDefault()
        return
      }
      if (this.lastActiveTabHash === selectedTab.hash) {
        this.$emit('clicked', { tab: selectedTab })
        return
      }
      this.tabs.forEach((tab) => {
        tab.isActive = tab.hash === selectedTab.hash
      })
      this.$emit('changed', { tab: selectedTab })
      this.activeTabHash = selectedTab.hash
      this.activeTabIndex = this.getTabIndex(selectedTabHash)
      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash

      if (this.$route.name === 'workspaceSettings') {
        this.$store.dispatch('userSettings/setWorkspaceSelectedTab', selectedTab.id)
      } else if (this.$route.name === 'account') {
        this.$store.dispatch('userSettings/setAccountSelectedTab', selectedTab.id)
      }

      setStorageKey(this.storageKey, selectedTab.hash, this.cacheLifetime)
    },
    setTabVisible(hash, visible) {
      const tab = this.findTab(hash)
      if (!tab) {
        return
      }
      tab.isVisible = visible
      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible
        this.tabs.every((tab, index, array) => {
          if (tab.isVisible) {
            tab.isActive = true
            return false
          }
          return true
        })
      }
    },

    getTabIndex(hash) {
      const tab = this.findTab(hash)

      return this.tabs.indexOf(tab)
    },

    getTabHash(index) {
      const tab = this.tabs.find((tab) => this.tabs.indexOf(tab) === index)

      if (!tab) {
        return
      }

      return tab.hash
    },

    getActiveTab() {
      return this.findTab(this.activeTabHash)
    },

    getActiveTabIndex() {
      return this.getTabIndex(this.activeTabHash)
    },
  },
}
</script>

<style lang="scss">
.tabs-component {
  margin: 4em 0;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  @include md {
    margin-top: 0px;
    position: relative;
  }
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

.tabs-component-tabs {
  border: 0;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:not(:last-child) {
  border-bottom: dotted 1px #ddd;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

.tabs-component-tab {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 3px 3px 0 0;
  margin-right: 5px;
  transform: translateY(2px);
  transition: transform 0.3s ease;
}

.tabs-component-tab.is-active {
  border-bottom: solid 1px #fff;
  z-index: 2;
  transform: translateY(0);
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 7.5px 10px;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
  z-index: 5;
}

.tabs-component-panels {
  border-top-left-radius: 0;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 0 6px 6px 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 40px 20px;
  z-index: 5;

  @include md {
    padding-top: 30px;
  }
}

.tabs-component-tabs {
  display: table;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 0;
  @include md {
    z-index: 1;
    display: none;
  }
}

.tabs-component-tab {
  border-bottom: none !important;
  padding: 0px 15px;
  display: inline-block !important;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.03px;
  font-weight: normal;
  transform: none;

  a {
    padding: 10px 0px;
    font-weight: normal;
    &:hover {
      color: #5e239e;
      text-decoration: none;
    }
  }
}

.tabs-component-tab.is-active {
  color: #5e239e;
  a {
    border-bottom: solid 2px #5e239e;
  }
}

.tabs-component-panels {
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15) !important;
  border-radius: 6px !important;
  border: none !important;
  z-index: 5;
  @include md {
    background: transparent;
    box-shadow: none !important;
  }
}

.select-clicked .tabs-component-tabs {
  @include md {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 0px;
    width: 310px;
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #5e239e;

    border-radius: 6px;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0;
    a {
      color: #000000;
      padding: 15px 0;
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      letter-spacing: -0.03px;
    }
    li {
      border-radius: 0px;
      border-top: 1px solid #d4dff0;
      width: 100%;
      padding: 0px 20px;
      &:hover {
        background: #f2f6fc;
      }
    }
    .tabs-component-tab.is-active {
      display: none !important;

      a {
        color: #5e239e;
        border-bottom: none;
      }
    }
  }
}

.tabs-component.simple {
  width: 100%;
}

.simple .tabs-component-tabs {
  display: inline-block !important;
  border-bottom: 1px solid #d4dff0;
  border-radius: 0 !important;
}

.simple .tabs-component-panels {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  background: transparent !important;
  z-index: 5;
}

.overview-content .tabs-component-tabs {
  width: 500px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.overview-content .tabs-component-tab:first-child {
  padding-left: 0;
}

.overview-content .tabs-component-panels {
  padding: 40px 0;
}

.tabs-component-tab {
  @include md {
    padding-left: 7px;
    padding-right: 7px;
  }
}
</style>
`
