<template>
  <div
    v-if="profile.id"
    class="main"
    :class="{
      coverMenu: !showMenu,
      'panel-load': panelLoad,
    }"
  >
    <div v-if="!panelLoad" id="triangle"></div>

    <SideMenu v-if="!(panelLoad || disableMenu) || isAccountSettingsRoute" :showMenu="showMenu" />

    <Header
      v-if="!panelLoad && showHeader"
      :closeAndSave="closeAndSave"
      :contractName="contractName_"
      :customTitle="customTitle"
      :disableMenu="disableMenu"
      :showBackNavigation="showBackNavigation"
      @close="() => $emit('close')"
      @onNavigationBack="() => $emit('onNavigationBack')"
    />

    <div v-if="loader" class="data data--loading" :class="{ menuOpen: isSideMenuVisible }">
      <Loader />
    </div>

    <div
      v-else
      class="data"
      :class="{
        menuOpen: isSideMenuVisible,
        'without-margin': withoutMargin,
        'without-side-paddings': contractSignature,
        'without-y-paddings': !showHeader,
        'without-after-graphic': withoutAfterGraphic,
      }"
    >
      <v-container
        class="data__content"
        :class="{ 'is-dashboard': isDashboard }"
        :style="$vuetify.breakpoint.mobile && $route.name !== 'createSign' ? 'margin-top: 1rem !important;' : ''"
        fluid
      >
        <div>
          <v-row v-if="titleSlotHasContent" align="start" :justify="isDashboard ? 'start' : 'center'" no-gutters>
            <v-col cols="12">
              <h1 v-if="!withoutMargin && !contractSignature">
                <slot name="title" />
              </h1>
            </v-col>
          </v-row>
        </div>

        <div>
          <v-row v-if="headerSlotHasContent" align="start" no-gutters>
            <v-col cols="12">
              <div v-if="!contractSignature" class="data__tab" v-bind:class="{ 'data__tab-noshaddow': !shadow }">
                <slot name="header" />
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="mx-auto">
          <slot></slot>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { changeAndSaveLocale } from '@/app/services/localeService'
import SideMenu from '@/components/partials/SideMenu.vue'
import Header from '@/components/partials/Header.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Data',
  components: {
    SideMenu,
    Header,
    Loader,
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    panelLoad: {
      default: false,
      type: Boolean,
    },
    closeAndSave: {
      default: false,
      type: Boolean,
    },
    withoutMargin: {
      default: false,
      type: Boolean,
    },
    withoutAfterGraphic: {
      default: false,
      type: Boolean,
    },
    showBackNavigation: {
      default: false,
      type: Boolean,
    },
    customTitle: {
      default: null,
      type: String,
    },
    contractName: {
      default: false,
      type: Boolean,
    },
    disableMenu: {
      default: false,
      type: Boolean,
    },
    contractSignature: {
      default: false,
      type: Boolean,
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
    isDashboard: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menuDisabled: false,
      shadow: false,
      hideMenu: false,
      contractName_: false,
    }
  },
  computed: {
    ...mapGetters(['isSideMenuVisible', 'profile']),
    isAccountSettingsRoute() {
      return this.$route.name === 'account'
    },
    titleSlotHasContent() {
      return !!this.$slots.title
    },
    headerSlotHasContent() {
      return !!this.$slots.header
    },
  },
  watch: {
    'profile.locale': function () {
      this.reloadLocale()
    },
  },
  created() {
    const body = document.body

    this.contractName_ = this.contractName

    if (!this.showMenu) {
      body.classList.add('hidden-menu')

      this.$store.commit('hideSideMenu')
      const vm = this
      setTimeout(function () {
        vm.$store.commit('hideSideMenu')
      }, 1)
    } else {
      body.classList.remove('hidden-menu')
    }

    this.reloadLocale()
  },
  methods: {
    reloadLocale() {
      if (!this.profile) {
        return
      }
      changeAndSaveLocale(this, this.profile.locale)
    },
  },
}
</script>

<style lang="scss">
.data.without-margin {
  margin: 0;
  padding: 0;
  border: 0;

  &:after {
    display: none;
  }
}

.bottom-symbol {
  background-image: url(/images/contract-shape-bottom-middle-desktop.png);
  background-size: 360px 45px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  position: relative;
  left: 50%;
  height: 45px;
  width: 360px;
  transform: translateX(-50%);
  bottom: 0;
  transition: 0.2s;
  margin-top: -45px;
  margin-left: 162.5px;
  @include md {
    background-size: auto;
    width: 100%;
    margin-left: 0;
  }
}

.data {
  position: relative;
  padding-top: 120px;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 100vh;
  border: 1px solid transparent;
  padding-bottom: 60px;
  display: inline-block;
  height: 100%;
  width: 100%;

  @include animation;

  @include md {
    padding-top: 60px;
    transition: padding-top 0s;
  }

  &.menuOpen {
    padding-left: 325px;
    padding-top: 130px;

    @include md {
      padding-left: 0;
      padding-top: 60px;
      transition: padding-top 0s;
    }
  }
}

.data__content {
  padding: 0 !important;
}

.main.coverMenu .data.menuOpen {
  padding-left: 15px;
  padding-top: 120px;

  @include lg {
    padding-top: 60px;
  }
}

.data__title {
  font-size: 33px;
  font-weight: normal;
  text-align: center;
  color: #000000;

  @include md {
    padding-top: 30px;
    background: #f1f6fd;
    margin-bottom: 0;
    padding-bottom: 15px;
    z-index: 1;
    position: relative;
    font-size: 25px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.file-uploads {
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;
  opacity: 0.56;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
}

.data__tab-hamburger {
  background: #5e239e;
  height: 2px;
  width: 20px;
  display: block;
  border-radius: 2px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;

  &:before {
    content: '';
    background: #5e239e;
    height: 2px;
    width: 20px;
    display: block;
    border-radius: 2px;
    position: absolute;
    right: 0;
    bottom: 6px;
    transition: 0.2s;
  }

  &:after {
    content: '';
    background: #5e239e;
    height: 2px;
    width: 20px;
    display: block;
    border-radius: 2px;
    position: absolute;
    right: 0;
    top: 6px;
    transition: 0.2s;
  }
}

.select-clicked .data__tab-hamburger {
  transition: 0.2s;
  height: 0;

  &:before {
    transition: 0.2s;
    bottom: -1px;
    transform: rotate(45deg);
  }

  &:after {
    transition: 0.2s;
    top: -1px;
    transform: rotate(-45deg);
  }
}

.data__tab {
  position: relative;

  @include md {
    background: #f2f5fe;
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.1);
    margin-left: -15px;
    margin-right: -15px;
    left: 0;

    &:after {
      content: '';
      background-image: linear-gradient(to right bottom, #f2f5fe 0%, #f2f5fe 50%, transparent 50%);
      position: absolute;
      z-index: -1;
      width: 100%;
      top: 20vh;
      left: 0;
      height: 30vh;
    }

    &:before {
      content: '';
      background: #f2f5fe;
      position: absolute;
      top: 0;
      height: 20vh;
      width: 100%;
      z-index: -1;
    }
  }
}

.data__tab-noshaddow {
  box-shadow: none;
}

.data__tab-select {
  display: none;

  @include md {
    display: block;
    position: relative;
    width: 310px;
    outline: none;
    background: #ffffff;
    border: 1px solid #b1bbcb;
    border-radius: 6px;
    height: 54px;
    padding: 0 20px;
    font-size: 16px;
    color: black;
    letter-spacing: -0.03px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    line-height: 54px;
    font-weight: 300;
    padding-right: 55px !important;
  }
}

.select-clicked.data__tab-select {
  border: solid 1px #5e239e;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.data__tab-simple-select {
  cursor: pointer;
  display: block;
  position: relative;
  width: 310px;
  outline: none;
  background: #ffffff;
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  height: 54px;
  padding: 0px 20px;
  font-size: 16px;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 54px;
  font-weight: 300;
  padding-right: 55px;

  .data__tab-hamburger {
    display: none;

    @include md {
      display: block;
    }
  }

  .data__tab-arrow {
    font-size: 10px;
    position: absolute;
    right: 20px;
    transition: 0.2s;
    line-height: 10px;
    top: 50%;
    line-height: 54px;
    display: block;

    .icon-bottom {
      display: block;
      top: 0px;
      transform: translateY(-50%);
      position: absolute;
      right: 0px;
      line-height: 54px;
    }

    @include md {
      display: none;
    }
  }
}

.data__tab-select-data {
  display: none;
}

.select-clicked {
  .data__tab-arrow .icon-bottom {
    display: block;
    top: 0;
    transform: translateY(-50%) rotate(180deg);
    position: absolute;
    right: 0;
    line-height: 54px;
  }

  .data__tab-select-data {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 310px;
    background: white;
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.04);
    padding: 20px 30px;
    height: 205px;
    overflow: scroll;
    margin-top: 10px;

    li {
      cursor: pointer;
      list-style-type: none;
      display: block;
      color: #000000;
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      letter-spacing: -0.03px;
      padding: 15px 0;
      border-top: 1px solid #f2f6fc;
    }

    li:first-child {
      border-top: none;
    }
  }
}

.data__tab-membership {
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  border-radius: 6px;
  border: none;
  background: white;
  text-align: center;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 35px;
  padding-top: 30px;

  @include md {
    box-shadow: none;
    background: transparent;
    padding: 0;
    border-radius: 0;
    width: 100%;
    max-width: 250px;
  }

  .button {
    background: transparent;
    margin-bottom: 0;
  }
}

.data__tab-usage {
  font-size: 15px;
  color: #000000;
  letter-spacing: -0.03px;
  font-weight: normal;
  width: 100%;
  padding-bottom: 10px;
  float: left;
}

.data__tab-credit {
  font-size: 15px;
  color: #000000;
  letter-spacing: -0.03px;
  font-weight: normal;
  width: 100%;
  padding-bottom: 10px;
  float: left;
}

.usage__item {
  float: right;
}

.usage__part {
  float: left;
}

.credit__item {
  float: right;
  background: #6decaf;
  padding: 0 6px;
  border-radius: 20px;
}

.credit__part {
  float: left;
}

.dg-backdrop {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.dg-backdrop,
.dg-container {
  z-index: 10000 !important; // it must be greater number than z-index from Modal.vue
}

.dg-main-content {
  border-radius: 6px !important;
  background-color: #fffad1 !important;
  padding: 30px 30px !important;
  font-size: 15px;
  color: #73560b;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  position: relative;
  -webkit-box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15) !important;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15) !important;
}

.dg-btn--ok {
  color: #fff !important;
  background-image: linear-gradient(-90deg, #934fdd 0%, #6226a3 100%);
  transition:
    background-image 0.5s linear,
    box-shadow 0.2s linear;
  position: relative;
  z-index: 1;
  outline: none !important;
  border: none !important;
  padding: 10px 30px !important;
  height: 40px;

  &:focus,
  &:active {
    background-color: #712cbd !important;
    background-image: none;
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #712cbd;
    transition: opacity 0.5s ease-out;
    z-index: 2;
    opacity: 0;
  }
}

.dg-btn--ok:hover:after {
  opacity: 1;
}

.dg-btn--ok span {
  position: relative;
  z-index: 3;
}

.dg-btn--cancel {
  border: 1px solid #5e239e !important;
  color: #5e239e !important;
  background-color: #fffad1 !important;
  transition: 0.5s;
  padding: 10px 30px !important;
  height: 40px;

  &:hover {
    color: #9268be !important;
    border: 1px solid #9268be !important;
    transition: 0.5s;
  }
}
.dg-content-body {
  padding-bottom: 10px !important;
  text-align: center;
  border-bottom: none !important;
}

.without-side-paddings {
  padding-left: 0;
  padding-right: 0;
}

.without-y-paddings {
  padding-top: 0;
  padding-bottom: 0;
  &:after {
    display: none;
  }
}

.without-after-graphic {
  &:after {
    display: none;
  }
}

.data__content.is-dashboard {
  padding: 0;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
</style>
