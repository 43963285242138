<template>
  <div class="ico-input-wrapper">
    <v-text-field
      type="text"
      class="workspace-input"
      v-model="model.organization_ico"
      :label="label"
      :placeholder="placeholder"
      :error="hasError"
      hide-details
      outlined
      autofocus
      style="width: 100%; max-width: 352px"
    ></v-text-field>

    <v-row align="center" justify="center" no-gutters>
      <v-col class="col-12 col-md-auto px-2">
        <v-btn
          :color="currentColor"
          :disabled="isSearchingSK"
          :loading="isSearchingCS"
          max-width="15rem"
          x-large
          @click="onSearchRequest('cs')"
        >
          <span class="d-block text-truncate" :title="$t('find.czech_ico')">
            {{ $t('find.czech_ico') }}
          </span>
        </v-btn>
      </v-col>

      <v-col class="col-12 col-md-auto px-2">
        <v-btn
          :color="currentColor"
          :disabled="isSearchingCS"
          :loading="isSearchingSK"
          max-width="15rem"
          x-large
          @click="onSearchRequest('sk')"
        >
          <span class="d-block text-truncate" :title="$t('find.slovak_ico')">
            {{ $t('find.slovak_ico') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ContractService } from '@/services/ContractService.js'

export default {
  name: 'InputICO',
  props: ['label', 'placeholder', 'unauthorizedToken', 'model', 'hasError', 'color'],
  data() {
    return {
      activeType: null,
      defaultResponse: {
        dic: '',
        street: '',
        city: '',
        zip: '',
        number: '',
      },
    }
  },
  computed: {
    currentColor() {
      return this.color || 'primary'
    },
    currentIco() {
      return this.model && this.model.organization_ico ? this.model.organization_ico : ''
    },
    isSearchingCS() {
      return this.activeType === 'cs'
    },
    isSearchingSK() {
      return this.activeType === 'sk'
    },
  },
  methods: {
    onSearchRequest(type) {
      if (this.currentIco?.length < 6) {
        return this.$notification.error(this.$t('contract.external_company.required_ico'))
      }

      this.activeType = type

      return ContractService.searchExternalDB(this.currentIco, type, this.unauthorizedToken)
        .then((response) => {
          if (response.code >= 400) {
            return (() => {
              this.$notification.error(this.$t('contract.external_company.not_found'))
              this.$emit(
                'processExternalCompany',
                Object.assign(this.defaultResponse, {
                  ico: this.model.organization_ico,
                }),
              )
            })()
          }
          this.$notification.success(this.$t('contract.external_company.filled_from_ico'))
          this.$emit('processExternalCompany', response)
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.external_company.error'))
        })
        .finally(() => {
          this.activeType = null
        })
    },
  },
}
</script>

<style lang="scss">
.ico-input-wrapper {
  text-align: center;

  .workspace-input {
    display: inline-block;
  }
  .country-radio {
    margin-left: 5px;
    display: inline-block;
    .radio-wrap {
      display: inline-block;
      padding: 17px;
      margin: 0;
      border: 1px solid #b1bbcb;
      border-radius: 6px;
      &.selected-country {
        border-color: #5e239e;
        color: #5e239e;
        border-left-width: 1px !important;
        border-right-width: 1px !important;
        background: rgb(249, 245, 252);
      }
      &:first-child {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .radio__data {
        display: none;
      }
    }
    @include md {
      display: block;
      margin-top: 15px;
    }
  }

  button {
    display: block;
    margin: 15px auto 0;
    max-width: 320px;
    width: 100%;
  }
}
</style>
