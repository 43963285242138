<template lang="html">
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="IcoPlus" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="panel/left-OS" transform="translate(-249.000000, -519.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="panel-white-3" transform="translate(0.000000, 16.000000)">
          <g id="btn/btn-56-white/plus" transform="translate(30.000000, 480.000000)">
            <path
              d="M227,29 L232,29 C232.552285,29 233,29.4477153 233,30 C233,30.5522847 232.552285,31 232,31 L227,31 L227,36 C227,36.5522847 226.552285,37 226,37 C225.447715,37 225,36.5522847 225,36 L225,31 L220,31 C219.447715,31 219,30.5522847 219,30 C219,29.4477153 219.447715,29 220,29 L225,29 L225,24 C225,23.4477153 225.447715,23 226,23 C226.552285,23 227,23.4477153 227,24 L227,29 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoPlusRight',
}
</script>
