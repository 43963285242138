<template>
  <v-row align="center" justify="center" no-gutters>
    <DocumentPagePreview
      v-for="page in pages"
      :key="page.pageNumber"
      :page-number="page.pageNumber"
      :page-id="page.id"
    />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentPagePreview from '@contract/components/DocumentPagePreview'

export default {
  name: 'DocumentPagePreviews',
  components: {
    DocumentPagePreview,
  },
  computed: {
    ...mapGetters({
      documentPages: 'pdfDocument/documentPages',
      numberOfPages: 'pdfDocument/numberOfPages',
    }),
    numberOfDocumentPages() {
      return this.numberOfPages || 5
    },
    pages() {
      return new Array(this.numberOfDocumentPages).fill({}).map((item, index) => {
        const pageNumber = 1 + index

        return {
          id: `document-page-${pageNumber}`,
          pageNumber,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.document-page-preview {
  z-index: 0;
}
</style>
