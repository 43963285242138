const routes = [
  {
    path: '/access/forgotten-password',
    alias: '/password-forgotten',
    name: 'passwordForgotten',
    component: () => import(/* webpackChunkName: "access__forgotten-password" */ './views/ForgottenPassword'),
  },
  {
    path: '/access/forgotten-password/done',
    name: 'passwordForgottenDone',
    component: () => import(/* webpackChunkName: "access__forgotten-password-done" */ './views/ForgottenPasswordDone'),
  },
  {
    path: '/access/forgotten-password/reset/:token',
    alias: '/password/reset/:token',
    name: 'passwordReset',
    component: () =>
      import(/* webpackChunkName: "access__reset-forgotten-password" */ './views/ResetForgottenPassword'),
  },
  {
    path: '/access/forgotten-password/reset/:token/done',
    name: 'passwordResetDone',
    component: () =>
      import(/* webpackChunkName: "access__reset-forgotten-password-done" */ './views/ResetForgottenPasswordDone'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "access__registration" */ './views/Registration'),
  },
  {
    path: '/register/email/:register_email',
    name: 'registerEmail',
    redirect: (to) => ({
      name: 'register',
      query: {
        email: to.params.register_email,
        code: to.query.code,
      },
    }),
  },
  {
    path: '/register/:locale',
    name: 'registerLocale',
    redirect: (to) => ({
      name: 'register',
      query: {
        locale: to.params.locale,
        code: to.query.code,
      },
    }),
  },
  {
    path: '/register/to/:email/confirm',
    name: 'registerToConfirm',
    component: () => import(/* webpackChunkName: "access__registration-done" */ './views/RegistrationDone'),
  },
  {
    path: '/registration/confirm/:token',
    name: 'registrationConfirm',
    component: () => import(/* webpackChunkName: "access__confirm-registration" */ './views/ConfirmRegistration'),
  },
]

routes.forEach((route) => {
  route.meta = {
    authRequired: false,
    fullLayout: true,
    layout: () => import(/* webpackChunkName: "access-layout" */ '@/layouts/AccessLayout'),
  }
})

export default routes
