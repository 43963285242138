<template>
  <div class="workspace-warning__block">
    <h2 class="headline text-center pb-5">{{ $t('dashboard.contract_list_heading') }}</h2>

    <p>{{ $t('dashboard.contract_list_description') }}</p>

    <div class="workspace-warning__content">
      <div class="workspace-warning__create">
        <div class="workspace-warning__tip workspace-warning__tip-big workspace-warning__tip--dashboard">
          <p>{{ $t('dashboard.by_clicking_on_complete_registration') }}</p>
        </div>

        <ul class="workspace-warning__create__items">
          <li class="workspace-warning__create__item">
            <div>
              <IcoDashboardTemplates />
            </div>

            <div
              class="workspace-warning__create__text"
              v-html="$t('dashboard.current_correct_balanced_designs')"
            ></div>
          </li>

          <li class="workspace-warning__create__item">
            <div>
              <IcoDashboardContract />
            </div>

            <div
              class="workspace-warning__create__text"
              v-html="$t('dashboard.valid_contract_created_in_few_minutes')"
            ></div>
          </li>

          <li class="workspace-warning__create__item">
            <div>
              <IcoDashboardSecurity />
            </div>

            <div class="workspace-warning__create__text" v-html="$t('dashboard.secure_electronic_signature')"></div>
          </li>
        </ul>

        <Button type="primary" @click="$router.push({ name: 'workspaceNew' })">
          {{ $t('dashboard.complete_registration') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import IcoDashboardTemplates from '@/components/svg/IcoDashboardTemplates'
import IcoDashboardContract from '@/components/svg/IcoDashboardContract'
import IcoDashboardSecurity from '@/components/svg/IcoDashboardSecurity'

export default {
  name: 'NoWorkspaceWarning',
  components: {
    IcoDashboardSecurity,
    IcoDashboardContract,
    IcoDashboardTemplates,
  },
}
</script>

<style lang="scss" scoped>
.workspace-warning__block {
  max-width: 845px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
}

.workspace-warning__content {
  padding-top: 25px;

  @media only screen and (max-width: 992px) {
    padding-top: 0;
  }
}

.workspace-warning__create {
  border: 1px solid #d4dff0;
  border-radius: 6px;
  background-color: #fff;
  padding: 100px 0 60px 0;
  position: relative;
  text-align: center;

  @media only screen and (max-width: 992px) {
    padding: 40px 0 40px 0;
  }
}

.workspace-warning__create__text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #6128a0;
}

.workspace-warning__create__items {
  padding-top: 30px;
  padding-bottom: 40px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.workspace-warning__create__item {
  display: inline-block;
  width: 240px;
  padding: 0 34px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 30px;

    br {
      display: none;
      opacity: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    font-size: 17px;
    color: #5e239e;
  }
}

.workspace-warning__tip {
  border-radius: 6px;
  background: #fffad1;
  padding: 10px 30px;
  font-size: 15px;
  color: #73560b;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  margin-top: 15px;
  position: relative;
  margin-bottom: 25px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fffad1;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    border-bottom: none;
    border-right: 0;
    left: 25px;

    @media only screen and (max-width: 992px) {
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 10px 15px;
  }
}

.workspace-warning__tip-big {
  position: absolute !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);

  &.tip:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fffad1;
    position: absolute;
    bottom: -4px;
    border-bottom: none;
    border-right: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: unset;
  }
}

.workspace-warning__tip--dashboard {
  padding: 20px 55px !important;
  text-align: center;

  p {
    margin-bottom: 0;
    text-align: center;
    color: #73560b;
  }

  @media only screen and (max-width: 992px) {
    transform: translate(0, 0);
    position: static !important;
    text-align: center !important;
    padding: 20px !important;
  }
}
</style>
