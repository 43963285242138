import Vue from 'vue'
import Vuex from 'vuex'
import { UserService } from '@/services/UserService.js'
import { ContractHelper } from '@/helpers/ContractHelper'
import { WindowModule } from './modules/window'
import { UserSettingsModule } from './modules/userSettings'

import account from '@account/store'
import apiDoc from '@apiDoc/store'
import signature from '@signature/store'
import signing from '@signing/store'
import workspace from '@workspace/store'
import dashboardFeature from '@dashboard/store'

import * as contractSignIdentities from '@contract/store/contractSignIdentities'
import * as pdfDocument from '@contract/store/pdfDocument'
import * as predefinedWorkflow from '@contract/store/predefinedWorkflow'
import * as verification from '@contract/store/verification'

import * as countries from '@subscription/store/countries'
import * as creditPackages from '@subscription/store/creditPackages'
import * as currencies from '@subscription/store/currencies'
import * as subscription from '@subscription/store/subscription'
import * as subscriptionPackages from '@subscription/store/subscriptionPackages'
import * as subscriptionInvoices from '@subscription/store/invoices'

import * as user from './modules/user'

import contractData from './modules/contractData'

import featureFlagsChecker from '@/common/reusable/featureFlagsChecker'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    apiDoc,
    contractData,
    contractSignIdentities,
    window: WindowModule,
    userSettings: UserSettingsModule,
    user,
    dashboardFeature,
    signature,
    signing,
    workspace,
    pdfDocument,
    predefinedWorkflow,
    verification,
    countries,
    creditPackages,
    currencies,
    subscription,
    subscriptionPackages,
    subscriptionInvoices,
  },
  state: {
    currentContractId: undefined,
    visibleMenu: 'side',
    profile: {},
    contract: {},
    userIdentity: {},
    create: {
      isContentFilled: false,
      signatures: [],
    },
    dashboard: {
      checkedDocuments: [],
      modal: null,
      documentLinks: [],
      availableGroups: [],
      templateLinks: [],
    },
  },
  mutations: {
    setIsCreateContentFilled: (state, contentStateFilled) => {
      state.create.isContentFilled = contentStateFilled
    },

    setCreateSignatures: (state, signatures) => {
      state.create.signatures = signatures
    },

    updateProfile: (state, profile) => {
      state.profile = profile
    },

    resetProfile: (state) => {
      state.profile = {}
    },

    toggleSideMenu: (state) => {
      state.visibleMenu = state.visibleMenu ? '' : 'side'
    },
    openSideMenu: (state) => {
      state.visibleMenu = 'side'
    },
    hideSideMenu: (state) => {
      state.visibleMenu = ''
    },
    toggleWorkspaceMenu: (state) => {
      state.visibleMenu = 'side.workspace' === state.visibleMenu ? 'side' : 'side.workspace'
    },

    setContract: (state, contract) => {
      state.contract = Object.assign({}, contract)
    },
    updateContractLanguage: (state, language) => {
      state.contract.locale = language
    },
    updateContractNote: (state, note) => {
      state.contract.note = note
    },
    updateContractNumber: (state, contractNumber) => {
      state.contract.number = contractNumber
    },
    dashboardCheckDocument(state, document) {
      const documentIndex = state.dashboard.checkedDocuments.findIndex((item) => item.id === document.id)
      if (-1 === documentIndex) {
        state.dashboard.checkedDocuments = [...state.dashboard.checkedDocuments, document]
      } else {
        state.dashboard.checkedDocuments = state.dashboard.checkedDocuments.filter((item) => item.id !== document.id)
      }
    },
    dashboardSelectAllDocuments(state, documents) {
      state.dashboard.checkedDocuments = documents
    },
    dashboardDeselectAllDocuments(state) {
      state.dashboard.checkedDocuments = []
    },
    dashboardOpenModal(state, data) {
      state.dashboard.modal = data // {type,contracts} for DocumentsDashboard, {contactAction,contactsSelected} for Contacts
    },
    dashboardCloseModal(state) {
      state.dashboard.modal = null
    },
    dashboardSetDocumentLinks(state, folders) {
      state.dashboard.documentLinks = folders
    },
    dashboardSetAvailableGroups(state, groups) {
      state.dashboard.availableGroups = groups
    },
    dashboardSetTemplateLinks(state, links) {
      state.dashboard.templateLinks = links
    },
    dashboardResetMenuLink(state) {
      state.dashboard.documentLinks = []
      state.dashboard.templateLinks = []
    },
  },
  actions: {
    setContract: ({ commit }, payload) => {
      commit('setContract', payload)
    },
    updateContractLanguage: (context, language) => {
      context.commit('updateContractLanguage', language)
    },
    updateContractNote: (context, note) => {
      context.commit('updateContractNote', note)
    },
    updateContractNumber: (context, number) => {
      context.commit('updateContractNumber', number)
    },
    setIsCreateContentFilled() {
      this.commit('setIsCreateContentFilled', true)
    },
    resetIsCreateContentFilled() {
      this.commit('setIsCreateContentFilled', false)
    },
    setCreateSignatures(context, signatures) {
      this.commit('setCreateSignatures', signatures)
    },
    getUserProfile(context, workspaceId) {
      return UserService.loadLoggedUser(workspaceId).then((resp) => {
        this.commit('updateProfile', resp)
        this.commit('account/SET_PROFILE', resp)

        context.dispatch('dashboardFeature/fetchNewsMsg', null, { root: true })
      })
    },
    resetDashboard() {
      this.commit('dashboardDeselectAllDocuments')
      this.commit('dashboardCloseModal')
      this.commit('dashboardResetMenuLink')
    },
    toggleWorkspaceMenu({ commit }) {
      commit('toggleWorkspaceMenu')
    },
  },
  getters: {
    create: (state) => {
      return state.create
    },
    profile: (state) => {
      return state.profile
    },
    isPartnersClient: (state) => {
      return state.profile?.feature_flags?.hasDisabledShopping || false
    },
    profileHasDisabledCreatingWorkspace: (state) => {
      return featureFlagsChecker.hasDisabledCreateWorkspace({
        workspaces: state.profile.workspaces,
      })
    },
    workspaceById: (state, getters) => (id) => {
      const workspace = getters.profileWorkspaces.find((workspace) => {
        return String(workspace.id) === String(id)
      })

      return workspace || getters.profileWorkspaces[0] || false
    },
    profileWorkspaces: (state, getters) => {
      return (getters.profile && getters.profile.workspaces) || []
    },
    signIdentity: (state) => {
      return ContractHelper.getLegacyCurrentSignIdentity(state.contract)
    },
    myIdentity: (state) => {
      return ContractHelper.getLegacyCurrentSignIdentity(state.contract)
    },
    userIdentity: (state) => {
      return state.userIdentity
    },

    isSideMenuVisible: (state) => {
      return state.visibleMenu.length > 0
    },
    isWorkspaceMenuVisible: (state) => {
      return 'side.workspace' === state.visibleMenu
    },

    contract: (state) => {
      return state.contract
    },
    contractComments: (state) => {
      return state.contract?.comments || []
    },
    contractCommentsCount: (state) => {
      return state.contract?.comments?.length || 0
    },
    dashboardCheckedDocuments(state) {
      return state.dashboard.checkedDocuments
    },
    dashboardModal(state) {
      return state.dashboard.modal
    },
    dashboardDocumentLinks(state) {
      return state.dashboard.documentLinks
    },
    dashboardAvailableGroups(state) {
      return state.dashboard.availableGroups
    },
    dashboardTemplateLinks(state) {
      return state.dashboard.templateLinks
    },
  },
})
