import { internalApiRequest } from '@/shared/services/apiService'

export const namespaced = true

export const mutationsTypes = {
  SET_INVOICES: 'SET_INVOICES',
}

export const state = {
  list: [],
}

export const mutations = {
  [mutationsTypes.SET_INVOICES](state, invoices) {
    state.list = invoices
  },
}

export const actions = {
  fetchInvoices: ({ commit }) => {
    commit(mutationsTypes.SET_INVOICES, [])

    return internalApiRequest({
      method: 'GET',
      path: 'api/v2/loggedUser/invoices',
    }).then((resp) => {
      commit(mutationsTypes.SET_INVOICES, resp)

      return resp
    })
  },
}

export const getters = {
  list: (state) => {
    return state.list
  },
}
