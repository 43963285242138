<template>
  <v-main>
    <v-menu
      v-model="activeWorkspaceMenu"
      :close-on-content-click="true"
      :disabled="isDisabledSettings"
      max-width="18rem"
      offset-y
      right
      style="z-index: 999"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row
          class="workplace-toggle"
          :class="{
            'workplace-toggle--desktop': !showOnMobile,
            show: isSideMenuVisible,
          }"
          align="center"
          justify="space-between"
          no-gutters
          v-bind="attrs"
          v-on="on"
        >
          <v-col class="d-block text-truncate">
            <span class="subtitle-1 font-weight-regular">
              {{ activeWorkspace.title }}
            </span>
          </v-col>

          <v-col v-if="showToggleButton" cols="auto">
            <v-icon color="primary">
              {{ `mdi-chevron-${isWorkspaceMenuActive ? 'up' : 'down'}` }}
            </v-icon>
          </v-col>
        </v-row>
      </template>

      <v-card width="18rem">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="workspace-title">
                {{ activeWorkspace.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list class="py-0">
          <v-subheader v-if="hasMoreWorkspaces" class="workspace-list__title">
            {{ $t('workspace.switch') }}
          </v-subheader>

          <div class="workspace-list__wrapper">
            <AppWorkspaceMenuItem v-for="workspace in profileWorkspaces" :key="workspace.id" :workspace="workspace" />
          </div>
        </v-list>

        <v-divider v-if="!hasMoreWorkspaces"></v-divider>

        <v-card v-if="!hasMoreWorkspaces" class="ma-3 workspace-card-border" elevation="0" color="#FFFBCB">
          <v-card-text class="font-weight-medium workspace-card-message">
            {{ $t('workspaces.only_one_message') }}
          </v-card-text>
        </v-card>

        <v-divider v-if="!disabledCreateNewWorkspace"></v-divider>

        <v-list v-if="!disabledCreateNewWorkspace" dense>
          <v-list-item class="workspace-menu__add-button" link @click="$router.push({ name: 'workspaceNew' })">
            <v-icon color="primary" left>mdi-plus</v-icon>
            {{ $t('workspace.createNew') }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <TwoFactorAuthenticationDialog
      v-model="show2FADialog"
      :profile="profile"
      @successful-verification="close2FADialog"
    />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import AppWorkspaceMenuItem from '@/layouts/components/AppWorkspaceMenuItem'
import TwoFactorAuthenticationDialog from '@/features/access/components/TwoFactorAuthenticationDialog.vue'
import { authorizedApiRequest } from '@/services/ApiService'

export default {
  name: 'AppWorkspaceMenu',
  components: {
    AppWorkspaceMenuItem,
    TwoFactorAuthenticationDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showOnMobile: {
      type: Boolean,
      default: false,
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeWorkspaceMenu: false,
      show2FADialog: false,
    }
  },
  computed: {
    ...mapGetters({
      disabledCreateNewWorkspace: 'profileHasDisabledCreatingWorkspace',
      profile: 'profile',
      isSideMenuVisible: 'isSideMenuVisible',
      workspaceById: 'workspaceById',
    }),
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id || localStorage.getItem('workspace_id'))
    },
    hasMoreWorkspaces() {
      return this.profileWorkspaces?.length > 1
    },
    isDisabledSettings() {
      return this.disabled
    },
    isWorkspaceMenuActive() {
      return this.activeWorkspaceMenu
    },
    profileWorkspaces() {
      return this.profile?.workspaces || []
    },
  },
  mounted() {
    if (this.activeWorkspace.is2_fa_required) {
      this.open2FADialog()
    }
  },
  methods: {
    open2FADialog() {
      this.show2FADialog = true

      return authorizedApiRequest({
        method: 'POST',
        endpoint: `api/v1/users/generate/sms`,
      })
        .then(() => {
          this.$notification.success(this.$t('account.send_pin.ok'))
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'))
        })
    },
    close2FADialog() {
      this.$store.dispatch('getUserProfile').then(() => {
        this.show2FADialog = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.workspace-title {
  color: #606771;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.05px;
  line-height: 27px;
  text-align: left;
}

.workspace-list__title {
  color: #7d8593;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.03px;
  line-height: 22px;
  text-align: left;
}

.workspace-menu__add-button {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.03px;
  line-height: 23px;
  text-align: left;
}

.workspace-list__wrapper {
  max-height: 50vh;
  overflow: scroll;
  overflow-x: hidden;
}

.workspace-list__item {
  min-height: unset !important;
  height: 2.8rem;
}

.workplace-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include md {
    display: none;
  }
}

.workplace-toggle--desktop.show {
  @include md {
    display: flex;
  }
}

.workspace-card-border {
  border: 3px solid #f6f1bb !important;
  border-radius: 5px;
}

.workspace-card-message {
  color: #a16b36 !important;
  font-size: 1rem;
}
</style>
