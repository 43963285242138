import axios from 'axios'
import { environment } from '@/config/environment'
import { authHeader } from '@/config/authHeader'

/**
 * @param {Object} requestSettings
 * @param {String} requestSettings.method
 * @param {String} requestSettings.path
 * @param {Object} requestSettings.data
 * @param {Object} requestSettings.headers
 * @param {String} requestSettings.responseType
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const internalApiRequest = ({ method, path, data = null, headers = null, responseType = null }) => {
  const requestConfig = {
    method: method,
    headers: {
      ...authHeader(),
      ...headers,
    },
    url: `${environment.getApiUrl()}/${path}`,
  }

  if (data) {
    requestConfig.data = data
  }

  if (responseType) {
    requestConfig.responseType = responseType
  }

  return axios.request(requestConfig).then((resp) => resp.data)
}
