import { authorizedApiRequest } from '@/services/ApiService'
import i18n from '@/app/plugins/i18n'

export const namespaced = true

export const state = {
  list: [],
}

export const mutations = {
  setCountriesList: (state, payload) => {
    state.list = payload
  },
}

export const actions = {
  fetchCountriesList: (context, language = null) => {
    return authorizedApiRequest({
      method: 'GET',
      endpoint: `/api/public/v1/enum/countries${null === language ? `/${i18n.locale}` : `/${language}`}`,
    })
      .then((resp) => {
        context.commit('setCountriesList', resp?.countries || [])

        return resp
      })
      .catch(() => {
        context.commit('setCountriesList', [])

        return []
      })
  },
}

export const getters = {
  list: (state) => {
    return state.list
  },
  currentCountryByAlpha3: (state) => (code) => {
    return state.list.find((country) => {
      return country.alpha3 === code
    })
  },
}
