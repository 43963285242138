export const authType = (state) => {
  return state.authType
}

export const userPassword = (state) => {
  return state.userPassword
}

export const remainingTime = (state) => {
  return state.remainingTime
}
