import axios from 'axios'
import { environment } from '@/config/environment'
import * as types from './mutation_types'
import { apiAuthorizedRequest } from '@/services/ApiService'
import { authHeader } from '@/config/authHeader'
import { internalApiRequest } from '@/shared/services/apiService'

export const fetchSettings = ({ commit }, workspaceId) => {
  commit(types.SET_WORKSPACE_SETTINGS, null)

  return axios
    .get(`${environment.getApiUrl()}/api/v1/workspaces/${workspaceId}`, {
      headers: authHeader(),
    })
    .then((resp) => {
      commit(types.SET_WORKSPACE_SETTINGS, resp.data)
    })
    .catch((err) => {
      commit(types.SET_WORKSPACE_SETTINGS, null)

      throw err
    })
}

export const fetchWorkspaceMembersPositions = ({ commit }, workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/userWorkspace/positions`,
  })
    .then((resp) => {
      commit(types.SET_MEMBERS_JOB_POSITIONS, resp)

      return resp
    })
    .catch((err) => {
      commit(types.SET_MEMBERS_JOB_POSITIONS, [])

      throw err
    })
}

export const setActiveWorkspace = ({ commit }, workspace) => {
  commit(types.SET_ACTIVE_WORKSPACE, workspace)
}

export const fetchWorkspaceSignatureFooterSettings = ({ commit }, workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/contract/settings/sign/footer`,
  }).then((resp) => {
    commit(types.SET_WORKSPACE_SIGNATURE_FOOTER_SETTINGS, resp)
  })
}

export const saveWorkspaceSignatureFooterSettings = ({ commit }, { workspaceId, data }) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v2/workspace/${workspaceId}/contract/settings/sign/footer`,
    data,
  }).then((resp) => {
    commit(types.SET_WORKSPACE_SIGNATURE_FOOTER_SETTINGS, resp)
  })
}

export const fetchBankAccount = ({ commit }, workspaceId) => {
  return apiAuthorizedRequest('GET', `/api/v1/workspaces/${workspaceId}/bankAccounts`).then((workspaceBankAccounts) => {
    commit(types.SET_WORKSPACE_BANK_ACCOUNTS, workspaceBankAccounts)

    const activeBankAccount = workspaceBankAccounts?.bank_accounts?.filter(
      (bankAccount) => 'undefined' === typeof bankAccount.deleted_at || null === bankAccount.deleted_at,
    )

    if (0 === activeBankAccount.length) {
      commit(types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT, null)

      return null
    }

    return apiAuthorizedRequest(
      'GET',
      `/api/v1/workspaces/${workspaceId}/bankAccounts/${activeBankAccount[activeBankAccount.length - 1]?.id}`,
    ).then((account) => {
      commit(types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT, account)

      return account
    })
  })
}

export const createBankAccount = ({ commit }, payload) => {
  return apiAuthorizedRequest('POST', `/api/v1/workspaces/${payload.workspaceId}/bankAccount`, payload.account).then(
    (resp) => {
      commit(types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT, resp)
    },
    (err) => {
      throw err
    },
  )
}

export const updateBankAccount = ({ commit }, payload) => {
  return apiAuthorizedRequest(
    'PATCH',
    `/api/v1/workspaces/${payload.workspaceId}/bankAccounts/${payload.bankAccountId}`,
    payload.data,
  ).then((resp) => {
    commit(types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT, resp)
  })
}

export const deleteBankAccount = ({ commit, dispatch }, payload) => {
  return apiAuthorizedRequest(
    'DELETE',
    `/api/v1/workspaces/${payload.workspaceId}/bankAccounts/${payload.bankAccountId}`,
  ).then(
    () => {
      commit(types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT, null)

      return dispatch('fetchBankAccount', payload.workspaceId)
    },
    (err) => {
      throw err
    },
  )
}

export const fetchBankIdSettings = (context, workspaceId) => {
  return apiAuthorizedRequest('GET', `api/v2/workspace/${workspaceId}/settings/bankId`).then((resp) => {
    return resp
  })
}

export const setBankIdSettings = (context, payload) => {
  return apiAuthorizedRequest(
    'PATCH',
    `api/v2/workspace/${payload.workspaceId}/settings/bankId`,
    payload.workspaceBankIdSettings,
  ).then((resp) => {
    return resp
  })
}

export const deleteBankIdSettings = (context, workspaceId) => {
  return apiAuthorizedRequest('DELETE', `api/v2/workspace/${workspaceId}/settings/bankId`).then((resp) => {
    return resp
  })
}
