import * as types from './mutation_types'

export const setSignatures = ({ commit }, signatures) => {
  commit(types.SET_SIGNATURES, signatures)
}

export const approveSign = ({ commit }, identifier) => {
  commit(types.SET_APPROVE, identifier)
}

export const setNumberOfPages = ({ commit }, numberOfPages) => {
  commit(types.SET_NUMBER_OF_PAGES, numberOfPages)
}
