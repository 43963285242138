<template lang="html">
  <div>
    <div
      class="input"
      :class="{
        invalid: !validation,
        line: inputClass === 'line',
        disabled: disabled,
        'reset-margin-top': marginTopReset,
      }"
    >
      <label>
        <span class="input-label-wrap">
          <span v-if="label" class="input__label" :class="{ 'input__label--value': isFilled }">{{ label }}</span>
        </span>

        <input
          @animationstart="checkAnimation"
          @blur="inputBlur()"
          @focus="inputFocus()"
          @keydown.prevent.down.stop="$emit('keyDownDown')"
          @keydown.prevent.up.stop="$emit('keyDownUp')"
          @keyup.enter.prevent.stop="$emit('keyUpEnter', $event)"
          @keydown.enter="keyEnterDown"
          @input="updateSelf($event.target.value)"
          :placeholder="placeholder"
          :disabled="disabled"
          :type="type"
          :value="computedName"
          class="input__data"
          :class="{ psc: psc, 'input__data--empty': !isFilled }"
          ref="formInput"
          :title="title"
          :autocomplete="autoComplete"
          :list="null === dataListId ? `` : dataListId"
        />

        <span class="input__icons">
          <slot name="inputIcons"></slot>
        </span>

        <span class="input__side-icons">
          <slot name="sideIcons"></slot>
        </span>

        <span v-if="search" class="icon-search" @click.prevent.stop="$emit('onSearch', $event)"></span>
        <span v-if="fileIco" class="icon-file1"></span>

        <p v-if="!validation" class="input__error">{{ error }}</p>
      </label>

      <div class="tip" v-if="tip">{{ $t('general.tip') }}: {{ tip }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  model: {
    prop: 'name',
    event: 'input',
  },
  data() {
    return {
      hasFocus: false,
      autofilled: false,
      filled: false,
    }
  },
  props: {
    value: String,
    label: String,
    title: String,
    type: String,
    inputClass: String,
    validation: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    disabled: Boolean,
    error: String,
    search: Boolean,
    psc: Boolean,
    tip: String,
    fileIco: Boolean,
    name: {},
    autoComplete: {
      required: false,
      default: undefined,
    },
    marginTopReset: {
      type: Boolean,
      default: false,
    },
    dataListId: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedName: {
      get() {
        return this.name
      },
      set(value) {
        this.filled = true
        return this.updateSelf(value)
      },
    },
    isFilled() {
      return this.hasFocus || this.autofilled || this.filled || this.name !== ''
    },
  },
  mounted() {
    const input = this.$refs.formInput

    if (!this.checkAutofill(this.$refs.formInput) && this.$route.name === 'login') {
      let interval = 0
      const intervalId = setInterval(() => {
        if (this.checkAutofill(input) || interval++ >= 20) {
          clearInterval(intervalId)
        }
      }, 100)
    }
  },
  methods: {
    checkAutofill(input) {
      const autofillContent = `"${String.fromCharCode(0xfeff)}"`

      if (!input.value) {
        const style = window.getComputedStyle(input)
        if (style.content !== autofillContent) {
          return false
        }
      }

      this.autofilled = true
      return true
    },
    updateSelf(name) {
      if (name) {
        this.filled = true
      }
      this.$emit('input', name)
    },
    focusInput() {
      this.$refs.formInput.focus()
    },
    keyEnterDown(e) {
      if (this.search) {
        e.preventDefault()
      }
      this.$emit('keydown', e)
    },
    inputBlur() {
      this.hasFocus = false
      this.$emit('onBlur')
    },
    inputFocus() {
      this.hasFocus = true
      this.$emit('onFocus')
    },
    checkAnimation(e) {
      if (e.animationName === 'onAutoFillStart') {
        this.autofilled = true
      } else if (e.animationName === 'onAutoFillCancel') {
        this.autofilled = false
      }
    },
  },
}
</script>

<style lang="scss">
.workspace-input-edit .input__data {
  padding-right: 70px;
}

.disabled .input__data {
  color: #8e97a4;
  background: #f2f6fc;
  &:active,
  &:focus {
    border: solid 1px #b1bbcb;
  }
}

.disabled .input__label {
  color: #8e97a4;
}

.profile-input.input {
  width: auto;
}

.input__data::placeholder {
  font-weight: 300;
  color: #8e97a4;
  font-style: italic;
}

textarea::placeholder {
  font-weight: 300;
  color: #8e97a4;
  font-style: italic;
}

.input__error {
  font-size: 13px;
  color: #cd0000;
  position: absolute;
  bottom: -13px;
  font-weight: 300;
}

.line .input_label {
  font-size: 13px;
  color: #9b9b9b;
  letter-spacing: -0.03px;
  font-weight: normal;
  margin-bottom: 0px;
}

.password-wrap {
  position: relative;
}
.password-wrap .input__data {
  padding-right: 35px !important;
}

.input__label {
  display: block;
}

.textarea-small {
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: -0.03px;
  line-height: 22px;
}

.input__data {
  width: 100%;
  outline: none;
  background: #ffffff;
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  height: 56px;
  max-width: 230px;
  padding: 0px 20px;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  &:focus,
  &:active {
    border: solid 1px #5e239e;
  }
}

.input.invalid .input__data {
  border-color: red;
}

.input {
  max-width: 100%;
  text-align: left;
  margin-top: 20px;
  width: 273px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.reset-margin-top {
  margin-top: 0 !important;
}

.input__label {
  font-weight: normal;
  font-size: 15px;
  color: black;
  letter-spacing: -0.03px;
  margin-bottom: 5px;
}

.white-input .input__label {
  color: white;
}

.input-disabled {
  cursor: default;
  pointer-events: none;
}

.textarea {
  width: 240px;
  height: 110px;
  border-radius: 6px;
  padding: 10px;
  resize: none;
  outline: none;
  margin-bottom: 10px;
}

.input-disabled .input__label {
  color: #8e97a4;
}
.input-disabled .input__data {
  color: #8e97a4;
}

.short-input .input__label {
  width: 70px;
}

.input-search .input {
  margin-top: 0px;
  width: 100%;
  padding-right: 0;
}
.input-search label {
  position: relative;
}
.input-search .icon-search {
  position: absolute;
  right: 20px;
  bottom: 0px;
  font-size: 20px;
  &:before {
    color: #b1bbcb;
  }
}
.input-search .input__data {
  padding-right: 60px;

  &.not-empty {
    padding-left: 35px;
  }
}

.input__icons {
  z-index: 10;
  position: absolute;
  bottom: 0;
  /* height: 100%; */
  width: 100%;
  display: flex;
  pointer-events: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 20px;
  line-height: 20px;
}

.input__side-icons {
  z-index: 10;
  position: absolute;
  right: 10px;
  top: 35%;
}

.input-search .input__data:focus ~ .icon-search:before {
  color: #5e239e;
}

.psc.input__data {
  max-width: 115px;
}

.tip {
  border-radius: 6px;
  background: #fffad1;
  padding: 10px 30px;
  font-size: 15px;
  color: #73560b;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  margin-top: 15px;
  position: relative;
  margin-bottom: 25px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    //border: 1px solid #F1EAAF;
    background: #fffad1;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    border-bottom: none;
    border-right: 0px;
    left: 25px;

    @include md {
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  @include md {
    padding: 10px 15px;
  }
}

.input-file-ico .input__data {
  padding-left: 50px;
  &:before {
    content: '';
  }
}

.input-file-ico .input {
  margin-top: 0px;
  width: 100%;
  padding-right: 0px;
}
.input-file-ico label {
  position: relative;
}
.input-file-ico .icon-file1 {
  position: absolute;
  left: 20px;
  bottom: 0px;
  font-size: 20px;
  &:before {
    color: #b1bbcb;
  }
}

.input-file-ico .input__data:focus ~ .icon-file1:before {
  color: #5e239e;
}

.input input:-internal-autofill-selected {
  background-color: transparent !important;
  color: #5e239e !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  animation-name: onAutoFillStart;

  -webkit-text-fill-color: #5e239e !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;

  &:first-line {
    font-size: 17px;
  }
}

body .login-form .input.line {
  border: 1px solid #ccd2da;
  border-radius: 6px;
  padding: 0 15px;
  width: 100%;
  max-width: unset;
  margin: 0 0 15px 0;

  .input-label-wrap {
    height: 20px;
  }

  .input__label {
    font-size: 17px;
    color: #8c939f;
    font-style: normal;
    transition: 0.4s;
    font-weight: 400;
    position: absolute;
    top: 20px;
  }

  .input__label--value {
    font-size: 13px;
    color: #9b9b9b;
    transition: 0.4s;
    top: 2px;
  }

  .input__data {
    border-radius: 6px;
    border: none;
    color: #000;
    font-weight: 500;
    height: 60px;
    font-size: 17px;
    letter-spacing: -0.04px;
    width: 100%;
    margin: 0;
    padding: 0;
    &:active,
    &:focus {
      border: none;
      border-bottom: none;
    }
  }
  input:-internal-autofill-selected,
  input:-webkit-autofill {
    color: #000;
    -webkit-text-fill-color: #000;
  }
}
body #app .input.invalid {
  border-color: red;
}
.input.invalid.line .input__error {
  color: red;
  font-weight: 100;
  font-size: 11px;
  position: absolute;
  bottom: -6px;
}

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  content: '\feff';
}
input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
