<template>
  <v-dialog :value="showDialog" max-width="500px">
    <v-card class="pa-5">
      <v-card-title class="py-4">
        <h2 class="title font-weight-medium mx-auto mb-0 text-center">
          {{ message }}
        </h2>
      </v-card-title>

      <v-card-text v-if="hasInfoNote" class="error--text">{{ infoNote }}</v-card-text>

      <v-card-actions>
        <v-btn color="primary" min-width="30%" outlined @click="onCancelAction">
          {{ $t('general.no') }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="error" ref="confirm" :loading="isSubmitted" min-width="30%" @click="onConfirmationAction">
          {{ $t('general.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SConfirmDialog',
  props: {
    value: Boolean,
    message: {
      type: String,
      default: null,
    },
    infoNote: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasInfoNote() {
      return null !== this.infoNote
    },
    isSubmitted() {
      return this.submitted
    },
  },
  watch: {
    showDialog: {
      handler: function (dialogState) {
        if (dialogState) {
          this.submitted = false
        }
      },
    },
  },
  methods: {
    onConfirmationAction() {
      this.submitted = true
      this.$emit('confirmation-callback')
      this.showDialog = false
    },
    onCancelAction() {
      this.showDialog = false
    },
  },
})
</script>

<style lang="scss" scoped></style>
