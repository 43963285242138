import { internalApiRequest } from '@/shared/services/apiService'

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchWorkspaceSettings = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/workspaces/${workspaceId}`,
  })
}

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchSignatureFooterSettings = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/contract/settings/sign/footer`,
  })
}

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchMembersJobsPositions = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/userWorkspace/positions`,
  })
}
