<template lang="html">
  <svg
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-471.000000, -264.000000)" fill-rule="nonzero" stroke-width="2">
        <g id="ico/ico-credity" transform="translate(452.000000, 248.000000)">
          <g transform="translate(20.000000, 16.000000)" stroke="#FDB9B9">
            <ellipse cx="11.5" cy="5.36666667" rx="10.5" ry="4.36666667"></ellipse>
            <path
              d="M0.766666667,6.19386038 L0.766666667,12.2997109 C2.62984145,14.7134648 6.18054509,15.9203418 11.4187776,15.9203418 C16.6570101,15.9203418 20.261862,14.6039447 22.2333333,11.9711505 L22.2333333,6.19386038"
            ></path>
            <path
              d="M0.766666667,12.3271937 L0.766666667,18.4330443 C2.62984145,20.8467981 6.18054509,22.0536751 11.4187776,22.0536751 C16.6570101,22.0536751 20.261862,20.737278 22.2333333,18.1044839 L22.2333333,12.3271937"
            ></path>
            <path
              d="M0.766666667,18.460527 L0.766666667,24.5663776 C2.62984145,26.9801315 6.18054509,28.1870084 11.4187776,28.1870084 C16.6570101,28.1870084 20.261862,26.8706114 22.2333333,24.2378172 L22.2333333,18.460527"
            ></path>
            <path
              d="M0.766666667,24.5938604 L0.766666667,30.6997109 C2.62984145,33.1134648 6.18054509,34.3203418 11.4187776,34.3203418 C16.6570101,34.3203418 20.261862,33.0039447 22.2333333,30.3711505 L22.2333333,24.5938604"
            ></path>
          </g>
          <circle stroke="#FDBBBB" fill="#FFE2E2" cx="48" cy="45" r="12"></circle>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoCredits',
}
</script>
