import i18n from '@/app/plugins/i18n'

function countFinishedTasks(tasks) {
  return tasks.filter((t) => t.isCompleted).length
}

function getProfileTasks(profile) {
  const workspace = getOwnedWorkspace(profile)

  const completenessLocalState = localStorage.getItem('completenessLocalState')
  let skippedTutorial
  let adaptWorkspaceClicked
  try {
    const parsedCompletenessLocalState = JSON.parse(completenessLocalState)
    const skippedTutorialByUsers = parsedCompletenessLocalState.skippedTutorial
    const adaptedWorkspaceForUsers = parsedCompletenessLocalState.adaptWorkspaceClicked

    if (
      adaptedWorkspaceForUsers &&
      typeof adaptedWorkspaceForUsers === 'object' &&
      adaptedWorkspaceForUsers.constructor === Array &&
      adaptedWorkspaceForUsers.includes(String(profile.id))
    ) {
      adaptWorkspaceClicked = true
    }

    if (
      skippedTutorialByUsers &&
      typeof skippedTutorialByUsers === 'object' &&
      skippedTutorialByUsers.constructor === Array &&
      skippedTutorialByUsers.includes(String(profile.id))
    ) {
      skippedTutorial = true
    }
  } catch (e) {}

  if (!workspace || skippedTutorial) {
    return []
  }

  const tasks = []
  tasks.push({
    title: i18n.t('tutorial.progress.signature'),
    isCompleted: hasSignatureInProfile(profile),
    routeLink: { name: 'account', hash: '#signature', query: { fromTutorial: 'true' } },
  })
  tasks.push({
    title: i18n.t('tutorial.progress.settings'),
    isCompleted: hasWorkspaceHeader(profile) || adaptWorkspaceClicked,
    routeLink: { name: 'workspaceSettings', params: { workspace_id: workspace.id }, query: { fromTutorial: true } },
  })
  if (isWorkspaceWithTeamMembers(profile)) {
    tasks.push({
      title: i18n.t('tutorial.progress.team'),
      isCompleted: hasTeamMembers(profile),
      routeLink: {
        name: 'workspaceSettings',
        params: { workspace_id: workspace.id },
        hash: '#team',
        query: { fromTutorial: true },
      },
    })
  }
  tasks.push({
    title: i18n.t('tutorial.progress.billing'),
    isCompleted: hasInvoiceInfo(profile),
    routeLink: { name: 'subscription', query: { fromTutorial: true, action: 'addInvoiceData' } },
  })
  return tasks
}

function hasSignatureInProfile(profile) {
  return profile.has_signature
}

function hasWorkspaceHeader(profile) {
  const workspace = getOwnedWorkspace(profile)
  return workspace && workspace.organization_city && workspace.organization_street && workspace.organization_zip
}

function isWorkspaceWithTeamMembers(profile) {
  const workspace = getOwnedWorkspace(profile)
  return workspace && workspace.type == 'legal'
}

function hasTeamMembers(profile) {
  const workspace = getOwnedWorkspace(profile)
  return workspace && workspace.has_team_members
}

function getOwnedWorkspace(profile) {
  const workspace = profile.workspaces && profile.workspaces.find((workspace) => workspace.is_owner)
  return workspace && workspace.id ? workspace : false
}

function hasInvoiceInfo(profile) {
  if (typeof profile.invoice_info === 'undefined') {
    return false
  }

  return (
    profile.invoice_info.invoice_email &&
    profile.invoice_info.invoice_firstname &&
    profile.invoice_info.invoice_lastname
  )
}

export default {
  countFinishedTasks,
  getOwnedWorkspace,
  getProfileTasks,
}
