import Vue from 'vue'
import { ContractService } from '@/services/ContractService'
import i18n from '@/app/plugins/i18n'
import FileSaver from 'file-saver'

/**
 * Method return html of contract type doc
 *
 * @param contract
 * @returns {Promise<T>}
 */
export const getContractDoc = (contract) => {
  return ContractService.getDoc(contract, 'html').then(
    (response) => {
      return response.preview_html
    },
    () => {
      Vue.prototype.$notification.error(i18n.$t('contract.not_loaded'))
    },
  )
}

/**
 * Method return array of images from contract type doc html or empty array
 *
 * @param documentHtml
 * @returns {*[]}
 */
export const getDocumentPreviewImages = (documentHtml) => {
  if (!documentHtml) {
    return []
  }

  let match
  const images = []
  const rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g

  // eslint-disable-next-line no-cond-assign
  while ((match = rex.exec(documentHtml))) {
    images.push(match[1])
  }

  return images
}

/**
 * Method return an encoded string in base-64
 *
 * @param buffer
 * @returns {String}
 */

export function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = [].slice.call(new Uint8Array(buffer))
  // eslint-disable-next-line no-return-assign
  bytes.forEach((b) => (binary += String.fromCharCode(b)))

  return window.btoa(binary)
}

/**
 * Method download contract file based on file extension
 *
 * @param response
 * @param contract
 * @param prefix
 * @returns {File}
 */

export function getDownloadedFile(response, contract, prefix = '') {
  let contentType = ''
  for (let header of response.headers.entries()) {
    if (header.includes('content-type')) {
      contentType = header[1]
      break
    }
  }

  response.arrayBuffer().then((buffer) => {
    let blob = null
    let fileExtension = ''

    if (contentType.includes('zip')) {
      blob = new Blob([buffer], { type: `${contentType}` })
      fileExtension = 'zip'
    } else if (contentType.includes('pdf')) {
      const base64Flag = 'data:application/pdf;base64,'
      const imageStr = arrayBufferToBase64(buffer)

      blob = base64Flag + imageStr
      fileExtension = 'pdf'
    }

    FileSaver.saveAs(blob, `${prefix}${contract?.title || contract?.contract_title}.${fileExtension}`)
  })
}
