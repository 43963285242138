import * as types from './mutation_types'

const profileMutations = {
  [types.SET_PROFILE](state, payload) {
    state.profile = Object.assign({}, payload)
  },
}

const signatureMutations = {
  [types.SET_SIGNATURE](state, payload) {
    state.signature = payload
  },
}

export default {
  [types.SET_LOADED](state, payload) {
    state.isLoaded = payload
  },
  [types.SET_ERROR](state) {
    state.error = false
  },
  ...profileMutations,
  ...signatureMutations,
}
