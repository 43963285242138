<template>
  <Data :showMenu="shouldShowMenu" :loader="isLoading">
    <template v-slot:title>
      <div v-if="hasWorkspaces" class="title dashboard__title">
        <h1>{{ $t('overview') }}</h1>
      </div>
    </template>

    <template v-if="hasUserWorkspaces">
      <template v-if="isAccDisabled">
        <DisableAccNotification v-if="canShowDisabledAccNotification" :profile="profile" :workspace="activeWorkspace" />
      </template>

      <template v-else>
        <div class="dashboard-content">
          <DashboardNotifications />

          <ArchiveNotification
            v-if="canShowArchiveNotification"
            :workspace="workspace"
            :documents-in-trash-count="countOfDocumentsInTrash"
          />

          <DisableAccNotification
            v-if="canShowDisabledAccNotification"
            :profile="profile"
            :workspace="activeWorkspace"
          />

          <GoogleDriveUpload
            v-if="workspace.google_upload_access && $route.params.workspace_id && loaded"
            :total="workspace.google_not_sent_contracts || 0"
            :inProgressAfterLoad="workspace.google_sending_in_progress"
            :workspaceId="$route.params.workspace_id || null"
          />

          <template v-if="profile && profile.invitations">
            <Invitation v-for="(invitation, index) in profile.invitations" :key="index" :invitation="invitation" />
          </template>

          <CreditNotification v-if="!credits" />

          <template v-if="$route.params.workspace_id && loaded && hasWorkspaces">
            <div class="contract-list contract-list--summary">
              <div class="contract-list__content">
                <Overview :workspace="workspace" />
              </div>
            </div>
          </template>

          <DocumentUploader :workspaceId="this.$route.params.workspace_id" :createContract="true" />

          <TemplatesTable
            v-if="activeWorkspace && activeWorkspace.settings.contracts.can_create_form_contract"
            :title="$t('dashboard.favourite_patterns')"
            :templates="workspace.favourite_templates"
            :noSearch="true"
            class="dashboard-templates-wrapper"
          />

          <div v-if="isTutorialVisible" id="tutorial-profile-finish" class="dashboard-tutorial__box-wrapper">
            <TutorialProfileFinish
              :tasks="tasks"
              :finished="countFinishedTasks"
              :profile="profile"
              @hideComponent="isTutorialVisible = false"
            />
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <div v-if="profile && profile.invitations" class="dashboard-content">
        <Invitation v-for="(invitation, index) in profile.invitations" :key="index" :invitation="invitation" />

        <v-expansion-panels class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header class="primary--text font-weight-bold">
              {{ $t('dashboard.invitation.create_account') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <NoWorkspaceWarning />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <NoWorkspaceWarning v-else />
    </template>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex'
import { DashboardService } from '@/services/DashboardService.js'
import profileCompletenessHelper from '@/helpers/ProfileCompletenessHelper.js'

import IcoDashboardContract from '@/components/svg/IcoDashboardContract.vue'
import IcoDashboardSecurity from '@/components/svg/IcoDashboardSecurity.vue'
import IcoDashboardTemplates from '@/components/svg/IcoDashboardTemplates.vue'

import TemplatesTable from '@dashboard/components/TemplatesTable'
import Invitation from './components/Invitation.vue'
import Overview from './components/Overview.vue'

import CreditNotification from './components/CreditNotification.vue'
import GoogleDriveUpload from './components/GoogleDriveUpload.vue'
import DocumentUploader from '@contract/components/DocumentUploader'
import TutorialProfileFinish from '@/views/tutorial/components/TutorialProfileFinish'
import DashboardNotifications from '@dashboard/components/DashboardNotifications'
import ArchiveNotification from '@dashboard/components/ArchiveNotification'
import DisableAccNotification from '@dashboard/components/DisableAccNotification'
import NoWorkspaceWarning from '@/features/dashboard/components/NoWorkspaceWarning'

export default {
  name: 'dashboard',
  components: {
    NoWorkspaceWarning,
    ArchiveNotification,
    DisableAccNotification,
    DashboardNotifications,
    Invitation,
    TemplatesTable,
    Overview,
    IcoDashboardTemplates,
    IcoDashboardSecurity,
    IcoDashboardContract,
    CreditNotification,
    GoogleDriveUpload,
    DocumentUploader,
    TutorialProfileFinish,
  },
  data() {
    return {
      loaded: false,
      shouldShowMenu: true,
      isTutorialVisible: false,
      data: {
        contracts: [],
        invitations: [],
      },
      workspace: {},
      countOfDocumentsInTrash: 0,
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      profileWorkspaces: 'profileWorkspaces',
      workspaceById: 'workspaceById',
    }),
    isLoading() {
      return !this.loaded
    },
    hasUserWorkspaces() {
      return 0 < this.profileWorkspaces?.length
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id)
    },
    canShowArchiveNotification() {
      return this.activeWorkspace?.trusted_archive?.status !== 'archive_enabled' && this.countOfDocumentsInTrash !== 0
    },
    isAccDisabled() {
      return this.activeWorkspace?.owner_disabled
    },
    canShowDisabledAccNotification() {
      return !!this.activeWorkspace?.owner_disabled_message
    },
    countFinishedTasks() {
      return profileCompletenessHelper.countFinishedTasks(this.tasks)
    },
    credits() {
      return this.activeWorkspace.type === 'legal' ? this.activeWorkspace.owner_credits : this.profile.credits
    },
    hasWorkspaces: function () {
      return this.profileWorkspaces.length
    },
    tasks() {
      return profileCompletenessHelper.getProfileTasks(this.profile)
    },
  },
  watch: {
    $route: 'fetchData',
    profile: {
      handler: function () {
        if (!this.$route.params.workspace_id && this.profileWorkspaces.length) {
          const localStorageWorkspace = this.workspaceById(localStorage.getItem('workspace_id'))
          this.$router.push({
            name: 'dashboard',
            params: {
              workspace_id: localStorageWorkspace ? localStorageWorkspace.id : this.profileWorkspaces[0].id,
            },
          })
          this.shouldShowMenu = true
          this.loaded = true
        } else {
          this.loaded = true
          this.isTutorialVisible = this.profile && this.tasks.length !== this.countFinishedTasks
          this.$forceUpdate()
        }
      },
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({
        name: 'login',
      })
    }

    const routeWorkspaceId = this.$route.params.workspace_id

    if (routeWorkspaceId) {
      this.loaded = false

      localStorage.setItem('workspace_id', routeWorkspaceId)

      DashboardService.fetchData(routeWorkspaceId).then((data) => {
        this.workspace = data
        this.countOfDocumentsInTrash = data?.contract_summaries ? data?.contract_summaries[`archive.trashed`] : 0

        this.loaded = true
        this.$forceUpdate()
      })
    } else {
      this.shouldShowMenu = false
      this.$forceUpdate()
    }
  },
  methods: {
    createContract() {
      this.$router.push({
        name: 'createNew',
        params: {
          workspace_id: this.$route.params.workspace_id,
        },
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if ('0' === from.query?.onedriveError) {
        return vm.$router.push({
          name: 'workspaceSettings',
          path: `/workspace/${from.query.workspaceId}/settings`,
          params: {
            workspace_id: from.query.workspaceId,
          },
          hash: '#settings',
        })
      }
    })
  },
}
</script>

<style lang="scss">
.dashboard-content {
  max-width: 845px;
  margin: 0 auto;
}

.dashboard__title {
  max-width: 845px;
  margin: -15px auto 0 auto;

  @include md {
    margin: 0 15px;
  }

  h1 {
    text-align: left;
    font-weight: 500;
  }
}

.dashboard-create-contract {
  @include md {
    display: none;
  }
}

.contract-list {
  max-width: 845px;
  margin: 0 auto;

  @include sm {
    padding: 0;
  }
}

.contract-list--summary {
  display: block;

  .button {
    @include sm {
      max-width: 640px !important;
      width: 100% !important;
    }
  }
}

.contract-list__title {
  text-align: center;
  margin-bottom: 10px;
}

.contract-list__content {
  padding-top: 25px;

  @include md {
    padding-top: 0;
  }
}

.contract-list__create {
  border: 1px solid #d4dff0;
  border-radius: 6px;
  background-color: #fff;
  padding: 100px 0 60px 0;
  position: relative;

  @include md {
    padding: 40px 0 40px 0;
  }
}

.contract-list__create__text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #6128a0;
}

.contract-list__create {
  text-align: center;
}

.contract-list__create__items {
  padding-top: 30px;
  padding-bottom: 40px;
  @include clearfix;
}

.contract-list__create__item {
  display: inline-block;
  width: 240px;
  padding: 0 34px;

  @include lg {
    // width: auto;
  }

  @include md {
    width: 100%;
    margin-bottom: 30px;

    br {
      display: none;
      opacity: 0;
    }
  }

  @include sm {
    margin-top: 30px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    font-size: 17px;
    color: #5e239e;
  }
}

body .tip--dashboard {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 55px !important;
  padding-right: 55px !important;
  text-align: center;

  p {
    margin-bottom: 0;
    text-align: center;
    color: #73560b;
  }

  @include md {
    transform: translate(0, 0);
    position: static !important;
    text-align: center !important;
    padding: 20px !important;
  }
}

.tip-big {
  position: absolute !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);

  &.tip:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fffad1;
    position: absolute;
    bottom: -4px;
    border-bottom: none;
    border-right: 0px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: unset;
  }
}

.dashboard-tutorial__box-wrapper {
  margin: 50px auto 0;
  max-width: 845px;

  @include md {
    margin: 20px 0 0 0;
  }
}

.dashboard-templates-wrapper {
  max-width: 845px;
}
</style>
