<script setup>
import { computed, ref } from 'vue'
import { goToLinkWithTarget } from '@/common/reusable/routing'
import store from '@/store'

const show = ref(false)

const newsMessages = computed(() => store.getters['dashboardFeature/messages'])
const hasSomeNews = computed(() => newsMessages.value?.length > 0 || false)

function openExternalLink(url) {
  goToLinkWithTarget(url)
}
</script>

<template>
  <v-menu v-model="show" offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="primary" class="app-help-center__activator" small> mdi-help </v-icon>
      </v-btn>
    </template>

    <v-card class="pa-2" max-width="22rem">
      <v-card-title class="pb-1 text-decoration-underline">
        {{ $t('help.title') }}
      </v-card-title>

      <v-card-text class="pb-2">
        <p class="help__message font-weight-regular mb-0" v-html="$t('help.htmlDescription')"></p>
      </v-card-text>

      <v-card-subtitle class="pb-2 font-weight-medium black--text">
        {{ $t('login.marketing.show') }}
      </v-card-subtitle>

      <v-card-text :class="hasSomeNews ? 'pb-2' : 'pb-4'">
        <v-btn color="primary" outlined block @click="openExternalLink('https://calendly.com/signisales')">
          {{ $t('login.marketing.plan') }}
        </v-btn>
      </v-card-text>

      <template v-if="hasSomeNews">
        <v-card-title class="pb-1 text-decoration-underline">
          {{ $t('news.header.title') }}
        </v-card-title>

        <v-list class="pb-0 py-0">
          <v-list-item
            v-for="message in newsMessages"
            :key="message.id"
            link
            three-line
            @click="openExternalLink(message.link)"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ message.title }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ message.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped>
.app-help-center {
  &__activator {
    border-radius: 50%;
    border: 3px solid #7c4cb0;
  }
}

:deep(.help__message) a {
  color: #4a4a4a !important;
  font-weight: 400;
  font-size: 15px;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
