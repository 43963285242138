import { defineStore } from 'pinia'
import * as workspaceService from '@/stores/services/workspaceService'

export const useWorkspaceStore = defineStore('workspace', {
  state: () => ({
    data: null,
    footerSettings: null,
  }),
  actions: {
    fetchWorkspaceSettings(workspaceId) {
      return workspaceService.fetchWorkspaceSettings(workspaceId).then((resp) => {
        this.data = Object.assign({}, resp)

        return resp
      })
    },
    async fetchSignatureFooterSettings(workspaceId) {
      if (this.footerSettings) {
        return this.footerSettings
      }

      return workspaceService.fetchSignatureFooterSettings(workspaceId).then((resp) => {
        this.footerSettings = resp

        return resp
      })
    },
    setWorkspaceSettings(data) {
      this.data = Object.assign({}, data)
    },
    resetWorkspaceSettings() {
      this.data = null
    },
  },
  getters: {
    workspace: (state) => {
      return state.data
    },
    workspaceFeatureFlags: (state) => {
      return state.data?.feature_flags
    },
    workspaceId: (state) => {
      return state.data?.id
    },
    workspaceOwnerName: (state) => {
      return `${state.data?.firstname || ''} ${state.data?.lastname || ''}`?.trim()
    },
    workspaceSettings: (state) => {
      return state.data?.settings
    },
    workspaceContractSettings: (state) => {
      return state.data?.settings?.contracts
    },
    isOnedriveEnabled: (state) => {
      return state.data?.settings?.onedrive_enabled
    },
    isOwner: (state) => {
      return state.is_owner
    },
    workspaceTitle: (state) => {
      return `${state.data?.title || ''}`
    },
    workspaceType: (state) => {
      return state.data?.type
    },
    signatureFooterSettings: (state) => {
      return state.footerSettings
    },
  },
})
