<template>
  <v-dialog :value="showDialog" max-width="500" persistent @keydown.esc="onEsc">
    <v-card class="pa-5 pa-md-10 mx-auto" max-width="800px" elevation="2">
      <v-row class="px-5" align="center" justify="center" no-gutters>
        <v-col cols="12" class="pb-5">
          <h1 class="font-weight-medium mb-0">
            {{ $t('two_factor_auth.two_factor_auth_heading') }}
          </h1>
        </v-col>

        <v-col cols="12" class="pb-5">
          <h2 class="font-weight-medium mb-0 text-center">
            {{ $t('two_factor_auth.enter_pin_heading_heading') }}
          </h2>
        </v-col>

        <v-col cols="12" class="pt-0 px-0 px-sm-8 px-md-16 text-center">
          <span class="subtitle-1 font-weight-regular">
            {{ $t('two_factor_auth.enter_pin_heading_text') }}
          </span>
        </v-col>

        <v-col cols="12" class="py-0 text-center" style="max-width: 20rem">
          <InputPIN ref="inputPin" v-model="pinCode" @confirmPin="confirmPin" />
        </v-col>

        <v-col cols="12" class="pt-0 pb-3 text-center">
          <v-btn color="primary" min-width="10rem" x-large :loading="isConfirmingPIN" @click="confirmPin">
            {{ $t('two_factor_auth.submit_pin') }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-0 pb-5 text-center">
          <v-btn text class="font-weight-regular primary--text text-decoration-underline" @click="sendPinToPhone">
            {{ $t('two_factor_auth.didnt_receive_pin') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import InputPIN from '@/components/InputPIN.vue'
import { generateAuthHeader } from '@/config/authHeader'
import { internalApiRequest } from '@/shared/services/apiService'
import { authorizedApiRequest } from '@/services/ApiService'

export default {
  name: 'TwoFactorAuthenticationDialog',
  components: {
    InputPIN,
  },
  props: {
    value: Boolean,
    profile: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      pinCode: '',
      isConfirmingPIN: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onEsc() {
      const workspacesWithout2FA = this.profile.workspaces.filter((ws) => !ws?.is2_fa_required)
      if (workspacesWithout2FA.length) {
        localStorage.setItem('workspace_id', workspacesWithout2FA[0].id)

        return this.$router.push({
          name: 'dashboard',
          params: {
            workspace_id: workspacesWithout2FA[0].id,
          },
        })
      }
    },
    confirmPin() {
      this.isConfirmingPIN = true

      if ('' === this.pinCode) {
        this.isConfirmingPIN = false

        return
      }

      internalApiRequest({
        method: 'POST',
        path: `api/v1/users/verify/sms`,
        data: {
          pin: this.pinCode,
        },
        headers: generateAuthHeader({
          authorization: 'Bearer',
          authToken: this.profile?.access_token || localStorage.getItem('token'),
        }),
      })
        .then(() => {
          return this.$emit('successful-verification')
        })
        .catch((errorCode) => {
          if (429 === errorCode) {
            this.$notification.error(this.$t('contract.errors.pin_limit'))
            return
          }
          this.isConfirmingPIN = false
          this.$notification.error(
            403 === errorCode ? this.$t('contract.errors.access_2fa') : this.$t('contract.errors.invalid_pin'),
          )
        })
    },
    sendPinToPhone() {
      return authorizedApiRequest({
        method: 'POST',
        endpoint: `api/v1/users/generate/sms`,
      })
        .then(() => {
          this.$notification.success(this.$t('account.send_pin.ok'))
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'))
        })
    },
  },
}
</script>
<style></style>
