import { apiUrl } from '@/app/config/appEnvironment'
import { internalApiRequest } from '@/shared/services/apiService'
import axios from 'axios'
import router from '@/app/plugins/router'
import { authHeader } from '@/config/authHeader'
import { autologoutIfUnauthorized } from './helpers'
import { parseJSON } from '@/services/helpers/json.js'
import { authorizedApiRequest, publicApiRequest } from '@/services/ApiService'

async function prefill(contractId) {
  const response = await fetch(`${apiUrl}/api/v1/contracts/${contractId}/prefill`, {
    method: 'POST',
    headers: { ...authHeader() },
  })
  return response.status || 500
}

function saveSignPositions(contract_id, signIdentitiesWithPositions) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(signIdentitiesWithPositions),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/positions`, requestOptions).then(handleResponse)
}

function resendInvitation(contract_id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/resendpartynotification`, requestOptions).then(handleResponse)
}

function searchAres(ico) {
  return searchExternalDB(ico, 'cz')
}

async function searchExternalDB(ico, country) {
  try {
    const response = await axios.post('https://us-central1-signi-master.cloudfunctions.net/api/v1/subject/search', {
      identificationNumber: ico,
      countryCode: 'cs' === country ? 'cz' : country,
    })

    return response?.data?.data?.subjectData || null
  } catch (e) {
    throw e
  }
}

function getPDF(contract_id) {
  var token = localStorage.getItem('token')

  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/pdf',
      'content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    responseType: 'arrayBuffer  ',
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/pdf`, requestOptions).then(handleResponseRaw)
}

function openPDFZipAuthorized(contract_id) {
  var token = localStorage.getItem('token')
  const win = window.open(`${apiUrl}/api/v1/contracts/${contract_id}/pdfzip?access_token=${token}`, '_blank')
  win.focus()
}

function getDoc(contract, format) {
  if (!contract) {
    // probably dead code? rather keep it because of https://gitlab.com/digitalfactorycz/ismlouva/-/commit/1cecb2a
    return Promise.reject('No document available for contract')
  }
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/documents/${contract.id}?format=${format}`, requestOptions).then(handleResponse)
}

function getLastDocuments(workspace, type) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${workspace}/lastdocuments?type=${type}`, requestOptions).then(
    handleResponse,
  )
}

function signaturefieldshown(contract_id, sign_id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/${sign_id}/signaturefieldshown`, requestOptions).then(
    handleResponse,
  )
}

function sendSignatureAuthorized(data, file, contract_id, identity_id, progress) {
  const dataToSend = {
    bin_data: file,
    signature_date: data.signature_date,
    signature_place: data.signature_place,
    progress: progress,
  }

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(dataToSend),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/${identity_id}/patch`, requestOptions).then(
    handleResponseSignatureAuhtorized,
  )
}

function handleResponseSignatureAuhtorized(response) {
  return response.text().then((text) => {
    const json = parseJSON(text)
    if (!response.ok) {
      if (response.status === 404) {
        return router.push({ name: 'notFound' })
      } else {
        // CreateSign has only success handler, so Promise.reject can't be used without bigger refactoring
        const error = (json && (json.message || json.title)) || response.statusText
        return {
          code: response.status,
          title: error,
        }
      }
    }
    return json
  })
}

function sendPinAuthorized(pin, contract_id, identity_id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ pin }),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/${identity_id}/verifypin`, requestOptions).then(
    handleResponsePin,
  )
}

function getRegisteredUsersForEditCounterparty(contract_id, identity_id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/${identity_id}`, requestOptions).then(handleResponse)
}

function editCounterpartyRegistered(contract_id, identity_id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }
  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/signs/${identity_id}`, requestOptions).then(handleResponse)
}

function sendPhoneAuthorized(data, contract_id, identity_id) {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: `api/v1/contracts/${contract_id}/signs/${identity_id}/pin`,
    data,
  })
}

function handleResponsePin(response) {
  return response.text().then((text) => {
    const json = parseJSON(text)
    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }
      return Promise.reject(json ? json.code : response.status)
    }
    return json
  })
}

function sendSignOnMobileLink(identity_id, data) {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: `api/v1/signs/signsms/${identity_id}`,
    data,
  })
}

function sendSignOnMobileLinkUnauthorized(identity_id, data, token) {
  return publicApiRequest({
    authorization: 'ApiToken',
    authToken: token,
    method: 'POST',
    endpoint: `api/v1/signs/signsms/${identity_id}?with=completed`,
    data,
  })
}

function rejectAuthorized(contract_id, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/decline`, requestOptions).then(handleResponse)
}

function getCounterparty(contract_id, searchString) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/users?q=${searchString}`, requestOptions).then(handleResponse)
}

function createSampleContract() {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/users/current/sampleContract`, requestOptions).then(handleResponse)
}

function getDuplicatedPrice(contract) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v2/contract/${contract.id}/duplicate/price`, requestOptions).then(handleResponse)
}

function duplicate(contract, errorHandler) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v2/contract/${contract.id}/duplicate`, requestOptions)
    .then(handleResponse)
    .then((newContract) => {
      router.push({
        name: 'contractsCollectionParties',
        params: {
          workspaceId: newContract?.workspace_id,
          contractId: newContract?.id,
        },
      })
    }, errorHandler)
}

function create(data, activeWorkspace) {
  const workspace = activeWorkspace || {
    id: null,
    locale: null,
    settings: {
      contracts: {
        is2FAEnabled: true,
        draftExpirationDays: null,
        completedExpirationDays: null,
      },
    },
  }

  let endingDate = null

  if (workspace.settings.contracts.completedExpirationDays) {
    const futureDate = new Date()
    futureDate.setDate(futureDate.getDate() + workspace.settings.contracts.completedExpirationDays)
    endingDate = futureDate.toISOString().slice(0, 10)
  }

  return internalApiRequest({
    method: 'POST',
    path: `api/v1/contracts`,
    data: {
      one_device: 0,
      workspace_id: workspace.id,
      locale: workspace.locale,
      contract_ending_date: endingDate,
      send_notification_for_ending_contract: endingDate ? 1 : 0,
      ...data,
      contacts_ids_csv: router.currentRoute && router.currentRoute.query ? router.currentRoute.query.contacts : '',
    },
  })
}

function update(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }
  return fetch(`${apiUrl}/api/v1/contracts/${id}`, requestOptions).then(handleResponse)
}

export const fetchEnvelopTitle = (id) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `api/v2/contract/${id}/envelope`,
  })
}

export const updateEnvelopTitle = ({ id, title }) => {
  return authorizedApiRequest({
    method: 'PUT',
    endpoint: `api/v2/contract/${id}/envelope`,
    data: { title },
  })
}

function send(id, request) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }
  const queryString = request ? `?${new URLSearchParams(request).toString()}` : ''
  return fetch(`${apiUrl}/api/v1/contracts/${id}/send${queryString}`, requestOptions).then(handleResponse)
}

function extendExpiration(workspaceId, contracts, extendDaysCount) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({
      contracts_ids: contracts.map((c) => c.id),
      extra_days_count: extendDaysCount,
    }),
  }
  return fetch(`${apiUrl}/api/v1/contracts/${workspaceId}/expiration`, requestOptions).then(handleResponse)
}

function detailWithLinks(id, workspace_id) {
  return detail(id, workspace_id, null, 'links')
}

function detailWithSignsAndLinks(id, workspace_id) {
  return detail(id, workspace_id, null, 'signs,links')
}

function detail(id, workspace_id, attachment_id, withData) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  const queryString = []

  if (workspace_id) {
    queryString.push(`workspace_id=${workspace_id}`)
  }
  if (attachment_id) {
    queryString.push(`attachment_id=${attachment_id}`)
  }
  if (withData) {
    queryString.push(`with=${withData}`)
  }

  return fetch(`${apiUrl}/api/v1/contracts/${id}?${queryString.join('&')}`, requestOptions).then((response) => {
    return response.text().then((text) => {
      const data = parseJSON(text)

      if (!response.ok) {
        if (response.status === 404) {
          return router.push({ name: 'dashboard' })
        }

        if (!store.getters['profile']) {
          return autologoutIfUnauthorized(response)
        }

        if (response.status === 401) {
          return router.push({ name: 'dashboard' })
        }

        if (data && data.title === 'Uživatel nemá dostatek kreditů') {
          return Promise.reject(data.title)
        }

        return Promise.reject((data && data.message) || response.statusText)
      }

      return data
    })
  })
}

function getComments(contractId, mode) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/contracts/${contractId}/comments?mode=${mode}`, requestOptions).then(handleResponse)
}

function manageComments(contractId, action, comment) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({
      // check constants in ManageComments and format in ManageCommentsTest
      action,
      comment,
    }),
  }
  return fetch(`${apiUrl}/api/v1/contracts/${contractId}/comments`, requestOptions).then(handleResponse)
}

function addAttachment(data, contract_id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contract_id}/attachment`, requestOptions).then(handleResponse)
}

function addAttachments(contractId, attachments) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(attachments),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${contractId}/attachments`, requestOptions).then(handleResponse)
}

function _delete(id, deleteComplete) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${apiUrl}/api/v1/contracts/${id}?delete_complete=${deleteComplete ? '1' : '0'}`, requestOptions).then(
    handleResponseDeleteWorkspace,
  )
}

function _deleteDocs(docs_id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${apiUrl}/api/v1/docs/${docs_id}`, requestOptions).then(
    handleResponseDeleteWorkspace,
  )
}

function deleteCompleted(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${apiUrl}/api/v2/contract/${id}/delete/documents`, requestOptions).then(handleResponseDeleteWorkspace)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = parseJSON(text)

    if (!response.ok) {
      if (response.status === 404) {
        return router.push({ name: 'notFound' })
      }

      autologoutIfUnauthorized(response)

      if (data && data.title === 'Uživatel nemá dostatek kreditů') {
        return Promise.reject(data.title)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function handleResponseRaw(response) {
  return response
}

function handleResponseFull(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }
      // TODO: when returning error as success result is good idea?
      // (addAttachment - it caused redirect to homepage, if API returned code 400)
    }

    return parseJSON(text)
  })
}

function handleResponseDeleteWorkspace(response) {
  return response.text().then((text) => {
    const data = parseJSON(text)

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }

      autologoutIfUnauthorized(response)

      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data)
    }

    return data
  })
}

function setContractViewed(id, signId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify([]),
  }

  return fetch(`${apiUrl}/api/v1/contracts/${id}/signs/${signId}/viewed`, requestOptions).then(handleResponse)
}

function redrawContractAfterSendIfNotDraft(contract, isDryRun) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  }
  return fetch(
    `${apiUrl}/api/v1/contracts/${contract.id}/redraw-after-send?isDryRun=${isDryRun ? 1 : 0}`,
    requestOptions,
  ).then(handleResponse)
}

export const ContractService = {
  prefill,
  update,
  send,
  extendExpiration,
  getDuplicatedPrice,
  duplicate,
  create,
  detail,
  detailWithLinks,
  detailWithSignsAndLinks,
  getComments,
  fetchEnvelopTitle,
  updateEnvelopTitle,
  manageComments,
  createSampleContract,
  getRegisteredUsersForEditCounterparty,
  editCounterpartyRegistered,
  getCounterparty,
  rejectAuthorized,
  sendPhoneAuthorized,
  sendPinAuthorized,
  sendSignatureAuthorized,
  sendSignOnMobileLink,
  sendSignOnMobileLinkUnauthorized,
  getLastDocuments,
  getPDF,
  openPDFZipAuthorized,
  getDoc,
  resendInvitation,
  searchExternalDB,
  searchAres,
  saveSignPositions,
  delete: _delete,
  deleteDocs: _deleteDocs,
  deleteCompleted,
  signaturefieldshown,
  addAttachment,
  addAttachments,
  setContractViewed,
  redrawContractAfterSendIfNotDraft,
}
