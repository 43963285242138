<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-tym.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-tym.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-6" transform="translate(0.000000, 2.000000)" stroke="currentColor" stroke-width="2">
        <path
          d="M5.13472401,20.1538462 C8.2265725,19.5354119 10.0486699,19.0470741 10.6010162,18.6888328 C11.1533626,18.3305915 11.2950608,17.3573908 11.0261108,15.7692308"
          id="Path-25"
        ></path>
        <path
          d="M16.9039548,20.1538462 C19.9958033,19.5354119 21.8179007,19.0470741 22.370247,18.6888328 C22.9225933,18.3305915 23.0642915,17.3573908 22.7953416,15.7692308"
          id="Path-25-Copy"
          transform="translate(19.915703, 17.961538) scale(-1, 1) translate(-19.915703, -17.961538) "
        ></path>
        <path
          d="M12.3846154,16.4615385 C10.9501133,16.0825014 9.90320641,15.0319589 9.24389485,13.3099109 C8.25492751,10.7268388 7.77081762,6.45054223 8.36212327,4.46153846 C8.95342892,2.4725347 11.059768,1 14.0999781,1 C17.1401882,1 18.7467414,3.08690062 19.5044887,4.46153846 C20.2622359,5.83617631 19.806481,11.3097455 18.5835272,13.6923077 C17.7682247,15.2806825 16.7562045,16.2037594 15.5474667,16.4615385"
          id="Path-32"
        ></path>
        <g id="Group">
          <path
            d="M0.58105644,17.4973822 C2.55306433,16.9915726 3.8240228,16.5610768 4.39393185,16.2058948 C4.96384091,15.8507127 5.18389934,15.0650962 5.05410715,13.8490451"
            id="Path-74"
          ></path>
          <path
            d="M6.23363505,14.1965058 C4.83374231,13.751609 3.84442922,12.8577097 3.26569577,11.5148079 C2.39759559,9.50045515 1.99387937,5.5094372 2.90793995,3.30646294 C3.82200054,1.10348869 6.4038724,0.608587018 8.44837517,1.2240888"
            id="Path-80"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoTeam',
}
</script>

<style lang="scss" scoped></style>
