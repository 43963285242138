<template>
  <v-dialog :value="true" :fullscreen="true">
    <v-container class="signature-pad__wrapper fill-height ma-0 pa-0" fluid>
      <SignaturePad
        class="signature-pad__landscape my-auto mx-auto"
        :preloaded-signature-image="preloadedSignatureImage"
        :show-progress="false"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import SignaturePad from '@signature/components/SignaturePad'

export default {
  name: 'LandscapeSigning',
  components: {
    SignaturePad,
  },
  props: {
    preloadedSignatureImage: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.signature-pad {
  &__wrapper {
    background-color: #f2f6fc;
  }

  &__landscape {
    background-color: white;
    border: 1px solid #b1bbcb;
  }
}
</style>
