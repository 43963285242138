<template lang="html">
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN001---DSKTP---Otevřené-smlouvy" transform="translate(-713.000000, -318.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1200" height="919"></rect>
        <g id="Group-5" transform="translate(377.000000, 270.000000)" stroke="#6DECAF">
          <g id="ico/ico-smlouva-potvrzena" transform="translate(336.000000, 48.000000)">
            <g id="Group-3">
              <circle id="Oval" stroke-width="2" fill-rule="nonzero" cx="9" cy="9" r="8"></circle>
              <polyline
                id="Path-21"
                stroke-width="2"
                fill-rule="nonzero"
                points="5.38888296 8.47185318 8.35730247 11.4402727 13.1975752 6.6"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoCircleCheck',
}
</script>
