<template lang="html">
  <button
    @click="$emit('click')"
    v-bind:class="[
      'button--' + type,
      { disabled: disabled },
      { 'small-text': type == 'accept-small' },
      { 'no-hover': noHover },
    ]"
    :disabled="disabled"
    class="button"
    :type="buttonType"
    :style="cssVars"
  >
    <span>
      <span
        v-if="
          !hideIcon &&
          (type == 'accept' || type == 'accept-small' || type == 'plus' || type == 'reject' || type == 'search')
        "
        class="button__ico button__ico--left"
      >
        <IcoCheck v-if="type == 'accept'" />
        <IcoCheck v-if="type == 'accept-small'" />
        <IcoPlus v-if="type == 'plus'" />
        <IcoReject v-if="type == 'reject'" />
        <IcoSearchPurple v-if="type == 'search'" />
      </span>
      <span v-if="type == 'add-person'" class="button__ico button__ico--left">
        <IcoPlus v-if="type == 'add-person'" />
      </span>
      <span v-if="hasStyledSlot"><slot /></span>
      <span v-else-if="hideIcon" class="text"> <slot /> </span>
      <span v-else class="button__text"><slot /></span>
      <span v-if="type == 'right-plus'" class="button__ico button__ico--right">
        <IcoPlusRight v-if="type == 'right-plus'" />
      </span>
      <span v-if="type == 'secondary-plus'" class="button__ico button__ico--right">
        <IcoPlus v-if="type == 'secondary-plus'" />
      </span>

      <span v-if="type == 'right-edit'" class="button__ico button__ico--right">
        <span class="icon-pen1"></span>
      </span>
    </span>

    <span class="button-loading" v-if="loading"></span>
  </button>
</template>

<script>
import IcoCheck from '@/components/svg/IcoCheck.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoReject from '@/components/svg/IcoReject.vue'
import IcoPlusRight from '@/components/svg/IcoPlusRight.vue'
import IcoSearchPurple from '@/components/svg/IcoSearchPurple.vue'
import { Colors } from '@/helpers/Colors.js'

export default {
  name: 'Button',
  props: {
    type: String,
    disabled: Boolean,
    loading: {
      default: false,
    },
    buttonType: {
      default: 'button',
    },
    noHover: {
      default: false,
    },
    customPrimaryColor: {
      type: String,
      required: false,
      default: null,
    },
    hideIcon: Boolean,
    hasStyledSlot: Boolean,
  },
  components: {
    IcoCheck,
    IcoPlus,
    IcoPlusRight,
    IcoReject,
    IcoSearchPurple,
  },
  computed: {
    cssVars() {
      return {
        '--color': this.customPrimaryColor,
        '--color-lighten': Colors.lightenDarkenHex(this.customPrimaryColor, 20),
        '--color-shadow': Colors.hexToRgba(Colors.lightenDarkenHex(this.customPrimaryColor, -20), 0.62),
      }
    },
  },
}
</script>

<style lang="scss">
button {
  outline: none;
}

.button {
  @include md {
    // width: 100% !important;
    max-width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 545px) {
    // width: 100% !important;
    /*width: 260px !important;*/
  }
}

.button.disabled {
  background-color: #d4dff0;
  background-image: none !important;
  color: #fff;
  cursor: default;

  &:hover {
    background-color: #d4dff0 !important;
    background-image: none !important;
  }

  &:hover:after {
    display: none !important;
  }
}

.profile-buttons {
  margin-top: 30px;
  margin-bottom: 20px;
}

.eye-button {
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 20px;
  width: fit-content;
  height: 25px;
  padding-top: 5px;
  margin-right: 15px;
  top: 0;
  text-align: right;
}
.password-line .eye-button {
  top: 21px;
}

.button {
  width: 240px;
  height: 56px;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  max-width: 100%;
  &:focus {
    overflow: hidden;
    outline: none;
  }
}

.button--primary {
  color: #ffffff;
  background-image: linear-gradient(-90deg, var(--color, #934fdd) 0%, var(--color, #6226a3) 100%);
  box-shadow: 0 7px 19px 0 var(--color-shadow, rgba(94, 35, 158, 0.62));
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  position: relative;
  z-index: 1;
  &:focus,
  &:active {
    background-color: var(--color-lighten, #712cbd) !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
  }
  @include md {
    box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
  }
}

.button--primary-small {
  color: #ffffff;
  background-image: linear-gradient(-90deg, #934fdd 0%, #6226a3 100%);
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  position: relative;
  z-index: 1;
  font-weight: 500;
  min-width: 150px;
  height: 46px;
  box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
  width: auto;
  padding: 0px 25px;
  &:focus,
  &:active {
    background-color: #712cbd !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
  }
  @include md {
    box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
  }
}

.button--right-plus {
  color: #ffffff;
  background-image: linear-gradient(-90deg, #934fdd 0%, #6226a3 100%);
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  position: relative;
  z-index: 1;
  font-weight: 500;
  min-width: 181px;
  height: 46px;
  width: auto;
  padding: 0px 25px;
  &:focus,
  &:active {
    background-color: #712cbd !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
  }
  @include md {
    box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
    height: 56px;

    .button__text {
      float: left;
    }

    .button__ico {
      float: right;
    }
  }
}

.button--right-edit {
  color: #ffffff;
  background-image: linear-gradient(-90deg, #934fdd 0%, #6226a3 100%);
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  position: relative;
  z-index: 1;
  font-weight: 500;
  min-width: 170px;
  height: 46px;
  width: auto;
  padding: 0px 25px;
  &:focus,
  &:active {
    background-color: #712cbd !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
    @include md {
      background-color: transparent !important;
    }
  }
  @include md {
    box-shadow: none;
    background-image: unset;
    background-color: transparent;
    color: black;
    min-width: unset;
    padding: 15px;
  }
  .button__text {
    @include md {
      display: none;
    }
  }
  .button__ico {
    @include md {
      padding: 0px;
    }
  }
}

.button--accept {
  background: #5e239e;
  color: #ffffff;
  width: auto;
  padding-bottom: 0px;
  margin: 0;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(-90deg, #934fdd 0%, #6226a3 100%);
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
  border-radius: 6px;
  &:focus,
  &:active {
    background-color: #712cbd !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
  }
  @include md {
    box-shadow: none;
    color: white;
    min-width: unset;
    padding: 15px;
  }
}

.button--accept:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #712cbd;
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.button--accept-small {
  background: var(--color, #5e239e);
  color: #ffffff;
  width: auto;
  padding-bottom: 0px;
  margin: 3px 0 0 0;
  height: 50px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(-90deg, var(--color, #934fdd) 0%, var(--color, #6226a3) 100%);
  transition: background-image 0.5s linear, box-shadow 0.2s linear;
  box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
  border-radius: 6px;
  &:focus,
  &:active {
    background-color: var(--color, #712cbd) !important;
    background-image: none;
    box-shadow: 0px 0px 0px;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
  }
  @include md {
    box-shadow: none;
    color: white;
    min-width: unset;
    // padding: 15px;
  }
}

.button--accept-small:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--color, #712cbd);
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.button.button--accept-small .button__ico {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 17px;
}

.button.button--accept-small .button__text {
  padding-left: 30px;
  display: block;
  text-align: left;
  font-size: 14px;
}

.button.button--accept-small .text {
  font-size: 14px;
}

.button--normal {
  background: #5e239e;
  color: #ffffff;
  width: auto;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
}

.button--decline {
  width: auto;
  background: none;
  color: #5e239e;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
}

.button__ico {
  display: inline-block;
  padding-right: 10px;
}

.btn {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;
  font-weight: 300;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transition: 0.2s;
    text-decoration: underline;
  }
}

.btn--delete-account {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}
.btn--delete-workspace {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}
.btn-primary {
  color: #5e239e;
}
.btn-secondary {
  color: #cd0000;
}

.button--primary:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--color-lighten, #712cbd);
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.button--primary-small:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #712cbd;
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.button--right-edit:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #712cbd;
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
  @include md {
    content: unset;
  }
}

.button--right-plus:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #712cbd;
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}
.button:hover:after {
  opacity: 1;
}
.button.no-hover:hover:after {
  opacity: 0 !important;
}
.button .button__text {
  position: relative;
  z-index: 3;
}
.button .text {
  position: relative;
  z-index: 3;
}
.button .button__ico {
  position: relative;
  z-index: 3;
}

.button--top {
  margin-bottom: 10px;
}

.button--secondary {
  position: relative;
  border: 1px solid var(--color, #5e239e);
  color: var(--color, #5e239e);
  background-color: white;
  transition: 0.5s;
  &:hover {
    color: var(--color-lighten, #9268be);
    border: 1px solid var(--color-lighten, #9268be);
    transition: 0.5s;
  }
  &:active,
  &:focus {
    border: 1px solid var(--color, #5e239e);
    color: var(--color, #5e239e);
  }
}
.button__ico svg g {
  transition: 0.5s;
}
.button--secondary-plus {
  border: 1px solid var(--color, #5e239e);
  color: var(--color, #5e239e);
  background-color: white;
  transition: 0.5s;
  &:hover {
    color: var(--color-lighten, #9268be);
    border: 1px solid var(--color-lighten, #9268be);
    transition: 0.5s;
    .button__ico svg g {
      fill: var(--color-lighten, #9268be);
      transition: 0.5s;
    }
  }
  &:active,
  &:focus {
    border: 1px solid var(--color, #5e239e);
    color: var(--color, #5e239e);
  }
}

.button--white {
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
}

.button--wide {
  width: 300px;
  .icon {
    padding-right: 10px;
  }
}
.button--wider {
  width: 270px;
}

.radio-button {
  width: 150px;
  height: 56px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ffffff;
  background-color: transparent;
  margin: 7px;
  color: #ffffff;
  letter-spacing: -0.03px;
  text-align: center;
  padding: 10px 15px;
  vertical-align: bottom;
  transition: 0.5s;
  @include sm {
    width: 130px;
  }
}

.radio-button--black {
  border: 1px solid #000;
  color: #000;
}

.radio-button--active {
  border: 1px solid #8145c2;
  color: #8145c2;
  background: white;
  transition: 0.5s;
}

.radio-button--black.radio-button--active {
}

.select-button {
  margin-left: 30px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background-color: white;
  height: 18px;
  padding: 0px;
  padding-bottom: 32px;
  transition: 0.2s;
  &:hover {
    transition: 0.2s;
    color: #9268be;
  }
}
.select-button--active {
  color: #9268be;
  border-bottom: solid 2px #9268be;
}
.sidemenu__button.button--plus .button__ico {
  float: right;
  padding-right: 8px;
  padding-left: 10px;
  line-height: 20px;
}
.sidemenu__button.button--plus .button__ico svg {
  vertical-align: middle;
}
.sidemenu__button.button--plus {
  width: 100%;
  font-size: 17px;
  color: #000000;
  letter-spacing: -0.04px;
  font-weight: normal;
  box-shadow: 0 6px 16px 0 rgba(212, 223, 240, 0.7);
  transition: 0.2s;
  background: white;
  svg path {
    transition: 0.2s;
  }
  &:hover {
    background: #6decaf;
    transition: 0.2s;
    //color:white;
    svg path {
      fill: white;
      transition: 0.2s;
    }
  }
}

.button-credit.button {
  font-size: 16px;
  height: 40px;
  width: auto !important;
  padding: 0px 15px;
  margin-bottom: 0px;

  .button__ico {
    display: none !important;
  }
}

.button-credit.button--primary {
  box-shadow: none;
}

.subscription-button.button {
  margin-top: 15px;
  font-size: 16px;
  height: 40px;
  width: auto;
  padding: 0px 15px;
  margin-bottom: 0px;

  .button__ico {
    display: none !important;
  }
}

.membership-button.button {
  .button__ico {
    display: none !important;
  }
}

.subscription-button.button--primary {
  box-shadow: none;
}

.disabled.button--primary {
  background: #d4dff0;
  box-shadow: 0px 0px 0px;
  transition: 0s;
  &:after {
    background: #d4dff0;
    transition: 0s;
  }
}

.disabled.button--secondary {
  color: #d4dff0;
  border: solid 1px #d4dff0;
}

.button__ico--right {
  padding-right: 0;
  padding-left: 10px;
}

.button--right-edit {
  .icon-pen1:before {
    color: white;
  }
}

.button--secondary-small {
  border: 1px solid #5e239e;
  color: #5e239e;
  background: transparent;
  position: relative;
  z-index: 1;
  font-weight: 500;
  min-width: 170px;
  height: 46px;
  width: auto;
  padding: 0px 25px;
  &:hover {
    color: #9268be;
    border: 1px solid #9268be;
    transition: 0.5s;
  }
  &:active,
  &:focus {
    border: 1px solid #5e239e;
    color: #5e239e;
  }
}

.content-finished__buttons {
  .button--primary-small {
    width: 100%;
  }
  .button--secondary-small {
    width: 100%;
  }
}

.button--right-edit .icon-pen1:before {
  @include md {
    color: #5e239e;
  }
}

.button--add-person {
  width: auto;
  height: auto;
  font-size: 16px;
  color: #5e239e;
  text-decoration: underline;
  transition: 0.2s;
  position: absolute;
  top: 50%;
  margin-bottom: 0px;
  padding-bottom: 5px;
  background: transparent;
  transform: translateY(-50%);
  &:hover {
    color: #9268be;
    transition: 0.2s;
  }
}

.workspace-person-resend.button {
  width: 100%;
}

.button--reject {
  border: 1px solid var(--color, #5e239e);
  color: var(--color, #5e239e);
  background-color: white;
  width: auto;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  transition: 0.5s;
  padding-bottom: 0px;
  &:hover {
    border: 1px solid var(--color-lighten, #9268be);
    color: var(--color-lighten, #9268be);
    transition: 0.5s;
  }
  &:active,
  &:focus {
    border: 1px solid var(--color, #5e239e);
    color: var(--color, #5e239e);
  }

  &:disabled {
    cursor: default;

    svg,
    g,
    path {
      fill: #fff !important;
      border-color: #fff !important;
      color: #fff !important;
      stroke: #fff !important;
    }

    &:hover {
      color: #fff !important;
    }
  }
}

.button--delete {
  font-size: 16px;
  color: #cd0000;
  cursor: pointer;
  font-weight: 300;
  height: auto;
  width: auto;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }
}

.contract-detail__controls {
  .button--reject {
    margin-bottom: 10px;
    width: 100%;
    .button__ico {
      position: absolute;
      left: 20px;
    }
  }
  .button--accept {
    margin-bottom: 10px;
    // margin-top: 30px;
    width: 100%;
    .button__ico {
      position: absolute;
      left: 20px;
    }
    @include md {
      margin-top: 20px;
    }
  }
  .button--delete {
    float: right;
    @include md {
      display: block;
      margin-left: auto;
      margin-right: auto;
      float: unset;
    }
  }
}

.button--search {
  font-size: 16px;
  color: #5e239e;
  cursor: pointer;
  font-weight: bold;
  height: auto;
  width: auto;
  background: transparent;
  transition: 0.2s;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    color: #9268be;
    text-decoration: underline;
  }
}

.contract-detail {
  .button--search {
    margin-top: -25px;
  }
}

.button-loading {
  width: 20px;
  height: 20px;
  background: url(/images/loader.svg);
  background-position: center;
  background-size: contain;
  animation: spin 4s linear infinite;

  position: relative;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  z-index: 4;
}

.button--link {
  background: transparent;
  width: auto;
  height: auto;
  border: none;
  color: black;
  margin-top: 5px;
  text-decoration: underline;
  transition: 0.2s;
  &:hover {
    border: none;
    text-decoration: underline;
    color: #5e239e;
    transition: 0.2s;
  }
}

.submit-twofa .button--link {
  text-decoration: none;
  font-weight: 300;
  font-size: 15px;
}

.button--type-clear {
  border: 1px solid #5e239e;
  color: #9e9fa0;
  background: transparent;
  position: relative;
  z-index: 1;
  font-weight: 500;
  min-width: 170px;
  height: 46px;
  width: auto;
  padding: 0px 25px;
  letter-spacing: -0.3px;
  &:hover {
    border: 1px solid #9268be;
    transition: 0.5s;
  }
  &:active,
  &:focus {
    border: 1px solid #5e239e;
  }
}

.button--back {
  padding-left: 60px !important;
  background: transparent;
  border: 1px solid grey;
  font-weight: unset;
  color: grey;

  &:hover {
    color: #5e239e;
    transition: 0.2s;
  }
}
</style>
