import { apiUrl } from '@/app/config/appEnvironment'
import { authHeader } from '@/config/authHeader'
import router from '@/app/plugins/router'

export const WorkspaceService = {
  findOne,
  update,
  create,
  getUsers,
  updateAppearance,
  updateNotifications,
  updateSettings,
}

function findOne(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }
  return fetch(`${apiUrl}/api/v1/workspaces/${id}`, requestOptions).then(handleResponse)
}

function getUsers(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${id}/allowedusers`, requestOptions).then(handleResponse)
}

function update(data, id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${id}`, requestOptions).then(handleResponse)
}

function updateAppearance(data, id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${id}/appearance`, requestOptions).then(handleResponse)
}

function updateNotifications(data, id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${id}/notifications`, requestOptions).then(handleResponse)
}

function updateSettings(data, id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/workspaces/${id}/settings`, requestOptions).then(handleResponse)
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiUrl}/api/v1/workspaces`, requestOptions).then(handleResponseFull)
}

function handleResponse(response) {
  return response.text().then((text) => {
    // const data = text && JSON.parse(text);
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function handleResponseFull(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    return data
  })
}

function handleResponseDeleteWorkspace(response) {
  return response.text().then((text) => {
    // const data = text && JSON.parse(text);
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' })
        return
      }
      return Promise.reject(data)
    }

    return data
  })
}
