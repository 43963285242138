import { PDFDocument } from 'pdf-lib'
import { circularFetchDocumentWithLink } from '@contract/services/contractService'

export const namespaced = true

export const state = {
  info: undefined,
  document: undefined,
  documentPages: undefined,
  documentLoaded: false,
  pagesLoaded: false,
}

export const mutations = {
  set(state, payload) {
    state = Object.assign(state, payload)
  },
  setInfo(state, payload) {
    state.info = payload
  },
  setPagesLoaded(state) {
    state.pagesLoaded = true
  },
  setPagesLoading(state) {
    state.pagesLoaded = false
  },
}

export const actions = {
  fetchDocumentWithLink: (context) => {
    return circularFetchDocumentWithLink({
      contractId: context.rootGetters['contract']?.id,
      timeout: 2500,
      next: (resp) => {
        context.commit('setInfo', resp)
      },
    })
  },
  load: (context, documentLink) => {
    context.commit('setPagesLoading')

    return fetch(documentLink)
      .then(async (resp) => {
        const documentBuffer = await resp.arrayBuffer()
        const pdfDoc = await PDFDocument.load(documentBuffer, {
          updateMetadata: false,
          renderInteractiveForms: false,
          ignoreEncryption: true,
        })

        context.commit('set', {
          document: await pdfDoc.save(),
          documentPages: pdfDoc.getPages(),
          documentLoaded: true,
        })

        return document
      })
      .catch(async (err) => {
        await context.dispatch('reset')

        throw err
      })
  },
  reset: (context) => {
    context.commit('set', {
      info: undefined,
      document: undefined,
      documentPages: undefined,
      documentLoaded: false,
      pagesLoaded: false,
    })
  },
  setPagesLoaded: (context) => {
    context.commit('setPagesLoaded')
  },
  setPagesLoading: (context) => {
    context.commit('setPagesLoading')
  },
}

export const getters = {
  document: (state) => {
    return state.document
  },
  documentInfo: (state) => {
    return state.info
  },
  documentLink: (state) => {
    return state.info?.file_url
  },
  documentPages: (state) => {
    return state.documentPages || []
  },
  isLoadingDocument: (state) => {
    return !state.documentLoaded
  },
  isAllPagesLoaded: (state) => {
    return state.pagesLoaded
  },
  numberOfPages: (state) => {
    return state.documentPages?.length || 0
  },
}
