<template lang="html">
  <Data :showMenu="false">
    <template v-slot:title>
      {{ $t('workspace.new') }}
    </template>

    <template v-slot:header>
      <div class="data__tab-select" v-bind:class="{ 'select-clicked': selectClicked }" @click="displaySelect()">
        {{ selectedTab }}<span class="data__tab-hamburger"></span>
      </div>
    </template>

    <tabs v-if="profile" :class="{ 'select-clicked': selectClicked }" :options="tabsOption" @changed="tabChanged">
      <tab v-if="canCreateLegalWorkspace" id="legal" :name="$t('workspace.type.po')">
        <div v-if="profile.workspaces.length === 0" class="content center workspace__content">
          <div class="workspace-header">
            <h2>{{ $t('welcome.title') }}</h2>
            <p>{{ $t('welcome.new_workspace') }}</p>
          </div>

          <div class="tips tips--icon">
            <div class="tips__icon">
              <IcoDashboardSecurity />
            </div>
            <div class="tips__data">
              <h3 class="tips__title">{{ $t('welcome.security.title') }}</h3>
              <div class="tips__content">
                <p>{{ $t('welcome.security.description') }}</p>
              </div>
            </div>
          </div>
        </div>

        <p class="text-center">
          {{ $t('welcome.required_fields') }}
        </p>

        <form @submit.prevent="saveWorkspaceLegal()">
          <v-text-field
            type="text"
            name="workspace-name"
            v-model="legal.title"
            :label="`${$t('workspace.title')} *`"
            :error="$v.legal.title.$error || userLegalTitleError !== ''"
            :messages="userLegalTitleError"
            outlined
            counter
            maxlength="20"
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-select
            v-model="copyWorkspaceId"
            :items="availableWorkspaceForCopy"
            :label="$t('workspace.copy.title')"
            outlined
            item-text="title"
            item-value="id"
            class="mx-auto"
            style="max-width: 22rem"
          >
            <template v-slot:append-outer>
              <s-help>
                <template v-slot:content>
                  <p
                    class="mb-0"
                    v-html="
                      $t('workspace.copy.help')
                    "
                  ></p>
                </template>
              </s-help>
            </template>
          </v-select>

          <div class="mb-10 workspace-info">
            <p>
              {{ $t('welcome.workspace_help') }}
            </p>
          </div>

          <v-text-field
            type="text"
            name="first-name"
            :value="profile.firstname"
            :label="$t('general.name')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="surname"
            :value="profile.lastname"
            :label="$t('general.surname')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="phone"
            v-model="profile_.mobile"
            :label="$t('general.phone')"
            :error="$v.profile_.mobile.$error"
            :disabled="!mobileEmpty"
            :hint="$t('welcome.phone_help')"
            persistent-hint
            outlined
            class="mx-auto mb-3"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="company-title"
            v-model="legal.organization_name"
            :label="`${$t('company.title')} *`"
            :error="$v.legal.organization_name.$error"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <InputICO
            class="workspace-new-ico identity-main-data"
            :label="`${$t('company.ico')} *`"
            :model="legal"
            :has-error="$v.legal.organization_ico.$error"
            @processExternalCompany="processExternalCompany(legal, $event)"
          />

          <v-text-field
            type="text"
            name="organization-dic"
            v-model="legal.organization_dic"
            :label="$t('company.dic')"
            outlined
            class="mt-10 mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-position"
            v-model="legal.position"
            :label="$t('workspace.position')"
            :hint="$t('workspace.position.help')"
            outlined
            persistent-hint
            class="mx-auto mb-2"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-street"
            v-model="legal.organization_street"
            :label="$t('company.street_and_number')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-city"
            v-model="legal.organization_city"
            :label="$t('company.city')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-postcode"
            v-model="legal.organization_zip"
            :label="$t('company.zip')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <WorkspaceCustomInput @change="setCustomInput" :inputs="inputs" />

          <div class="mt-10 text-center">
            <v-btn
              type="submit"
              color="primary"
              :loading="workspaceLoader"
              :disabled="userLegalTitleError !== ''"
              x-large
              style="max-width: 22rem"
            >
              {{ $t('workspace.create') }}
            </v-btn>
          </div>
        </form>
      </tab>

      <tab id="citizen" :name="$t('workspace.type.osvc')">
        <div v-if="profile.workspaces.length === 0" class="content center workspace__content">
          <div class="workspace-header">
            <h2>{{ $t('welcome.title') }}</h2>
            <p>{{ $t('welcome.new_workspace') }}</p>
          </div>

          <div class="tips tips--icon">
            <div class="tips__icon">
              <IcoDashboardSecurity />
            </div>
            <div class="tips__data">
              <h3 class="tips__title">{{ $t('welcome.security.title') }}</h3>
              <div class="tips__content">
                <p>{{ $t('welcome.security.description') }}</p>
              </div>
            </div>
          </div>
        </div>

        <p class="text-center">
          {{ $t('welcome.required_fields') }}
        </p>

        <form @submit.prevent="saveWorkspaceUserCitizen()">
          <v-text-field
            type="text"
            name="workspace-name"
            v-model="citizen.title"
            :label="`${$t('workspace.title')} *`"
            :error="$v.citizen.title.$error || userCitizenTitleError !== ''"
            :messages="userCitizenTitleError"
            outlined
            counter
            maxlength="20"
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-select
            v-model="copyWorkspaceId"
            :items="availableWorkspaceForCopy"
            :label="$t('workspace.copy.title')"
            outlined
            item-text="title"
            item-value="id"
            class="mx-auto"
            style="max-width: 22rem"
          >
            <template v-slot:append-outer>
              <s-help>
                <template v-slot:content>
                  <p
                    class="mb-0"
                    v-html="
                      $t('workspace.copy.help')
                    "
                  ></p>
                </template>
              </s-help>
            </template>
          </v-select>

          <div class="mb-10 workspace-info">
            <p>
              {{ $t('welcome.workspace_help') }}
            </p>
          </div>

          <v-text-field
            type="text"
            name="first-name"
            :value="profile.firstname"
            :label="$t('general.name')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="surname"
            :value="profile.lastname"
            :label="$t('general.surname')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="phone"
            v-model="profile_.mobile"
            :label="$t('general.phone')"
            :error="$v.profile_.mobile.$error"
            :disabled="!mobileEmpty"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <div class="mb-8 workspace-info workspace-info-noborder">
            <p>
              {{ $t('welcome.phone_help') }}
            </p>
          </div>

          <InputICO
            class="workspace-new-ico identity-main-data"
            :label="`${$t('company.ico')} *`"
            :model="citizen"
            :has-error="$v.citizen.organization_ico.$error"
            @processExternalCompany="processExternalCompany(citizen, $event)"
          />

          <v-text-field
            type="text"
            name="organization-dic"
            v-model="citizen.organization_dic"
            :label="$t('company.dic')"
            outlined
            class="mt-10 mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-street"
            v-model="citizen.organization_street"
            :label="$t('company.street_and_number')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-city"
            v-model="citizen.organization_city"
            :label="$t('company.city')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="organization-postcode"
            v-model="citizen.organization_zip"
            :label="$t('company.zip')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <WorkspaceCustomInput @change="setCustomInput" :inputs="inputs" />

          <div class="mt-10 text-center">
            <v-btn
              type="submit"
              color="primary"
              :loading="workspaceLoader"
              :disabled="userCitizenTitleError !== ''"
              x-large
              style="max-width: 22rem"
            >
              {{ $t('workspace.create') }}
            </v-btn>
          </div>
        </form>
      </tab>

      <tab id="fo" :name="$t('workspace.type.fo')">
        <div v-if="profile.workspaces.length === 0" class="content center workspace__content">
          <div class="workspace-header">
            <h2>{{ $t('welcome.title') }}</h2>
            <p>{{ $t('welcome.new_workspace') }}</p>
          </div>

          <div class="tips tips--icon">
            <div class="tips__icon">
              <IcoDashboardSecurity />
            </div>

            <div class="tips__data">
              <h3 class="tips__title">
                {{ $t('welcome.security.title') }}
              </h3>

              <div class="tips__content">
                <p>
                  {{ $t('welcome.security.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <p class="text-center">
          {{ $t('welcome.required_fields') }}
        </p>

        <form @submit.prevent="saveWorkspaceUserPrivate()">
          <v-text-field
            type="text"
            name="workspace-name"
            v-model="user_private.title"
            :label="`${$t('workspace.title')} *`"
            :error="$v.user_private.title.$error || userPrivateTitleError !== ''"
            :messages="userPrivateTitleError"
            outlined
            counter
            maxlength="20"
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-select
            v-model="copyWorkspaceId"
            :items="availableWorkspaceForCopy"
            :label="$t('workspace.copy.title')"
            outlined
            item-text="title"
            item-value="id"
            class="mx-auto"
            style="max-width: 22rem"
          >
            <template v-slot:append-outer>
              <s-help>
                <template v-slot:content>
                  <p
                    class="mb-0"
                    v-html="
                      $t('workspace.copy.help')
                    "
                  ></p>
                </template>
              </s-help>
            </template>
          </v-select>

          <div class="mb-10 workspace-info">
            <p>
              {{ $t('welcome.workspace_help') }}
            </p>
          </div>

          <v-text-field
            type="text"
            name="first-name"
            :value="profile.firstname"
            :label="$t('general.name')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="surname"
            :value="profile.lastname"
            :label="$t('general.surname')"
            disabled
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="phone"
            v-model="profile_.mobile"
            :label="$t('general.phone')"
            :error="$v.profile_.mobile.$error"
            :disabled="!mobileEmpty"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <div class="mb-10 workspace-info">
            <p>
              {{ $t('welcome.phone_help') }}
            </p>
          </div>

          <SBirthDayPicker
            v-model="user_private.number"
            :label="`${$t('general.birth_date')} *`"
            :locale="$i18n.locale"
            :error="$v.user_private.number.$error"
            class="mx-auto mb-8"
            style="max-width: 22rem"
          />

          <v-text-field
            type="text"
            name="street"
            v-model="user_private.organization_street"
            :label="$t('company.street_and_number')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="city"
            v-model="user_private.organization_city"
            :label="$t('company.city')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <v-text-field
            type="text"
            name="postcode"
            v-model="user_private.organization_zip"
            :label="$t('company.zip')"
            outlined
            class="mx-auto"
            style="max-width: 22rem"
          ></v-text-field>

          <WorkspaceCustomInput @change="setCustomInput" :inputs="inputs" />

          <div class="mt-10 text-center">
            <v-btn
              type="submit"
              color="primary"
              :loading="workspaceLoader"
              :disabled="userPrivateTitleError !== ''"
              x-large
              style="max-width: 22rem"
            >
              {{ $t('workspace.create') }}
            </v-btn>
          </div>
        </form>
      </tab>
    </tabs>

    <template v-if="errorMessage.length">
      <ErrorMessage v-for="(message, index) in errorMessage" :key="index">
        {{ message }}
      </ErrorMessage>
    </template>
  </Data>
</template>

<script>
import IcoDashboardSecurity from '@/components/svg/IcoDashboardSecurity.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { WorkspaceService } from '@/services/WorkspaceService.js'
import Tab from '@/components/Tab.vue'
import Tabs from '@/components/Tabs.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import WorkspaceCustomInput from '@/components/WorkspaceCustomInput'
import InputICO from '@/components/InputICO.vue'
import SBirthDayPicker from '@/common/components/SBirthDayPicker'
import { internalApiRequest } from '@/shared/services/apiService'

export default {
  name: 'WorkspaceCreate',
  components: {
    SBirthDayPicker,
    WorkspaceCustomInput,
    Tabs,
    Tab,
    ErrorMessage,
    IcoDashboardSecurity,
    InputICO,
  },
  data() {
    return {
      tabsOption: {
        defaultTabHash: 'legal',
      },
      errorMessage: [],
      searchedValue: '',
      selectClicked: false,
      validForm: true,
      profile_: {
        mobile: '',
      },
      userLegalTitleError: '',
      legal: {
        title: '',
        organization_name: '',
        organization_ico: '',
        organization_dic: '',
        position: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'legal',
      },
      natureDatePicker: false,
      userPrivateTitleError: '',
      user_private: {
        title: '',
        number: null,
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'nature',
      },
      userCitizenTitleError: '',
      citizen: {
        title: '',
        organization_ico: '',
        organization_dic: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'citizen',
      },
      workspaceLoader: false,
      selectedTab: '',
      inputs: [],
      mobileEmpty: false,
      copyWorkspaceId: '',
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
    canCreateLegalWorkspace() {
      if ('home' === this.profile?.active_credit_package?.type) {
        return false
      }

      return true
    },
    availableWorkspaceForCopy() {
      return [
        {
          title: this.$t('workspace.copy.not_copy'),
          id: ''
        },
        ...this.profile.workspaces.filter((workspace) => workspace.is_owner)
      ]
    }
  },
  validations: {
    user_private: {
      title: {
        required,
        minLength: minLength(2),
      },
      number: {
        required,
      },
    },
    citizen: {
      title: {
        required,
        minLength: minLength(2),
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
    },
    legal: {
      title: {
        required,
        minLength: minLength(2),
      },
      organization_name: {
        required,
        minLength: minLength(2),
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
    },
    profile_: {
      mobile: {
        required,
      },
    },
  },
  watch: {
    natureDatePicker(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    $route: 'fetchData',
    profile: function () {
      this.checkWorkspaces()
    },
    'user_private.title': {
      handler: function () {
        this.userPrivateTitleError = ''
      },
    },
    'citizen.title': {
      handler: function () {
        this.userCitizenTitleError = ''
      },
    },
    'legal.title': {
      handler: function () {
        this.userLegalTitleError = ''
      },
    },
    'citizen.organization_ico': {
      handler: function () {
        this.$v.citizen.$reset()
      },
    },
    'legal.organization_ico': {
      handler: function () {
        this.$v.legal.$reset()
      },
    },
  },
  created() {
    this.checkWorkspaces()
  },
  methods: {
    processExternalCompany(editedWorkspace, response) {
      editedWorkspace.organization_city = response.city
      editedWorkspace.organization_street = response.street

      if (response.number) {
        if (response.street) {
          editedWorkspace.organization_street += ' '
        }
        editedWorkspace.organization_street += response.number
      }

      editedWorkspace.organization_dic = response.dic
      editedWorkspace.organization_ico = response.ico
      editedWorkspace.organization_zip = response.zip
      if (editedWorkspace === this.legal) {
        editedWorkspace.organization_name = response.organizationName
      }
    },
    displaySelect() {
      this.selectClicked = !this.selectClicked
    },
    setBirthDate(date) {
      if (date) {
        this.user_private.number = date
      } else {
        this.user_private.number = ''
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split(' ')[0].split('-')
      return `${day}.${month}.${year}`
    },
    getBirthDate(date) {
      this.user_private.number = date
    },
    checkWorkspaces() {
      if (this.profile.mobile || this.profile.mobile_tmp) {
        this.mobileEmpty = false
        this.profile_.mobile = this.profile.mobile || this.profile.mobile_tmp
      } else {
        this.mobileEmpty = true
      }
    },
    tabChangedName(tabName) {
      this.errorMessage = []
      this.selectedTab = tabName
    },
    tabChanged(selectedTab) {
      this.errorMessage = []
      this.selectedTab = selectedTab.tab.name
      this.selectClicked = false
    },
    saveWorkspaceLegal() {
      this.errorMessage = []

      this.$v.legal.$touch()
      this.$v.profile_.$touch()

      const workspaceExist = this.profile.workspaces.filter((workspace) => {
        return this.legal.title.toLowerCase() === workspace.title.toLowerCase()
      })

      if (workspaceExist.length !== 0) {
        this.userLegalTitleError = this.$t('workspace.exist')
        this.$notification.error(this.$t('workspace.exist'))

        return
      }

      if (this.$v.legal.$error || this.$v.profile_.$error) {
        this.errorMessage.push(this.$t('general.fill_required'))
        this.$notification.error(this.$t('general.fill_required'))
        return
      }

      this.workspaceLoader = true

      if(this.copyWorkspaceId) {
        return internalApiRequest({
          method: 'POST',
          path: `api/v1/workspace/${this.copyWorkspaceId}/clone`
        }).then(async (resp) => {
          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))

          await WorkspaceService.update({
            ...this.legal,
            inputs: this.inputs,
            mobile: this.profile_.mobile,
          }, resp.id)

          await this.$router.push({
            name: 'workspaceCreated',
            params: {
              workspace_id: resp.id,
            },
          })
        }).catch(() => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        })
      }

      WorkspaceService.create({
        ...this.legal,
        inputs: this.inputs,
        mobile: this.profile_.mobile,
      }).then(
        async (responseWorkspace) => {
          if (422 == responseWorkspace.code) {
            this.$notification.error(this.$t('general.invalid_form'))
            this.errorMessage.push(this.$t('general.invalid_form'))
            return
          } else if (responseWorkspace.code >= 400) {
            this.$notification.error(this.$t('workspace.create_error'))
            this.errorMessage.push(this.$t('workspace.create_error'))
            return
          }
          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))
          await this.$router.push({
            name: 'workspaceCreated',
            params: { workspace_id: responseWorkspace.id },
          })
        },
        () => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        },
      )
    },
    saveWorkspaceUserCitizen() {
      this.errorMessage = []
      this.$v.citizen.$touch()
      this.$v.profile_.$touch()

      const workspaceExist = this.profile.workspaces.filter((workspace) => {
        return this.citizen.title.toLowerCase() === workspace.title.toLowerCase()
      })

      if (workspaceExist.length !== 0) {
        this.userCitizenTitleError = this.$t('workspace.exist')
        this.$notification.error(this.$t('workspace.exist'))

        return
      }

      if (this.$v.citizen.$error || this.$v.profile_.$error) {
        this.errorMessage.push(this.$t('general.fill_required'))
        this.$notification.error(this.$t('general.fill_required'))
        return
      }

      this.workspaceLoader = true

      if(this.copyWorkspaceId) {
        return internalApiRequest({
          method: 'POST',
          path: `api/v1/workspace/${this.copyWorkspaceId}/clone`
        }).then(async (resp) => {
          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))

          await WorkspaceService.update({
            ...this.citizen,
            inputs: this.inputs,
            mobile: this.profile_.mobile,
          }, resp.id)

          await this.$router.push({
            name: 'workspaceCreated',
            params: {
              workspace_id: resp.id,
            },
          })
        }).catch(() => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        })
      }

      WorkspaceService.create({
        ...this.citizen,
        inputs: this.inputs,
        mobile: this.profile_.mobile,
      }).then(
        async (responseWorkspace) => {
          if (422 == responseWorkspace.code) {
            this.$notification.error(this.$t('general.invalid_form'))
            this.errorMessage.push(this.$t('general.invalid_form'))
            return
          } else if (responseWorkspace.code >= 400) {
            this.$notification.error(this.$t('workspace.create_error'))
            this.errorMessage.push(this.$t('workspace.create_error'))
            return
          }

          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))
          await this.$router.push({
            name: 'workspaceCreated',
            params: { workspace_id: responseWorkspace.id },
          })
        },
        () => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        },
      )
    },
    saveWorkspaceUserPrivate() {
      this.errorMessage = []
      this.$v.user_private.$touch()
      this.$v.profile_.$touch()

      const workspaceExist = this.profile.workspaces.filter((workspace) => {
        return this.user_private.title.toLowerCase() === workspace.title.toLowerCase()
      })

      if (workspaceExist.length !== 0) {
        this.userPrivateTitleError = this.$t('workspace.exist')
        this.$notification.error(this.$t('workspace.exist'))

        return
      }

      if (this.$v.user_private.$error || this.$v.profile_.$error) {
        this.errorMessage.push(this.$t('general.fill_required'))
        this.$notification.error(this.$t('general.fill_required'))

        return
      }

      this.workspaceLoader = true

      if(this.copyWorkspaceId) {
        return internalApiRequest({
          method: 'POST',
          path: `api/v1/workspace/${this.copyWorkspaceId}/clone`
        }).then(async (resp) => {
          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))

          await WorkspaceService.update({
            ...this.user_private,
            inputs: this.inputs,
            mobile: this.profile_.mobile,
          }, resp.id)

          await this.$router.push({
            name: 'workspaceCreated',
            params: {
              workspace_id: resp.id,
            },
          })
        }).catch(() => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        })
      }

      WorkspaceService.create({
        ...this.user_private,
        inputs: this.inputs,
        mobile: this.profile_.mobile,
      }).then(
        async (responseWorkspace) => {
          if (422 == responseWorkspace.code) {
            this.$notification.error(this.$t('general.invalid_form'))
            this.errorMessage.push(this.$t('general.invalid_form'))

            return
          } else if (responseWorkspace.code >= 400) {
            this.$notification.error(this.$t('workspace.create_error'))
            this.errorMessage.push(this.$t('workspace.create_error'))

            return
          }

          await this.$store.dispatch('getUserProfile')
          this.workspaceLoader = false
          this.$notification.success(this.$t('workspace.created'))
          await this.$router.push({
            name: 'workspaceCreated',
            params: {
              workspace_id: responseWorkspace.id,
            },
          })
        },
        () => {
          this.workspaceLoader = false
          this.$notification.error(this.$t('workspace.create_error'))
        },
      )
    },
    setCustomInput(inputs) {
      this.inputs = inputs
    },
  },
}
</script>

<style lang="scss">
.workspace__create {
  max-width: 60rem;
  width: 100%;
}

.workspace__content {
  padding-bottom: 45px;
}

.tips {
  max-width: 640px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 6px;
  //border: 1px solid #F1EAAF;
  background: #fffad1;
  font-size: 15px;
  color: #73560b;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  margin-top: 25px;
  position: relative;
  // margin-bottom: 25px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
}

.tips__icon {
  float: left;
  text-align: center;
  padding-left: 6px;
}

.tips__data {
  padding-left: 80px;
  text-align: left;
}

.tips__title {
  color: #73560b;
  font-weight: 700;
}

.tips__content {
  color: #73560b;

  p {
    color: #73560b;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.workspace-header {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.workspace-info {
  max-width: 100%;
  text-align: left;
  margin-top: 6px;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-bottom: 1px solid #cdcdcd;
  &.workspace-info-noborder {
    border: 0;
  }
  p {
    font-size: 13px;
  }
}

.workspace-desc {
  max-width: 100%;
  text-align: left;
  margin-top: 6px;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  p {
    font-size: 13px;
  }
}

.error-message {
  text-align: center;
}

.workspace-radios {
  margin-top: 20px;
}
.workspace__buttons {
  text-align: center;
  padding-top: 60px;
}

.workspace-identity {
  width: 100%;
  float: left;
  padding-top: 40px;
  padding-bottom: 30px;
}

.workspace-input.input {
  width: 330px;
}

.workspaces-content {
  margin: 4em 0;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15) !important;
  border-radius: 6px !important;
  border: none !important;
  background: white;
}

.workspace-content__body {
  padding: 40px 25px;
}

.workspace-half {
  width: 50%;
  float: left;
  padding: 0px 15px;
}

.workspace-input .input {
  max-width: 470px;
}

.workspace-new-ico.identity-main-data {
  text-align: center;
  margin: 15px -20px 0;
}
</style>
