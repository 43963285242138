import * as types from './mutation_types'

export default {
  [types.SET_ACTIVE_WORKSPACE](state, workspace) {
    state.activeWorkspace = workspace
    localStorage.setItem('workspace_id', workspace?.id)
  },
  [types.SET_ACTIVE_WORKSPACE_BANK_ACCOUNT](state, bankAccount) {
    state.activeWorkspaceBankAccount = bankAccount
  },
  [types.SET_WORKSPACE_SIGNATURE_FOOTER_SETTINGS](state, signatureFooterSettings) {
    state.signatureFooterSettings = signatureFooterSettings
  },
  [types.SET_WORKSPACE_SETTINGS](state, workspaceSettings) {
    state.workspaceSettings = workspaceSettings
  },
  [types.SET_WORKSPACE_BANK_ACCOUNTS](state, bankAccounts) {
    state.workspaceBankAccounts = bankAccounts
  },
  [types.SET_MEMBERS_JOB_POSITIONS](state, jobPositions) {
    state.membersJobPositions = jobPositions
  },
}
