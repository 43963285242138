<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center justify-center">
      <img src="/images/onedrive.png" alt="onedrive logo" height="20px" class="mr-5" />
      <v-btn v-if="!isOneDriveEnabled" @click="onedriveAuthStart">
        {{ $t('workspace.drive.enable') }}
      </v-btn>
      <span v-else class="onedrive">
        <IcoCircleCheck />
        {{ $t('workspace.drive.active') }}
        <v-btn plain text color="error" small @click="showDeleteOneDriveDialog = true">
          {{ $t('workspace.drive.disable') }}
        </v-btn>
      </span>
    </v-col>
    <v-col v-if="isOneDriveEnabled">
      <v-card flat class="px-4 d-flex align-center justify-center">
        <v-row>
          <v-col cols="12" class="d-flex flex-column align-center justify-center mb-4">
            <div v-if="!isOneDriveSelected">
              <div>{{ $t('workspace.drive.onedrive') }}</div>
              <v-select
                :label="$t('workspace.drive.onedrive_select')"
                :items="oneDrives"
                :item-value="'id'"
                :item-text="'name'"
                @change="setOneDrive"
              ></v-select>
            </div>
            <div v-else class="d-flex flex-column align-center justify-center" style="width: 100%">
              <v-card flat outlined style="width: 100%" class="d-flex align-center justify-space-between">
                <v-card-subtitle>
                  {{ this.selectedDrive.name }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn icon @click="editOneDrive"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                </v-card-actions>
              </v-card>
              <v-card flat class="mt-5" :loading="loadingFolders">
                <div class="onedrive-explorer" @click="openOnedriveExplorer">
                  {{ $t('contract.onedrive.select_folder') }}:<br />
                  <div class="d-flex align-center justify-space-between">
                    <span class="content-finish__box-one-drive-name">
                      {{ onedrivePath }}
                    </span>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                      </template>
                      <span> {{ $t('contract.onedrive.help') }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <div v-if="isOneDriveSelected">
      <FolderStructureExplorer
        v-model="chooseOnedriveFolder"
        :loadFunction="onedriveDataLoad"
        @selectFolder="selectOnedriveFolder($event)"
        @cancel="chooseOnedriveFolder = false"
      />
    </div>

    <SConfirmDialog
      v-model="showDeleteOneDriveDialog"
      :message="`${$t('workspace.drive.onedrive_delete')}`"
      @confirmation-callback="deleteOneDrive"
    />
  </v-row>
</template>

<script>
import { internalApiRequest } from '@/shared/services/apiService'
import { ExternalDriveService } from '@/services/ExternalDriveService'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import { fetchSharePointDirectories } from '@/features/contracts/services/sharePointService'
import IcoCircleCheck from '@/components/svg/IcoCircleCheck.vue'
import SConfirmDialog from '@/common/components/SConfirmDialog'

export default {
  name: 'WorkspaceOneDrive',
  components: {
    FolderStructureExplorer: () => import('@/views/create/components/FolderStructureExplorer'),
    SConfirmDialog,
    IcoCircleCheck,
  },
  props: {
    workspaceId: {
      required: true,
    },
    isOneDriveEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      oneDrives: [],
      selectedDrive: null,
      showDeleteOneDriveDialog: false,
      chooseOnedriveFolder: false,
      onedriveFolder: '',
      loadingFolders: false,
    }
  },
  computed: {
    isOneDriveSelected() {
      return !!this.selectedDrive?.name || !!this.selectedDrive?.driveId
    },
    onedrivePath() {
      return this.onedriveFolder && this.onedriveFolder != '/' ? this.onedriveFolder : this.$t('contract.onedrive.root')
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isOneDriveEnabled) {
        this.fetchOneDrives()
        this.fetchSelectedDrive()
      }
    })
  },
  methods: {
    deleteOneDrive() {
      return internalApiRequest({
        method: 'DELETE',
        path: `api/v2/workspace/${this.workspaceId}/sharePoint`,
      })
        .then((response) => {
          if ('ok' === response.status) {
            this.selectedDrive = {}
            this.onedriveFolder = ''
            this.$emit('fetchData')
            return this.$notification.success(this.$t('workspace.drive.onedrive_deactivated'))
          }
        })
        .catch((error) => getErrorResponseMessage(error.data))
    },
    editOneDrive() {
      this.loadingFolders = true
      return internalApiRequest({
        method: 'PATCH',
        path: `api/v2/workspace/${this.workspaceId}/sharePoint`,
        data: {
          driveId: null,
          path: null,
        },
      }).then((response) => {
        this.selectedDrive = response
        this.onedriveFolder = response.path
        this.loadingFolders = false
      })
    },
    fetchOneDrives() {
      internalApiRequest({
        method: 'GET',
        path: `api/v2/workspace/${this.workspaceId}/sharePoint/drives`,
      })
        .then((response) => {
          this.oneDrives = response
        })
        .catch((error) => getErrorResponseMessage(error.data))
    },
    fetchSelectedDrive() {
      this.loadingFolders = true

      if (!this.isOneDriveSelected) {
        return internalApiRequest({
          method: 'GET',
          path: `api/v2/workspace/${this.workspaceId}/sharePoint/drive/selected`,
        }).then((response) => {
          this.selectedDrive = response

          return internalApiRequest({
            method: 'GET',
            path: `api/v2/workspace/${this.workspaceId}/sharePoint`,
          }).then((response) => {
            this.onedriveFolder = response.path
            this.loadingFolders = false
          })
        })
      }
    },
    onedriveAuthStart() {
      ExternalDriveService.onedriveAuthStart(this.workspaceId)
    },
    onedriveDataLoad(path = null) {
      return fetchSharePointDirectories({
        workspaceId: this.$route.params.workspace_id,
        path,
      })
    },
    openOnedriveExplorer() {
      this.chooseOnedriveFolder = true
    },
    selectOnedriveFolder(folder) {
      return internalApiRequest({
        method: 'PATCH',
        path: `api/v2/workspace/${this.workspaceId}/sharePoint`,
        data: {
          path: folder,
        },
      })
        .then((response) => {
          this.onedriveFolder = response.path
          this.chooseOnedriveFolder = false
        })
        .catch((error) => getErrorResponseMessage(error.data))
    },
    setOneDrive(driveId) {
      internalApiRequest({
        method: 'PATCH',
        path: `api/v2/workspace/${this.workspaceId}/sharePoint`,
        data: {
          driveId: driveId,
        },
      })
        .then((response) => {
          this.loadingFolders = false
          return (this.selectedDrive = this.oneDrives.find((drive) => drive.id === response.driveId))
        })
        .catch((error) => getErrorResponseMessage(error.data))
    },
  },
}
</script>

<style lang="scss" scoped>
.onedrive {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: #6decaf;
  height: 40px;
  border: 1px solid #e8e8e8;
  padding: 0 10px;
  border-radius: 6px;
  cursor: default;
  svg {
    margin-right: 5px;
  }
}
</style>
