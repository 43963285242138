export const signatures = (state) => {
  return state.signatures
}

export const pageSignatures = (state) => (pageNumber) => {
  return state.signatures?.filter((signature) => signature.position?.page === pageNumber - 1) || []
}

export const signaturesWithoutPosition = (state) => {
  return state.signatures?.filter((signature) => null === signature.position)
}

export const signIdentitySignatures = (state) => (signIdentityId) => {
  return state.signatures?.filter((signature) => signature.signIdentity.id === signIdentityId) || []
}

export const unfinishedSignatures = (state, getters) => (signIdentityId) => {
  return getters['signIdentitySignatures'](signIdentityId)?.filter((signature) => {
    return false === signature.isConfirmed && signature?.position?.page < getters['numberOfPages']
  })
}

export const isAllSignIdentitySignaturesConfirmed = (state, getters) => (signIdentityId) => {
  return 0 === getters['unfinishedSignatures'](signIdentityId)?.length
}

export const numberOfPages = (state) => {
  return state.numberOfPages
}
